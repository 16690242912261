import {authHeader} from "../../../../auth";

export default {
    
    data() {
        return {
            setURL: '',
            pageCMSData: []
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.home.homePageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async update() {
            try {
                let data = {
                    sectionOneTitle: this.pageCMSData.sectionOneTitle ? this.pageCMSData.sectionOneTitle : '',
                    sectionOneSubTitle: this.pageCMSData.sectionOneSubTitle ? this.pageCMSData.sectionOneSubTitle : '',
                    carouselTitle: this.pageCMSData.carouselTitle ? this.pageCMSData.carouselTitle : '',
                    sectionTwoTitle: this.pageCMSData.sectionTwoTitle ? this.pageCMSData.sectionTwoTitle : '',
                    sectionTwoSubTitle: this.pageCMSData.sectionTwoSubTitle ? this.pageCMSData.sectionTwoSubTitle : '',
                    sectionFiveTitle: this.pageCMSData.sectionFiveTitle ? this.pageCMSData.sectionFiveTitle : '',
                    sectionFiveSubTitle: this.pageCMSData.sectionFiveSubTitle ? this.pageCMSData.sectionFiveSubTitle : '',
                    sectionEightTitle: this.pageCMSData.sectionEightTitle ? this.pageCMSData.sectionEightTitle : '',
                    sectionEightSubTitle: this.pageCMSData.sectionEightSubTitle ? this.pageCMSData.sectionEightSubTitle : '',
                    sectionNineTitle: this.pageCMSData.sectionNineTitle ? this.pageCMSData.sectionNineTitle : ''
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.home.homePageCMS,
                    data: data,
                    headers: authHeader()
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}