<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Newsletter Modal</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Newsletter CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Newsletter CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="pageCMSData.title" class="form-control" id="inputPageHeaderTitle" placeholder="Enter title">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Image:
                                            <strong>[Preferred Image Size: 450X400, Max Upload Limit:
                                                1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImage"
                                            :src="previewImage" height="70" width="100" />
                                        <img style="position: relative; left: 11px"
                                            v-else-if="pageCMSData.image" :src="
                                            pageCMSData.image
                                        " height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else
                                            src="../../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="backgroundImage"
                                            accept="image/*" @change="uploadImage($event)" />
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Email Title:</label>
                                        <input type="text" v-model="pageCMSData.emailTtile" class="form-control" id="inputPageHeaderPageName" placeholder="Enter email title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Email Placeholder Text:</label>
                                        <input type="text" v-model="pageCMSData.emailPlaceHolderText" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Email Placeholder Text">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="pageCMSData.buttonText" class="form-control" id="inputPageHeaderPageName" placeholder="Enter button text">
                                    </div>
                                    
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/newsletter-cms.js"></script>
