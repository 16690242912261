import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    data() {
        return {
            orderList: [],
            filteredOrderIdList: [],
            filter: {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: ""
            },
            deliveryStatus: "",
            totalOrders: "",
            totalPendingOrders: "",
            totalDeliveredOrders: "",
            totalCancelledOrders: "",
            data: {
                paymentStatus: "PENDING"
            },
            userList: [],
            setURL: this.$serverURL + this.$api.user.customerList,
            sheets: []
        }
    },
    async mounted() {
        document.title = "Golden Gal - Order List";
        await this.getOrderList();
        await this.getCustomerList();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderListURL + "?paymentStatus=" + this.filter.paymentStatus + "&deliveryStatus=" + this.filter.deliveryStatus + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + "&customerName=" + this.filter.userName + "&orderId=" + this.filter.orderId,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;
                        if (this.filteredOrderIdList.length == 0) {
                            this.filteredOrderIdList = this.orderList.map(el => {
                                return { value: el.orderId, label: el.orderId }
                            });
                        }
                        this.totalOrders = response.data.data.totalOrders;
                        this.totalPendingOrders = response.data.data.totalPendingOrders;
                        this.totalDeliveredOrders = response.data.data.totalDeliveredOrders;
                        this.totalCancelledOrders = response.data.data.totalCancelledOrders;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: error.response.data.message
                });
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.userList = await response.data.data.map(el => {
                        return { value: el.fullName, label: el.fullName }
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async updateOrder(param) {
            try {
                let data = {
                    deliveryStatus: param.deliveryStatus,
                    paymentStatus: param.paymentStatus
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.order.orderListURL + '/' + param.id,
                    data: data,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        this.getOrderList();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Order updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later!"
                });
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select end date!"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select start date!"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async clearFilter() {
            this.filter = {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: ""
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        exportOrders: function () {
            this.orderList.map((el) => {
                let shippingInfo = JSON.parse(el.shippingAddress);
                this.sheets.push({
                    'Id': el.id,
                    'OrderId': el.orderId,
                    "Customer Name": el.customerName,
                    "Mobile": shippingInfo.phone,
                    "Email": shippingInfo.email,
                    "Address": shippingInfo.addressOne,
                    "City": shippingInfo.city,
                    "Post Code": shippingInfo.postCode,
                    "Country": shippingInfo.country,
                    'Total Price': el.total,
                    'Total Quantity': el.totalQuantity,
                    'Order Date': this.$filters.formatDate(el.createdAt),
                    'Payment Method': el.paymentMethodName,
                    'Payment Status': el.paymentStatus,
                    'Shipping Status': el.deliveryStatus,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'order_report(' + date + ').xlsx');
        },
        async deleteOrder(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.order.orderListURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.deleted == true) {
                                this.getOrderList();
                                this.$swal.fire({
                                    icon: "success",
                                    text: "Order deleted successfully."
                                });
                            }
                        }).catch(error => {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later!"
                        });
                    }
                }
            })
        }
    }
}