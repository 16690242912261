<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <div>
        <h4 class="logo-text">Golden Gal</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bx-home-circle"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-user"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="lni lni-producthunt"></i>
          </div>
          <div class="menu-title">Product</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-list')">
              <i class="bx bx-right-arrow-alt"></i>Products
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-color')">
              <i class="bx bx-right-arrow-alt"></i>Color
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-size')">
              <i class="bx bx-right-arrow-alt"></i>Size
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-material')">
              <i class="bx bx-right-arrow-alt"></i>Material
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-highlight')">
              <i class="bx bx-right-arrow-alt"></i>Highlight
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-tag')">
              <i class="bx bx-right-arrow-alt"></i>Tag
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/items-on-sale')">
              <i class="bx bx-right-arrow-alt"></i>Items On Sale
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/shop-by-occasion')">
              <i class="bx bx-right-arrow-alt"></i>Shop By Occasion
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-cubes"></i>
              </div>
              <div class="menu-title">Product Category</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/category')">
                  <i class="bx bx-right-arrow-alt"></i>Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/sub-category')">
                  <i class="bx bx-right-arrow-alt"></i>Sub Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/child-category')">
                  <i class="bx bx-right-arrow-alt"></i>Child Category
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-cart"></i>
          </div>
          <div class="menu-title">Order</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/create-order')">
              <i class="bx bx-right-arrow-alt"></i>Create Order
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/order-list')">
              <i class="bx bx-right-arrow-alt"></i>Order List
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-list')">
              <i class="bx bx-right-arrow-alt"></i>Payment List
            </a>
          </li>
        </ul>
      </li>

            <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-info-circle"></i>
          </div>
          <div class="menu-title">Return Products</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-unapproved')">
              <i class="bx bx-right-arrow-alt"></i>Unapproved
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-approved')">
              <i class="bx bx-right-arrow-alt"></i>Approved
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-rejected')">
              <i class="bx bx-right-arrow-alt"></i>Rejected
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:void(0)" @click="$router.push('/shipping-methods')">
          <div class="parent-icon">
            <i class="fa-solid fa-truck-fast"></i>
          </div>
          <div class="menu-title">Shipping Methods</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/sales-tax')">
          <div class="parent-icon">
            <i class="fa-solid fa-circle-minus"></i>
          </div>
          <div class="menu-title">Sales Tax</div>
        </a>
      </li>

      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-message-detail"></i>
          </div>
          <div class="menu-title">Contact</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/contact-address')">
              <i class="bx bx-right-arrow-alt"></i>Contact Address
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/contact-message')">
              <i class="bx bx-right-arrow-alt"></i>Contact Messages
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="bx bxs-gift"></i>
          </div>
          <div class="menu-title">Gift Card</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/gift-card')">
              <i class="bx bx-right-arrow-alt"></i>Gift Card
            </a>
          </li>
        </ul>
      </li>
      <!-- <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class="lni lni-home"></i>
                    </div>
                    <div class="menu-title">Home</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/customer-review')"><i
                                class="bx bx-right-arrow-alt"></i>Customer Review</a>
                    </li>
                    
                </ul>
      </li>-->
      <!-- <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class="bx bx-clipboard"></i>
                    </div>
                    <div class="menu-title">Learn</div>
                </a>
                <ul>
                    
                </ul>
      </li>-->
      <li>
        <a href="javascript:void(0)" @click="$router.push('/gallery')">
          <div class="parent-icon">
            <i class="fa-sharp fa-solid fa-images"></i>
          </div>
          <div class="menu-title">Gallery</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/vendor')">
          <div class="parent-icon">
            <i class="fa-solid fa-building-circle-arrow-right"></i>
          </div>
          <div class="menu-title">Vendor</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/coupon')">
          <div class="parent-icon">
            <i class="fa-sharp fa-solid fa-tag"></i>
          </div>
          <div class="menu-title">Coupon</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/payment-configuration')">
          <div class="parent-icon">
            <i class="bx bxs-badge-dollar"></i>
          </div>
          <div class="menu-title">Payment Credential</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/subscriber')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-message-square"></i>
          </div>
          <div class="menu-title">Subscriber</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/product-hint')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-message-square"></i>
          </div>
          <div class="menu-title">Product Hint</div>
        </a>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fa-solid fa-folder-open"></i>
          </div>
          <div class="menu-title">Reports</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/order-report')">
              <i class="bx bx-right-arrow-alt"></i>Order Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/gift-card-report')">
              <i class="bx bx-right-arrow-alt"></i>Gift Card Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-profit-report')">
              <i class="bx bx-right-arrow-alt"></i>Product Report
            </a>
          </li>
        </ul>
      </li>
      <!-- <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class="fa-solid fa-money-bill"></i>
                    </div>
                    <div class="menu-title">Payment</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/payment-method')"><i
                                class="bx bx-right-arrow-alt"></i>Payment Methods</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/payment-list')"><i
                                class="bx bx-right-arrow-alt"></i>Payment List</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/payment-settings')"><i
                                class="bx bx-right-arrow-alt"></i>Payment Settings</a>
                    </li>
                </ul>
      </li>-->
      <!-- <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon"><i class="fa-solid fa-truck"></i></div>
                    <div class="menu-title">Shipping Settings</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/fedex-settings')"><i
                                class="bx bx-right-arrow-alt"></i>Fedex Settings</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/ups-settings')"><i
                                class="bx bx-right-arrow-alt"></i>Ups Settings</a>
                    </li>
                </ul>
      </li>-->
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="lni lni-cog"></i>
          </div>
          <div class="menu-title">CMS Settings</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-home"></i>Home
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-slider')">
                  <i class="bx bx-right-arrow-alt"></i>Home Slider
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/section-two')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-section-four-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/section-four-slider-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four Slider CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-section-six-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Six Content
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-section-seven-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Seven CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Product
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Product Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-details-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Product Details Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-gift"></i>Gift Guide
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/gift-guide-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Gift Guide CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-brands fa-shopify"></i>Shop Guide
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shop-guide-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Guide CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-cart"></i>Shopping Cart
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/cart-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cart CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Style Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/style-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/style-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/style-section-four-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/style-section-five-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-brands fa-leanpub"></i>Learn
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/learn-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Learn Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('learn-tab')">
                  <i class="bx bx-right-arrow-alt"></i>Learn Page Tab
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/glossary')">
                  <i class="bx bx-right-arrow-alt"></i>Glossary
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/ring-size')">
                  <i class="bx bx-right-arrow-alt"></i>Ring Size
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/bracelets-size')">
                  <i class="bx bx-right-arrow-alt"></i>Bracelets Size
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/necklace-size')">
                  <i class="bx bx-right-arrow-alt"></i>Necklace Size
                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-sharp fa-solid fa-images"></i>Mail Template
            </a>
            <ul>
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/create-account-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Create Account Email
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/create-account-by-admin-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Create Account By Admin Email
                </a>
              </li> -->
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/order-placed-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Order Placed Email
                </a>
              </li> -->
              <li>
                <a href="javascript:void(0);" @click="$router.push('/create-admin-signup-email-template-elementor')">
                  <i class="bx bx-right-arrow-alt"></i> Create Admin Signup Email</a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/create-customer-signup-email-template-elementor')">
                  <i class="bx bx-right-arrow-alt"></i> Create Customer Signup Email
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/forgot-password-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Customer Forgot Password Email
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/place-order-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Placed Order Email Template
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/processing-order-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Processing Order Email Template
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shipped-order-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Shipped Order Email Template
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/delivered-order-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Delivered Order Email Template
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-hint-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Product Hint Email Template
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/new-customer-admin-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> New Customer Admin Email Template
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-sharp fa-solid fa-images"></i>Gallery
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/gallery-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Gallery Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-info-circle"></i>About Us
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/about-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bxs-collection"></i>Collection
              Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/collection-page')">
                  <i class="bx bx-right-arrow-alt"></i>Collection Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bxs-gift"></i>Gift Card
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/gift-card-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Gift Card CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-bars-progress"></i>Header
              Footer
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/page-title-cms')">
                  <i class="bx bx-right-arrow-alt"></i> Site Page Title CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-meta')">
                  <i class="bx bx-right-arrow-alt"></i>Product Meta
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-link"></i>Social Links
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/social-links')">
                  <i class="bx bx-right-arrow-alt"></i>Social Links
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-paperclip"></i>Quick Links
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/quick-links')">
                  <i class="bx bx-right-arrow-alt"></i>Quick Links
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-message-detail"></i>Contact
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/contact-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bx-clipboard"></i>Promotional
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/promotional-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Promotional Page CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-popup"></i>Pop Up Modal
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/newsletter-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Modal CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-check-shield"></i>Policies
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/terms&condition')">
                  <i class="bx bx-right-arrow-alt"></i>Terms&Condition
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/privacy-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Return Policy
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="lni lni-cog"></i>
          </div>
          <div class="menu-title">Component CMS</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-home"></i>Home Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-cart"></i>Cart Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/cart-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cart Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Shop Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shop-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-sharp fa-solid fa-images"></i>Gallery Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/gallery-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Gallery Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Style Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/style-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Style Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-info-circle"></i>About Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/about-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bxs-collection"></i>Collection Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/collection-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Collection Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bx-clipboard"></i>Learn Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/learn-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Learn Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-message-detail"></i>Contact Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/contact-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Contact Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bx-clipboard"></i>Promotional Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/promotional-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Promotional Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bxs-gift"></i>Gift Card Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/gift-card-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Gift Card Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="bx bxs-gift"></i>Gift Card Details
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/gift-card-details-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Gift Card Details Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Hover Color
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/hover-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Hover Color
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Product Details
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/product-details-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Product Details Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Product Modal Page
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-varient-modal')">
                  <i class="bx bx-right-arrow-alt"></i>Product Modal Component
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-check-shield"></i>Policies
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/terms-and-condition-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Terms & Condition
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/privacy-policy-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-policy-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Return Policy
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>