export default {
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            previewImageOne: null,
            previewImageTwo: null,
            previewImageThree: null,
            previewImageFour: null,
            previewImageFive: null,
            imageOneFile: null,
            imageTwoFile: null,
            imageThreeFile: null,
            imageFourFile: null,
            imageFiveFile: null,
            productList: []
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Promotional Page";
        this.setURL = this.$serverURL + this.$api.cms.promotionalPage;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = {};
        }
        await this.getDataList(this.$serverURL + this.$api.product.productURL);
        this.productList = this.dataList;
    },
    methods: {
        uploadImageOne: function (event) {
            this.imageOneFile = event.target.files[0];
            let input = this.$refs.imageOne;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageOne = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageTwo: function (event) {
            this.imageTwoFile = event.target.files[0];
            let input = this.$refs.imageTwo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageTwo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageThree: function (event) {
            this.imageThreeFile = event.target.files[0];
            let input = this.$refs.imageThree;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageThree = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageFour: function (event) {
            this.imageFourFile = event.target.files[0];
            let input = this.$refs.imageFour;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageFour = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageFive: function (event) {
            this.imageFiveFile = event.target.files[0];
            let input = this.$refs.imageFive;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageFive = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("title", this.pageCMSData.title ? this.pageCMSData.title : '');
                formData.append("subTitle", this.pageCMSData.subTitle ? this.pageCMSData.subTitle : '');
                formData.append("firstProductId", this.pageCMSData.firstProductId ? this.pageCMSData.firstProductId : '');
                formData.append("firstProductLabel", this.pageCMSData.firstProductLabel ? this.pageCMSData.firstProductLabel : '');
                formData.append("firstProductDiscount", this.pageCMSData.firstProductDiscount ? this.pageCMSData.firstProductDiscount : '');
                formData.append("firstProductImage", this.imageOneFile ? this.imageOneFile : this.pageCMSData.firstProductImage ? this.pageCMSData.firstProductImage : '');
                
                formData.append("secondProductId", this.pageCMSData.secondProductId ? this.pageCMSData.secondProductId : '');
                formData.append("secondProductLabel", this.pageCMSData.secondProductLabel ? this.pageCMSData.secondProductLabel : '');
                formData.append("secondProductDiscount", this.pageCMSData.secondProductDiscount ? this.pageCMSData.secondProductDiscount : '');
                formData.append("secondProductImage", this.imageTwoFile ? this.imageTwoFile : this.pageCMSData.secondProductImage ? this.pageCMSData.secondProductImage : '');
                
                formData.append("thirdProductId", this.pageCMSData.thirdProductId ? this.pageCMSData.thirdProductId : '');
                formData.append("thirdProductLabel", this.pageCMSData.thirdProductLabel ? this.pageCMSData.thirdProductLabel : '');
                formData.append("thirdProductDiscount", this.pageCMSData.thirdProductDiscount ? this.pageCMSData.thirdProductDiscount : '');
                formData.append("thirdProductImage", this.imageThreeFile ? this.imageThreeFile : this.pageCMSData.thirdProductImage ? this.pageCMSData.thirdProductImage : '');
                
                formData.append("fourthProductId", this.pageCMSData.fourthProductId ? this.pageCMSData.fourthProductId : '');
                formData.append("fourthProductLabel", this.pageCMSData.fourthProductLabel ? this.pageCMSData.fourthProductLabel : '');
                formData.append("fourthProductDiscount", this.pageCMSData.fourthProductDiscount ? this.pageCMSData.fourthProductDiscount : '');
                formData.append("fourthProductImage", this.imageFourFile ? this.imageFourFile : this.pageCMSData.fourthProductImage ? this.pageCMSData.fourthProductImage : '');
                
                formData.append("fifthProductId", this.pageCMSData.fifthProductId ? this.pageCMSData.fifthProductId : '');
                formData.append("fifthProductLabel", this.pageCMSData.fifthProductLabel ? this.pageCMSData.fifthProductLabel : '');
                formData.append("fifthProductDiscount", this.pageCMSData.fifthProductDiscount ? this.pageCMSData.fifthProductDiscount : '');
                formData.append("fifthProductImage", this.imageFiveFile ? this.imageFiveFile : this.pageCMSData.fifthProductImage ? this.pageCMSData.fifthProductImage : '');
                
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.imageOne.value = null;
                this.$refs.imageTwo.value = null;
                this.$refs.imageThree.value = null;
                this.$refs.imageFour.value = null;
                this.$refs.imageFive.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}