import {authHeader} from "../../../auth";

export default {
    name: "PaymentConfiguration",
    data() {
        return {
            dataList: [],
            securityKey: null
        }
    },
    async created() {
        document.title = "Golden Gal - Payment Configuration";
        await this.fetchData();
    },
    methods: {
        fetchData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.paymentConfiguration.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.securityKey = this.dataList.securityKey;
                    }
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        update: async function() {
            if(!this.securityKey) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter security key!"
                });
                return false;
            }
            let data = {
                securityKey: this.securityKey
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.paymentConfiguration.requestURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.fetchData();
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}