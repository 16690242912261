import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
// import $ from 'jquery';
export default {
    data() {
        return {
            paymentList: [],
            deliveryStatus: "",
            filter: {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            data: {
                paymentStatus: "PENDING"
            },
            userList: [],
            orderList: [],
            transIdList: [],
            setURL: this.$serverURL + this.$api.user.customerList,
            sheets: [],
            countryList: [],
            pickUpAddress: "",
            pickUpCity: "",
            pickUpStateCode: "",
            pickUpPostCode: "",
            pickUpCountry: {
                name: 'United States of America',
                code: 'US',
            },
            pickUpRate: "",
            singleItem: {},
            refundAmount: "",
            amount: 0,
        }
    },
    async mounted() {
        document.title = "Golden Gal - Payment list";
        await this.getPaymentList();
        await this.getCustomerList();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getPaymentList() {
            this.paymentList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.paymentConfig.paymentListURL + '?transId=' + this.filter.transId + "&userName=" + this.filter.userName + "&orderId=" + this.filter.orderId + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate,
                    headers: authHeader(),
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.paymentList = await response.data.data;
                        if (this.orderList.length == 0) {
                            this.orderList = await response.data.data.map(el => (el.invoiceID));
                        }
                        if (this.transIdList.length == 0) {
                            this.transIdList = await response.data.data.map(el => (el.paymentTransID));
                        }
                    }

                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
            }
        },

        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = response.data.data.map(el => {
                        return { value: el.fullName, label: el.fullName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        refundItem(data) {
            this.singleItem = data;
            this.refundAmount = (parseFloat(data.paymentAmount) - parseFloat(data.refundAmount)).toFixed(2);
            this.amount = this.refundAmount;
        },
        checkNumber: function () {
            this.refundAmount = this.refundAmount.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        },
        async refund() {
            try {
                if (this.refundAmount <= parseFloat(this.singleItem.paymentAmount)) {
                    this.$swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Refund!'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            let data = {
                                amount: this.refundAmount,
                                transactionID: this.singleItem.paymentTransID
                            }
                            let config = {
                                method: "PATCH",
                                url: this.$serverURL + this.$api.paymentConfig.refundURL + '/' + this.singleItem.id,
                                data: data,
                                headers: authHeader(),
                            };
                            this.$swal.fire({
                                title: 'Please Wait...'
                            });
                            this.$swal.showLoading();
                            await this.$axios(config).then((response) => {
                                this.$swal.close();
                                if (response.data.statusCode == 200) {
                                    this.singleItem = {};
                                    this.refundAmount = 0;
                                    document.getElementById('refundCloseBtn').click();
                                    window.$('#example').DataTable().destroy();
                                    this.getPaymentList();
                                    window.$('#example').DataTable();
                                    this.$swal.fire({
                                        toast: true,
                                        position: 'top-end',
                                        // title: "Good Choice",
                                        text: response.data.message,
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        animation: false,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                        }
                                    });
                                }
                            }).catch(error => {
                                this.$swal.close();
                                this.$swal.fire({
                                    icon: "error",
                                    text: error.response.data.message
                                });
                            });
                        }
                    })
                } else {
                    this.$swal.fire({
                        text: "Refund ammount can not be greater then total amount!!.",
                        icon: 'info'
                    })
                }

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                else if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                } else {
                    if (this.filter.transId == null) {
                        this.filter.transId = ""
                    }
                    if (this.filter.userName == null) {
                        this.filter.userName = ""
                    }
                    if (this.filter.orderId == null) {
                        this.filter.orderId = ""
                    }
                    window.$('#example').DataTable().destroy();
                    await this.getPaymentList();
                    window.$('#example').DataTable();
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async clearFilter() {
            this.filter = {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getPaymentList();
            window.$('#example').DataTable();
        },
        exportOrders: function () {
            this.paymentList.map((el) => {
                this.sheets.push({
                    'Invoice ID': el.invoiceID,
                    "Customer Name": el.customerName,
                    "Payment Amount": el.paymentAmount,
                    "Payment TransID": el.paymentTransID,
                    "Refund TransID": el.refundTransID ? el.refundTransID : "",
                    "Payment Date": this.$filters.formatDate(el.paymentDate),
                    "Trans Status": el.transStatus,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'payment_report(' + date + ').xlsx')
            this.sheets = [];
        },
        parsePayload(data) {
            let result = JSON.parse(data);
            return result;
        }
    }

}