import LvColorpicker from 'lightvue/color-picker';
import { authHeader } from "../../../../auth";

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "Golden Gal -  Style Page";
        this.setURL = this.$serverURL + this.$api.cms.componentCMS.stylePageURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async update() {
            try {
                let data = {
                    pageBackgroundColor: this.pageCMSData.pageBackgroundColor ? this.pageCMSData.pageBackgroundColor : '#FFFFFF',
                    sectionTwoBackgroundColor: this.pageCMSData.sectionTwoBackgroundColor ? this.pageCMSData.sectionTwoBackgroundColor : '#F6F4F2',
                    sectionTwoTitleFontColor: this.pageCMSData.sectionTwoTitleFontColor ? this.pageCMSData.sectionTwoTitleFontColor : '#000000',
                    sectionFiveTitleFontColor: this.pageCMSData.sectionFiveTitleFontColor ? this.pageCMSData.sectionFiveTitleFontColor : '#000000',
                    sectionThreeTitleFontColor: this.pageCMSData.sectionThreeTitleFontColor ? this.pageCMSData.sectionThreeTitleFontColor : '#828282',
                    sectionThreeSubTitleFontColor: this.pageCMSData.sectionThreeSubTitleFontColor ? this.pageCMSData.sectionThreeSubTitleFontColor : '#000000',
                    sectionFourTitleFontColor: this.pageCMSData.sectionFourTitleFontColor ? this.pageCMSData.sectionFourTitleFontColor : '#4F4F4F',
                    sectionFourSubTitleFontColor: this.pageCMSData.sectionFourSubTitleFontColor ? this.pageCMSData.sectionFourSubTitleFontColor : '#333333'
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}