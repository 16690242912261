import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            type: '',
            template: '',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Mail Template";
    },
    methods: {
        validate: function () {
            if (!this.type) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select type!"
                });
                return false;
            }
            if (!this.template) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter template content!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                try {

                    // let formData = new FormData();
                    // formData.append('name', this.name);
                    // formData.append('linkType', this.linkType);
                    // formData.append('link', this.link);
                    // formData.append('image', this.image);
                    let data = {
                        type: this.type,
                        template: this.template,
                    }
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.cms.mailTemplate.requestURL,
                        data: data,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.type = '';
                    this.template = '';
                    this.$router.push("/email-template");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}