import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            productList: [],
            filter: {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            userList: [],
            salesTotal: 0,
            salesTotalQuantity: 0,
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.returnProducts.requestURL + "?status=Approved",
            sheets: [],
        }
    },
    async mounted() {
        document.title = "Golden Gal - Approved Return Product";
        this.loadJs();
        await this.getReturnList();
        await this.getCustomerList();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getReturnList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.setURL,
                    headers: authHeader(),
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.productList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error)
                });
            } catch (error) {
                console.log(error)
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },

        async clearFilter() {
            this.filter = {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },

        exportData: function () {
            this.productList.map((el) => {
                this.sheets.push({
                    'OrderId': el.orderId,
                    "Customer Name": el.customerName,
                    "Product Name": el.productName,
                    "Quantity": el.quantity,
                    "Actual Price": el.actualPrice,
                    "Status": el.status,
                });
            });

            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'approved_product_return_report(' + date + ').xlsx');
            this.sheets = [];
        },
    }

}