import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditGlossary",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            title: '',
            description: '',
            category: '',
            editID: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Glossary";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.learn.glossaryURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id,
                            this.title = this.dataList.title,
                            this.description = this.dataList.description,
                            this.category = this.dataList.category
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        update: async function () {
            try {
                if (!this.title) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title!"
                    });
                    return;
                }
                else if (!this.description) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description!"
                    });
                    return;
                }
                else if (!this.category) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please select category!"
                    });
                    return;
                }
                let data = {
                    title: this.title,
                    description: this.description,
                    category: this.category
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.learn.glossaryURL + '/' + this.editID,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.$router.push("/glossary");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}