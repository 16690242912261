import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            returnPolicyData: {
                title: "",
                description: ""
            },
            setURL: this.$serverURL + this.$api.policies.returnPolicy,
        }

    },
    async created() {
        document.title = "Golden Gal - Return Policy";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.returnPolicyData = this.pageCMS;
        }
        else {
            this.returnPolicyData = {
                title: "",
                description: ""
            };
        }
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    title: this.returnPolicyData.title,
                    description: this.returnPolicyData.description,
                };
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    },
}