import { authHeader } from "../../../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.popUp.newsletterCMS,
            pageCMSData: [],
            imagePath: this.$imageURL + 'newsletter/',
            previewLogo: '',
            previewImage: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Newsletter";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadLogo(event) {
            this.pageCMSData.companyLogo = event.target.files[0];
            let input = this.$refs.addLogo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewLogo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('image', this.pageCMSData.image);
                formData.append('title', this.pageCMSData.title);
                formData.append('emailTtile', this.pageCMSData.emailTtile);
                formData.append('buttonText', this.pageCMSData.buttonText);
                formData.append('emailPlaceHolderText', this.pageCMSData.emailPlaceHolderText);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                this.$refs.backgroundImage.value = null;
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}