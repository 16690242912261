export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                pageTitle: null,
                headerTitle: null,
                headerSubTitle: null,
            },
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Gift Card Cms";
        this.setURL = this.$serverURL + this.$api.cms.giftCardCmsURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
    },
    methods: {
        async update() {
            try {
                let token = localStorage.getItem('token');
                let data = {
                    pageTitle: this.pageCMSData.pageTitle,
                    headerTitle: this.pageCMSData.headerTitle,
                    headerSubTitle: this.pageCMSData.headerSubTitle,
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.blockOneImage.value = null;
                this.$refs.blockTwoImage.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}