import { authHeader } from "../../../auth";

export default {
    name: "EditGallery",
    data() {
        return {
            image: '',
            previewImage: '',
            id: this.$route.params.id,
            singleGiftCard: {},
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Gift Card";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.giftCard.giftCardURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.singleGiftCard = response.data.data[0];
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function () {
            if (!this.singleGiftCard.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
                return false;
            }
            if (!this.singleGiftCard.warranty) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter warranty!"
                });
                return false;
            }
            if (!this.singleGiftCard.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter price!"
                });
                return false;
            }
            if (!this.singleGiftCard.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter cost!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append('name', this.singleGiftCard.name);
                    formData.append('warranty', this.singleGiftCard.warranty);
                    formData.append('price', this.singleGiftCard.price);
                    formData.append('cost', this.singleGiftCard.cost);
                    formData.append('description', this.singleGiftCard.description);
                    formData.append('image', this.image ? this.image : this.singleGiftCard.image);
                    let config = {
                        method: 'PATCH',
                        url: this.$serverURL + this.$api.giftCard.giftCardURL + '/' + this.id,
                        data: formData,
                        headers: authHeader()
                    };
                    this.$swal.showLoading();
                    await this.updateDataToBackend(config);
                    this.$swal.hideLoading();
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.singleGiftCard = {};
                    this.$router.push("/gift-card");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        },
    }
}