import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            displayName: '',
            email: '',
            phone: null,
            userType: 'customer',
            password: null,
            confirmPassword: null,
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            setURL: '',
            singleCustomer: {},
            sheets: [],
            country: "United States of America"
        }
    },
    async created() {
        document.title = "Golden Gal - Customer";
        this.setURL = this.$serverURL + this.$api.user.customerList;
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        checkValidation: function() {
            if(!this.fullName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter full name!"
                })
                return false;
            }
            else if(!this.displayName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter display name!"
                })
                return false;
            }
            else if(!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                })
                return false;
            }
            else if(!this.phone) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter phone!"
                })
                return false;
            }
            else if (this.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone no nust be at least 10 digits."
                });
                return false;
            }
            else if(!this.password) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter password!"
                })
                return false;
            }
            else if(!this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter confirm password!"
                })
                return false;
            }
            else if(this.confirmPassword !== this.password) {
                this.$swal.fire({
                    icon: "info",
                    text: "Password and confirm password doesn't match!"
                })
                return false;
            }
            return true;
        },
        addCustomer: async function () {
            if(this.checkValidation() == true) {
                let data = {
                    fullName: this.fullName,
                    displayName: this.displayName,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                    country: this.country,
                    //userType: this.userType
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.registrationURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                this.fullName = '';
                this.displayName = '';
                this.email = '';
                this.phone = '';
                this.userType = 'customer';
                this.password = '';
                this.confirmPassword = '';
                this.inputTypePassword = "password";
                this.inputTypeConfirmPassword = "password";
                await this.getDataList(this.setURL);
                document.getElementById("addButton").click();
            }
        },
        editCustomer: function (data) {
            this.singleCustomer = data;
        },
        updateCustomer: async function () {
            if (!this.singleCustomer.fullName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter full name!"
                });
            }
            else if (!this.singleCustomer.displayName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter display name!"
                });
            }
            else if (!this.singleCustomer.phone) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter phone!"
                });
            }
            else if (this.singleCustomer.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone no nust be at least 10 digits."
                });
                return false;
            }
            else {
                let data = {
                    fullName: this.singleCustomer.fullName,
                    displayName: this.singleCustomer.displayName,
                    phone: this.singleCustomer.phone,
                    //userType: this.singleCustomer.userType
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUserDetails + this.singleCustomer.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                document.getElementById("updateButton").click();
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteCustomer: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUserDetails + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Customer Name": el.fullName,
                    "Mobile": el.phone,
                    "Email": el.email,
                    "Address": el.streetAddress,
                    "City": el.city,
                    "Post Code": el.postCode,
                    "Country": el.country,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'customer_report(' + date + ').xlsx');
            this.sheets = [];
        }
    }
}