<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Header Footer Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Header Footer CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Header Footer CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8" v-if="dataLoaded">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Logo: <strong>[Preferred Image Size: 66X200, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control" type="file" accept="image/*" @change="uploadImageOne($event)" ref="imageOne"
                      id="formFile">
                  </div>
                  <div class="mb-3">
                    <div class="col-md-12">
                      <img v-if="previewImageOne" :src="previewImageOne" height="100" width="150" />
                      <img v-else-if="pageCMSData.logo" :src="pageCMSData.logo" height="100" width="150" />
                      <img v-else src="../../../../assets/images/default.png" height="100" width="150" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label>Description:</label>
                    <ckeditor :editor="editor" v-model="pageCMSData.description" :config="editorConfig">
                    </ckeditor>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Email:</label>
                    <input type="text" v-model="pageCMSData.email" class="form-control" placeholder="Enter email">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Phone:</label>
                    <input type="text" v-model="pageCMSData.phone" class="form-control" placeholder="Enter phone number">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Fax:</label>
                    <input type="text" v-model="pageCMSData.fax" class="form-control" placeholder="Enter fax">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Copyright Text:</label>
                    <input type="text" v-model="pageCMSData.copyrightText" class="form-control" placeholder="Enter copyright text">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Payment Image: <strong>[Preferred Image Size: 36X290, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control" type="file" accept="image/*" @change="uploadImageTwo($event)" ref="imageTwo"
                      id="formFile">
                  </div>
                  <div class="mb-3">
                    <div class="col-md-12">
                      <img v-if="previewImageTwo" :src="previewImageTwo" height="100" width="150" />
                      <img v-else-if="pageCMSData.paymentMethodImage" :src="pageCMSData.paymentMethodImage" height="100" width="150" />
                      <img v-else src="../../../../assets/images/default.png" height="100" width="150" />
                    </div>
                  </div>

                  <hr />
                  <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label">Splash Screen Heading:</label>
                        <input type="text" v-model="pageCMSData.splashScreenHeading" class="form-control">
                      </div>
                      <div class="col-md-6">
                        <div class="input-group">
                            <LvColorpicker label="Splash Screen Heading Color:" v-model="pageCMSData.splashScreenHeadingColor" :clearable="true"
                                :bottomBar="true" :colors="colors" />
                        </div>
                      </div>
                      
                  </div>
                  <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label">Splash Screen Sub-Heading:</label>
                        <input type="text" v-model="pageCMSData.splashScreenSubHeading" class="form-control">
                      </div>
                      <div class="col-md-6">
                          <div class="input-group">
                              <LvColorpicker label="Splash Screen Sub-Heading Color:" v-model="pageCMSData.splashScreenSubHeadingColor" :clearable="true"
                                  :bottomBar="true" :colors="colors" />
                          </div>
                      </div>
                      
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-4">
                      <div class="input-group">
                          <LvColorpicker label="Splash Screen First Color:" v-model="pageCMSData.splashScreenFirstColor" :clearable="true"
                              :bottomBar="true" :colors="colors" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="input-group">
                          <LvColorpicker label="Splash Screen Second Color:" v-model="pageCMSData.splashScreenSecondColor" :clearable="true"
                              :bottomBar="true" :colors="colors" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="input-group">
                          <LvColorpicker label="Splash Screen Third Color:" v-model="pageCMSData.splashScreenThirdColor" :clearable="true"
                              :bottomBar="true" :colors="colors" />
                      </div>
                    </div>
                      
                  </div>

                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/footer-cms.js"></script>