import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            singleContent: {},
            id: this.$route.params.id,
            dataLoaded: false,
            previewImage:""
        }
    },
    async created() {
        document.title = "Golden Gal -  Home section six content";
        await this.getData();
    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.home.homePageSectionSixCMS + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.singleContent = response.data.data;
                    this.dataLoaded = true;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.singleContent.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function () {
            if (!this.singleContent.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter Title!"
                });
                return false;
            }
            if (!this.singleContent.image) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select featured image!"
                });
                return false;
            }
            if (!this.singleContent.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.singleContent.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (!this.singleContent.buttonColor) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please button color!"
                });
                return false;
            }
            return true;
        },
        updateContent: async function () {
            if (this.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append('title', this.singleContent.title);
                    formData.append('buttonText', this.singleContent.buttonText);
                    formData.append('buttonLink', this.singleContent.buttonLink);
                    formData.append('buttonColor', this.singleContent.buttonColor);
                    formData.append('image', this.singleContent.image);
                    let config = {
                        method: 'PATCH',
                        url: this.$serverURL + this.$api.cms.home.homePageSectionSixCMS+'/'+this.id,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.updateDataToBackend(config);
                    this.title = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.buttonColor = '';
                    this.featuredImage = "";
                    this.previewImage = "";
                    this.$refs.imageFile.value = null;
                    this.$router.push("/home-page-section-six-cms");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}