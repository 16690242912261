import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';
//import $ from 'jquery';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            colors: [
                "#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50"
                                                     , "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ],
            setURL: '',
            pageCMSData: [],
            pageCicleCMSNewData: {top:'', left:''},
            image: "",
            previewImage: "",
            imageOne: "",
            cicleImage: "",
            previewImageOne: "",
            previewCicleImage: "",
            imageTwo: "",
            previewImageTwo: "",
            imageThree: "",
            previewImageThree: "",
            productLink: "",
            productTwoLink: "",
            productThreeLink: "",
            enableDotFeature: false,
            modalShown: false,
            dotfeatureData:[],
            dataLoaded: false,
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.home.homePageSectionSevenCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;

            console.log('this.pageCMSData =',this.pageCMSData);
            console.log('this.pageCMSData =',JSON.parse(this.pageCMSData.productBackgroundDotFeature));
            if(this.pageCMSData.productBackgroundDotFeature)
            {
                this.dotfeatureData = JSON.parse(this.pageCMSData.productBackgroundDotFeature);
            }
            

        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        showModal() {
            this.modalShown = true;
        },
        loadExistingCircle(row) {
            console.log(row);
            this.pageCicleCMSNewData.top = row.top;
            this.pageCicleCMSNewData.left =row.left;
            this.pageCicleCMSNewData.productName =row.productName;
            this.pageCicleCMSNewData.productPrice = row.productPrice;
            this.pageCicleCMSNewData.productLink = row.productLink;
            this.pageCicleCMSNewData.productImage = row.productImage;

            window.$("#exampleVerticallycenteredModal").modal('show');

        },
        drawDot(event, targetElement) {

            this.pageCicleCMSNewData.top = "";
            this.pageCicleCMSNewData.left ="";
            this.pageCicleCMSNewData.productName ="";
            this.pageCicleCMSNewData.productPrice = "";
            this.pageCicleCMSNewData.productLink = "";
            this.pageCicleCMSNewData.productImage = "";

            let mouseX = event.offsetX;
            let mouseY = event.offsetY;
            
            let imageWidth = targetElement.offsetWidth;
            let imageHeight = targetElement.offsetHeight;
            
            // calculate the percentage and viewport values
            let top = (mouseY / imageHeight) * 100 + '%';
            let left = (mouseX / imageWidth) * 100 + 'vw';

            this.pageCicleCMSNewData.top = top;
            this.pageCicleCMSNewData.left = left;

            let htmlString = `<div style="position: absolute; top: `+top+`; left:`+left+`; z-index:999999;">
                                <button type="button"  style="width: 22px;
                                height: 22px;
                                background:#ed1122 !important;
                                border: 4px solid hsla(12,11%,91%,.5) !important;
                                border-radius: 50% !important;"></button>
                             </div>`;
            console.log(htmlString);
            // let container = document.querySelector('#imageDotFetaureContainer');
            // container.insertAdjacentHTML('beforeend', htmlString);
            //this.$refs.exampleFullScreenModal.hide();
            // this.modalShown = true;
            //window.$("#exampleFullScreenModal").modal('hide');
            
            window.$("#exampleVerticallycenteredModal").modal('show');

        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImageOne: function (event) {
            this.imageOne = event.target.files[0];
            let input = this.$refs.imageOne;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageOne = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadCicleImage: function (event) {
            this.cicleImage = event.target.files[0];
            let input = this.$refs.cicleImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewCicleImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImageTwo: function (event) {
            this.imageTwo = event.target.files[0];
            let input = this.$refs.imageTwo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageTwo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImageThree: function (event) {
            this.imageThree = event.target.files[0];
            let input = this.$refs.imageThree;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageThree = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let formData =  new FormData();
                formData.append('productName', this.pageCMSData.productName ? this.pageCMSData.productName: '');
                formData.append('productLink', this.pageCMSData.productLink ? this.pageCMSData.productLink: '');
                formData.append('productPrice', this.pageCMSData.productPrice ? this.pageCMSData.productPrice: 0);
                formData.append('productImage', this.imageOne ? this.imageOne : this.pageCMSData.productImage ? this.pageCMSData.productImage: '');
                formData.append('productTwoName', this.pageCMSData.productTwoName ? this.pageCMSData.productTwoName: '');
                formData.append('productTwoLink', this.pageCMSData.productTwoLink ? this.pageCMSData.productTwoLink: '');
                formData.append('productTwoPrice', this.pageCMSData.productTwoPrice ? this.pageCMSData.productTwoPrice: 0);
                formData.append('productTwoImage', this.imageTwo ? this.imageTwo : this.pageCMSData.productTwoImage ? this.pageCMSData.productTwoImage: '');
                formData.append('productThreeName', this.pageCMSData.productThreeName ? this.pageCMSData.productThreeName: '');
                formData.append('productThreeLink', this.pageCMSData.productThreeLink ? this.pageCMSData.productThreeLink: '');
                formData.append('productThreePrice', this.pageCMSData.productThreePrice ? this.pageCMSData.productThreePrice: 0);
                formData.append('productDotCicleColor', this.pageCMSData.productDotCicleColor ? this.pageCMSData.productDotCicleColor: '');
                formData.append('productThreeImage', this.imageThree ? this.imageThree : this.pageCMSData.productThreeImage ? this.pageCMSData.productThreeImage: '');
                formData.append('backgroundImage', this.image ? this.image : this.pageCMSData.backgroundImage ? this.pageCMSData.backgroundImage: '');
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homePageSectionSevenCMS,
                    data: formData,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.imageOne.value = null;
                this.$refs.imageTwo.value = null;
                this.$refs.imageThree.value = null;
                this.$refs.backgroundImage.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        async updateNewCirleCms() {
            try {
                let formData =  new FormData();
                formData.append('top', this.$refs.circleTop.value ? this.$refs.circleTop.value : '');
                formData.append('left', this.$refs.circleLeft.value ? this.$refs.circleLeft.value : '');
                formData.append('productName', this.$refs.circleProductName.value ? this.$refs.circleProductName.value: '');
                formData.append('productLink', this.$refs.circleProductLink.value ? this.$refs.circleProductLink.value: '');
                formData.append('productPrice', this.$refs.circleProductPrice.value ? this.$refs.circleProductPrice.value: 0);
                formData.append('productImage', this.cicleImage ? this.cicleImage : this.pageCicleCMSNewData.productImage ? this.pageCicleCMSNewData.productImage: '');
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homePageSectionSevenCMSNewCircle,
                    data: formData,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.cicleImage.value = null;
                await this.getCmsData(this.setURL);

                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
        
                    console.log('this.pageCMSData =',this.pageCMSData);
                    console.log('this.pageCMSData =',JSON.parse(this.pageCMSData.productBackgroundDotFeature));
                    if(this.pageCMSData.productBackgroundDotFeature)
                    {
                        this.dotfeatureData = JSON.parse(this.pageCMSData.productBackgroundDotFeature);
                        window.$("#exampleVerticallycenteredModal").modal('hide');
                    }
                    
        
                }
                else {
                    this.pageCMSData = [];
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        async deleteCirleCms() {
            try {
                let formData =  {
                    'top':this.$refs.circleTop.value ? this.$refs.circleTop.value : '',
                    'left':this.$refs.circleLeft.value ? this.$refs.circleLeft.value : ''
                };
                //formData.append('top', this.$refs.circleTop.value ? this.$refs.circleTop.value : '');
                //formData.append('left', this.$refs.circleLeft.value ? this.$refs.circleLeft.value : '');

                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homePageSectionSevenCMSDeleteCircle,
                    data: formData,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.cicleImage.value = null;
                await this.getCmsData(this.setURL);

                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
        
                    console.log('this.pageCMSData =',this.pageCMSData);
                    console.log('this.pageCMSData =',JSON.parse(this.pageCMSData.productBackgroundDotFeature));
                    if(this.pageCMSData.productBackgroundDotFeature)
                    {
                        this.dotfeatureData = JSON.parse(this.pageCMSData.productBackgroundDotFeature);
                        window.$("#exampleVerticallycenteredModal").modal('hide');
                    }
                    
        
                }
                else {
                    this.pageCMSData = [];
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        enableDotFeature: async function() {
            this.enableDotFeature = true
        }
    }
}