import LvColorpicker from 'lightvue/color-picker';
import { authHeader } from "../../../../auth";

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "Golden Gal - Learn Page";
        this.setURL = this.$serverURL + this.$api.cms.componentCMS.learnPageURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async update() {
            try {
                let data = {
                    pageBackgroundColor: this.pageCMSData.pageBackgroundColor ? this.pageCMSData.pageBackgroundColor : '#FFFFFF',
                    pageHeaderBackgroundColor: this.pageCMSData.pageHeaderBackgroundColor ? this.pageCMSData.pageHeaderBackgroundColor : '#EAE6E5',
                    pageHeaderFontColor: this.pageCMSData.pageHeaderFontColor ? this.pageCMSData.pageHeaderFontColor : '#000000',
                    pageTitleFontColor: this.pageCMSData.pageTitleFontColor ? this.pageCMSData.pageTitleFontColor : '#000000',
                    sectionOneTitleFontColor: this.pageCMSData.sectionOneTitleFontColor ? this.pageCMSData.sectionOneTitleFontColor : '#000000',
                    sectionTwoTitleFontColor: this.pageCMSData.sectionTwoTitleFontColor ? this.pageCMSData.sectionTwoTitleFontColor : '#000000',
                    sectionThreeTitleFontColor: this.pageCMSData.sectionThreeTitleFontColor ? this.pageCMSData.sectionThreeTitleFontColor : '#000000',
                    sectionFourTitleFontColor: this.pageCMSData.sectionFourTitleFontColor ? this.pageCMSData.sectionFourTitleFontColor : '#000000',
                    sectionFiveTitleFontColor: this.pageCMSData.sectionFiveTitleFontColor ? this.pageCMSData.sectionFiveTitleFontColor : '#000000',
                    sectionSixTitleFontColor: this.pageCMSData.sectionSixTitleFontColor ? this.pageCMSData.sectionSixTitleFontColor : '#000000',
                    sectionSevenTitleFontColor: this.pageCMSData.sectionSevenTitleFontColor ? this.pageCMSData.sectionSevenTitleFontColor : '#000000',
                    sectionEightTitleFontColor: this.pageCMSData.sectionEightTitleFontColor ? this.pageCMSData.sectionEightTitleFontColor : '#000000'
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}