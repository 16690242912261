import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            title: '',
            description: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Learn Tab";
    },
    methods: {
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                try {
                    let data = {
                        title: this.title,
                        description: this.description
                    }
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.cms.learnTab,
                        data: data,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.title = '';
                    this.description = '';
                    this.$router.push("/learn-tab");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}