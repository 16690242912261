<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Customer Review</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Edit Customer Review</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Reviewer Name:</label>
                                        <input type="text" v-model="reviewerName" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Reviewer Image:</label>
                                        <input class="form-control" @change="onImageChange" type="file" ref="imageFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img class="img-thumbnail" v-if="previewImage" :src="previewImage" height="100" width="150"/>
                                            <img class="img-thumbnail" v-else :src="reviewerImage" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Message:</label>
                                        <ckeditor :editor="editor" v-model="message" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update" type="button" class="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/edit-customer-review.js"></script>