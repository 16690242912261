import { authHeader } from "../../../auth";

export default {
    name: "EditGallery",
    data() {
        return {
            title: '',
            subTitle: '',
            blockOneImage: '',
            previewBlockOneImage: '',
            blockOneImageFile: '',
            blockOneTitle: '',
            blockOneLink: '',
            blockTwoImage: '',
            previewBlockTwoImage: '',
            blockTwoImageFile: '',
            blockTwoTitle: '',
            blockTwoLink: '',
            buttonText: '',
            editID: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Collection";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.collection.collectionPage + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id;
                        this.title = this.dataList.title;
                        this.subTitle = this.dataList.subTitle;
                        this.blockOneImage = this.dataList.blockOneImage;
                        this.blockOneTitle = this.dataList.blockOneTitle;
                        this.blockOneLink = this.dataList.blockOneLink;
                        this.blockTwoImage = this.dataList.blockTwoImage;
                        this.blockTwoTitle = this.dataList.blockTwoTitle;
                        this.blockTwoLink = this.dataList.blockTwoLink;
                        this.buttonText = this.dataList.buttonText;
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onBlockOneImageChange: function (event) {
            this.blockOneImageFile = event.target.files[0];
            let input = this.$refs.blockOneImageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewBlockOneImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onBlockTwoImageChange: function (event) {
            this.blockTwoImageFile = event.target.files[0];
            let input = this.$refs.blockTwoImageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewBlockTwoImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter sub title!"
                });
                return false;
            }
            if(!this.blockOneTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block one title!"
                });
                return false;
            }
            if(!this.blockOneLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block one link!"
                });
                return false;
            }
            if(!this.blockTwoTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block two title!"
                });
                return false;
            }
            if(!this.blockTwoLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block two link!"
                });
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if(this.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append('title', this.title);
                    formData.append('subTitle', this.subTitle);
                    formData.append('blockOneImage', this.blockOneImageFile ? this.blockOneImageFile : this.blockOneImage);
                    formData.append('blockOneTitle', this.blockOneTitle);
                    formData.append('blockOneLink', this.blockOneLink);
                    formData.append('blockTwoImage', this.blockTwoImageFile ? this.blockTwoImageFile : this.blockTwoImage);
                    formData.append('blockTwoTitle', this.blockTwoTitle);
                    formData.append('blockTwoLink', this.blockTwoLink);
                    formData.append('buttonText', this.buttonText);
                    let config = {
                        method: 'PATCH',
                        url: this.$serverURL + this.$api.cms.collection.collectionPage + '/' + this.editID,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.updateDataToBackend(config);
                    this.previewBlockTwoImage = '';
                    this.$refs.blockTwoImageFile.value = null;
                    this.previewBlockOneImage = '';
                    this.$refs.blockOneImageFile.value = null;
                    this.$router.push("/collection-page");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}