<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Learn Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Page Title:</label>
                                        <input type="text" v-model="pageCMSData.pageTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Page Sub Title:</label>
                                        <input type="text" v-model="pageCMSData.pageSubTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.pageDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionOneTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionOneDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <!-- <div class="mb-3">
                                        <label class="form-label">Section One Image: <strong>[Preferred Image Size: 658X512, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadSectionOneImage($event)" ref="sectionOneImage" id="formFile">
                                    </div> -->
                                    <!-- <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewSectionOneImage" :src="previewSectionOneImage" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.sectionOneImage" :src="pageCMSData.sectionOneImage" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionTwoTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionTwoDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Image: <strong>[Preferred Image Size: 1130X1060, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadSectionTwoImage($event)" ref="sectionTwoImage" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewSectionTwoImage" :src="previewSectionTwoImage" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.sectionTwoImage" :src="pageCMSData.sectionTwoImage" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Three Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionThreeTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Three Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionThreeDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Four Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionFourTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Four Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionFourDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Four Image: <strong>[Preferred Image Size: 954X1128, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadSectionFourImage($event)" ref="sectionFourImage" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewSectionFourImage" :src="previewSectionFourImage" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.sectionFourImage" :src="pageCMSData.sectionFourImage" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Five Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionFiveTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Five Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionFiveDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Six Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionSixTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Six Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionSixDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Six Image: <strong>[Preferred Image Size: 285X814, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadSectionSixImage($event)" ref="sectionSixImage" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewSectionSixImage" :src="previewSectionSixImage" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.sectionSixImage" :src="pageCMSData.sectionSixImage" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Seven Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionSevenTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Seven Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.sectionSevenDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Seven Image: <strong>[Preferred Image Size: 954X1128, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadSectionSevenImage($event)" ref="sectionSevenImage" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewSectionSevenImage" :src="previewSectionSevenImage" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.sectionSevenImage" :src="pageCMSData.sectionSevenImage" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Eight Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionEightTitle" class="form-control">
                                    </div> -->
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/learn-page-cms.js"></script>