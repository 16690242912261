import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            title: "",
            buttonText: "",
            buttonLink: "",
            buttonColor: "#ed1122",
            featuredImage: "",
            previewImage: "",
        }
    },
    created() {
        document.title = "Golden Gal -  Home section six content";
    },
    methods: {
        onImageChange: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter Title!"
                });
                return false;
            }
            if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select featured image!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (!this.buttonColor) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please button color!"
                });
                return false;
            }
            return true;
        },
        addContent: async function () {
            if (this.validate()==true) {
                try {
                    let formData = new FormData();
                    formData.append('title', this.title);
                    formData.append('buttonText', this.buttonText);
                    formData.append('buttonLink', this.buttonLink);
                    formData.append('buttonColor', this.buttonColor);
                    formData.append('image', this.featuredImage);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.cms.home.homePageSectionSixCMS,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.title = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.buttonColor = '';
                    this.featuredImage = "";
                    this.previewImage = "";
                    this.$refs.imageFile.value = null;
                    this.$router.push("/home-page-section-six-cms");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}