<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Product Hint List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto"></div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0 text-uppercase">Product Hint List</h6>
                </div>
                <div class="col-6 float-right">
                    <a role="button" class="btn btn-warning btn-sm m-1 mb-md-0" style="float: right" @click="exportData()">
                        <i class="fa-solid fa-file-export"></i> Export
                    </a>
                </div>
            </div>
            <hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Sender Name</th>
                                    <th>Sender Email</th>
                                    <th>Friend Email</th>
                                    <th>Message</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{data.senderName}}</td>
                                    <td>{{data.senderEmail}}</td>
                                    <td>{{data.friendEmail}}</td>
                                    <td>{{data.message.slice(0, 50)}}</td>
                                    <td>{{$filters.formatDate(data.createdAt)}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="deleteData(data.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Sender Name</th>
                                    <th>Sender Email</th>
                                    <th>Friend Email</th>
                                    <th>Message</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/product-hint.js'></script>


<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>
