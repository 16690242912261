<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Product Details Component CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Product Details Component CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Product Details Component CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Product Title Font Color:"
                                        v-model="pageCMSData.productTitleFontColor" :clearable="true" :bottomBar="true"
                                        :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Page Background Color:"
                                        v-model="pageCMSData.pageBackgroundColor" :clearable="true" :bottomBar="true"
                                        :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Product Description Background Color:"
                                        v-model="pageCMSData.descriptionBackgroundColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Related Product Title Font Color:"
                                        v-model="pageCMSData.relatedProductTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Ready to Ship Button Color:"
                                        v-model="pageCMSData.readyToShipButtonColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Size Button Color:"
                                        v-model="pageCMSData.sizeButtonColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Material Button Color:"
                                        v-model="pageCMSData.materialButtonColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Add to Cart Button Color:"
                                        v-model="pageCMSData.addToCartButtonColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="d-grid">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                                    <button class="btn btn-primary" @click="update" type="button">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/product-details-component-cms.js"></script>