import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditSectionTwo",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            title: '',
            description: '',
            buttonText: '',
            buttonLink: '',
            imageFile: '',
            image: '',
            editID: '',
            previewImage: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Home Page Section Two";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.sectionTwoURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id,
                            this.title = this.dataList.title,
                            this.description = this.dataList.description,
                            this.buttonText = this.dataList.buttonText,
                            this.buttonLink = this.dataList.buttonLink,
                            this.image = this.dataList.image
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        save: async function () {
            try {
                if (!this.description) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter description!"
                    });
                    return;
                }
                else if (!this.buttonText) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter button text!"
                    });
                    return;
                }
                else if (!this.buttonLink) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter button link!"
                    });
                    return;
                }
                let formData = new FormData();
                formData.append('title', this.title);
                formData.append('description', this.description);
                formData.append('buttonText', this.buttonText);
                formData.append('image', this.imageFile ? this.imageFile : this.image);
                formData.append('buttonLink', this.buttonLink);
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.home.sectionTwoURL + '/' + this.editID,
                    data: formData,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.updateDataToBackend(config);
                this.$swal.hideLoading();
                this.previewImage = '';
                this.$refs.imageFile.value = null;
                this.$router.push("/section-two");
            } catch (error) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                });
                return false;
            }
        },

    }
}