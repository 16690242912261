import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LinkShortcut from "../../../layouts/template/LinkShortcut";
import LvColorpicker from 'lightvue/color-picker';
import { authHeader } from '../../../../auth';
export default {
    components: {
        LinkShortcut,
        LvColorpicker
    },
    data() {
        return {
            setURL: '',
            dataList: [],
            previewImage: null,
            imageFile: null,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            dataLoaded: false,
            colors: [
                "#E91E63", "#F44336", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"
                , "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#795548"]
        }
    },
    async created() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.home.homePageSectionSixCMS;
        await this.getData();
    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.home.homePageSectionSixCMS,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    if (response.data.data) {
                        this.dataList = await response.data.data;
                    }
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.cms.home.homePageSectionSixCMS + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }

    }
}