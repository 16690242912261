<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;">
                  <i class="bx bx-home-alt"></i>
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Create Order</li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->

      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Order</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label">
                      <strong>
                        Customer
                        Information
                      </strong>
                    </label>
                    <br />
                    <label for="inputProductTitle" class="form-label" style="margin-right: 5px">
                      <strong>
                        Customer Type:
                        {{ customerType }}
                      </strong>
                    </label>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        v-model="isExistingUser"
                        checked
                        :value="false"
                      />
                      <label class="form-check-label" for="exampleRadios1">New</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="inlineCheckbox2"
                        v-model="isExistingUser"
                        :value="true"
                      />
                      <label class="form-check-label" for="inlineCheckbox2">Existing</label>
                    </div>

                    <div class="mb-3" v-if="isExistingUser">
                      <label for="inputProductTitle" class="form-label">Select Customer</label>
                      <Multiselect
                        v-model="userDetails"
                        id="inputVendor"
                        @select="
                          () => {
                            setUserData(userDetails);
                          }
                        "
                        :options="userList"
                        :searchable="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Name</label>
                      <input
                        type="email"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="userName"
                        placeholder="Enter name"
                      />
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm mb-3">
                          <label for="inputProductTitle" class="form-label">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="userEmail"
                            placeholder="Enter email"
                          />
                        </div>
                        <div class="col-sm mb-3">
                          <label for="inputProductTitle" class="form-label">Contact No</label>
                          <input
                            type="tel"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="userMobile"
                            placeholder="Enter phone no"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label">
                      <strong>Shipping Information</strong>
                    </label>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.name"
                            placeholder="Enter name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Street Address One</label>
                          <!-- <textarea
                            class="form-control"
                            id="floatingTextarea"
                            v-model="shippingAddressData.addressOne"
                            placeholder="Enter street address one"
                          ></textarea> -->
                          <GMapAutocomplete @place_changed="setShippingAddressOne" :value="shippingAddressData.addressOne" :class="'form-control'"></GMapAutocomplete>
                        </div>
                        <div class="col">
                          <label
                            for="inputProductTitle"
                            class="form-label"
                          >Street Address Two (Optional)</label>
                          <GMapAutocomplete @place_changed="setShippingAddressTwo" :value="shippingAddressData.addressTwo" :class="'form-control'"></GMapAutocomplete>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Street Address One</label>
                         
                          <GMapAutocomplete @place_changed="setShippingAddressOne" :value="shippingAddressData.addressOne" :class="'form-control'"></GMapAutocomplete>
                        </div>
                        <div class="col">
                          <label
                            for="inputProductTitle"
                            class="form-label"
                          >Street Address Two (Optional)</label>
                          <textarea
                            class="form-control"
                            id="floatingTextarea"
                            v-model="shippingAddressData.addressTwo"
                            placeholder="Enter street address two"
                          ></textarea>
                          <GMapAutocomplete @place_changed="setShippingAddressTwo" :value="shippingAddressData.addressTwo" :class="'form-control'"></GMapAutocomplete>
                        </div>
                      </div>
                    </div> -->
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">City</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.city"
                            placeholder="Enter city"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">Zip Code</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.postCode"
                            placeholder="Enter zip code"
                          />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">State</label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            v-model="stateCode"
                          >
                            <option style="display: none" value selected>Select State</option>
                            <option
                              v-for="(data, index) in states"
                              :key="index"
                              :value="data.code"
                            >{{ data.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.email"
                            placeholder="Enter email"
                          />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">Contact No</label>
                          <input
                            type="tel"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.phone"
                            placeholder="Enter phone no"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row"></div>
                    </div>
                    <!-- <div id="chart2"></div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <div class="border border-3 p-4 rounded">
                  <div class="row g-3">
                    <div class="invoice-body">
                      <!-- Row start -->
                      <div class="row gutters">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="table-responsive">
                            <table class="table custom-table m-0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Product</th>
                                  <th scope="col">Color</th>
                                  <th scope="col">Size</th>
                                  <th scope="col">Material</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Unit Price</th>
                                  <th scope="col">Total Amount</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(data, index) in addedProductList" :key="index">
                                  <td scope="row">{{ index + 1 }}</td>
                                  <td scope="row" style="width: 20%">
                                    <Multiselect
                                      v-model="productValue[index]"
                                      @select="
                                        () => {
                                          productData(
                                            productValue[index],
                                            index
                                          );
                                        }
                                      "
                                      :options="productList"
                                      :searchable="true"
                                    />
                                  </td>
                                  <td scope="row" style="width: 20%">
                                    <select
                                      class="form-select"
                                      aria-label="Default select example"
                                      :onChange="
                                        () => {
                                          selectColor(index);
                                        }
                                      "
                                      v-model="colorValue[index]"
                                    >
                                      <option style="display: none" selected>Select Color</option>
                                      <option
                                        v-for="(data, index) in selectedProduct[
                                          index
                                        ] &&
                                        selectedProduct[index]
                                          .productColorVariants"
                                        :key="index"
                                        :value="data.colorName"
                                      >{{ data.colorName }}</option>
                                    </select>
                                  </td>
                                  <td scope="row" style="width: 20%">
                                    <select
                                      class="form-select"
                                      aria-label="Default select example"
                                      :onChange="
                                        () => {
                                          selectSize(index);
                                        }
                                      "
                                      v-model="sizeValue[index]"
                                    >
                                      <option style="display: none" selected>Select Size</option>
                                      <option
                                        v-for="(data, index) in selectedProduct[
                                          index
                                        ] &&
                                        selectedProduct[index]
                                          .productSizeVariants"
                                        :key="index"
                                        :value="data.sizeName"
                                      >{{ data.sizeName }}</option>
                                    </select>
                                  </td>
                                  <td scope="row" style="width: 20%">
                                    <select
                                      class="form-select"
                                      aria-label="Default select example"
                                      :onChange="
                                        () => {
                                          selectMaterial(index);
                                        }
                                      "
                                      v-model="materialValue[index]"
                                    >
                                      <option style="display: none" selected>Select Material</option>
                                      <option
                                        v-for="(data, index) in selectedProduct[
                                          index
                                        ] &&
                                        selectedProduct[index]
                                          .productMaterialVariants"
                                        :key="index"
                                        :value="data.materialName"
                                      >{{ data.materialName }}</option>
                                    </select>
                                  </td>
                                  <td scope="row">
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="decreaseQuantity(index)"
                                    >-</button>
                                    &ensp;
                                    {{
                                    selectedProduct[index]
                                    ? selectedProduct[index]?.quantity
                                    ? selectedProduct[index]?.quantity
                                    : 1
                                    : 0
                                    }}
                                    &ensp;
                                    <button
                                      @click="increaseQuantity(index)"
                                      class="btn btn-success btn-sm"
                                    >+</button>
                                  </td>
                                  <th scope="row">
                                    <input
                                      type="text"
                                      v-model="selectedProduct[index].price"
                                      v-on:keyup="calculateTotal"
                                      @change="calculateTotal"
                                      class="form-control"
                                      v-if="selectedProduct[index]"
                                    />
                                  </th>
                                  <th scope="row">
                                    {{
                                    selectedProduct[index]?.price
                                    ? selectedProduct[index]?.price *
                                    selectedProduct[index]?.quantity
                                    : selectedProduct[index]
                                    ?.discountedPrice
                                    ? selectedProduct[index]
                                    ?.discountedPrice *
                                    selectedProduct[index]?.quantity
                                    : 0
                                    }}
                                  </th>
                                  <th>
                                    <span
                                      :hidden="addedProductList.length < 2"
                                      class="badge bg-danger"
                                      @click="removeItem(index)"
                                    >Remove</span>
                                  </th>
                                </tr>
                                <tr>
                                  <td colspan="8">&nbsp;</td>
                                  <td style="float: right">
                                    <button
                                      class="btn btn-sm btn-success"
                                      @click="addProduct()"
                                    >Add More</button>
                                  </td>
                                </tr>
                                <hr />
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col" colspan="2">Gift Card</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col" colspan="2">Unit Price</th>
                                  <th scope="col" colspan="2">Total Amount</th>
                                  <th scope="col">Action</th>
                                </tr>
                                <tr v-for="(data, index) in addedCardList" :key="index">
                                  <td scope="row">{{ index + 1 }}</td>
                                  <td scope="row" colspan="2" style="width: 20%">
                                    <Multiselect
                                      v-model="cardValue[index]"
                                      @select="
                                        () => {
                                          cardData(
                                            cardValue[index],
                                            index
                                          );
                                        }
                                      "
                                      :options="cardList"
                                      :searchable="true"
                                    />
                                  </td>
                                  <td scope="row">
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="decreaseCardQuantity(index)"
                                    >-</button>
                                    &ensp;
                                    {{
                                    selectedCard[index]
                                    ? selectedCard[index]?.quantity
                                    ? selectedCard[index]?.quantity
                                    : 1
                                    : 0
                                    }}
                                    &ensp;
                                    <button
                                      @click="increaseCardQuantity(index)"
                                      class="btn btn-success btn-sm"
                                    >+</button>
                                  </td>
                                  <th scope="row" colspan="2">
                                    <input
                                      type="text"
                                      v-model="selectedCard[index].price"
                                      v-on:keyup="calculateCard"
                                      @change="calculateCard"
                                      class="form-control"
                                      v-if="selectedCard[index]"
                                    />
                                  </th>
                                  <th scope="row" colspan="2">
                                    {{
                                    selectedCard[index]?.price
                                    ? selectedCard[index]?.price *
                                    selectedCard[index]?.quantity
                                    : selectedCard[index]
                                    ?.discountPrice
                                    ? selectedCard[index]
                                    ?.discountedPrice *
                                    selectedCard[index]?.quantity
                                    : 0
                                    }}
                                  </th>
                                  <th>
                                    <span
                                      :hidden="addedCardList.length < 2"
                                      class="badge bg-danger"
                                      @click="removeCard(index)"
                                    >Remove</span>
                                  </th>
                                </tr>
                                <tr>
                                  <td colspan="8">&nbsp;</td>
                                  <td style="float: right">
                                    <button
                                      class="btn btn-sm btn-success"
                                      @click="addCard()"
                                    >Add More</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="6">
                                    <div class="card radius-10" v-if="selectedProduct.length > 0">
                                      <div class="card-body" v-if="smList">
                                        <label for="inputProductTitle" class="form-label">
                                          <strong>Shipping Method</strong>
                                        </label>
                                        <br />

                                        <div
                                          v-for="(data, index) in smList"
                                          :key="index"
                                          class="form-check form-check-inline"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="shippingType"
                                            :value="data.id"
                                            @click="setShippingPrice(data)"
                                          />
                                          <label
                                            class="form-check-label"
                                          >{{data.name}} (${{data.price}})</label>
                                        </div>
                                      </div>
                                    </div>
                                    <textarea
                                      class="form-control"
                                      v-model="remarks"
                                      placeholder="Leave a comment here"
                                      id="floatingTextarea2"
                                      style="height: 100px"
                                    ></textarea>
                                  </td>
                                  <td colspan="2">
                                    <p>
                                      Subtotal
                                      <br />
                                    </p>
                                    <p v-if="shippingCost">
                                      Shipping Cost
                                      <br />
                                    </p>
                                    <p v-if="salesTaxAmount">
                                      Sales Tax ({{stRate}}%)
                                      <br />
                                    </p>
                                    <h5 class="text-success mb-5">
                                      <strong>Discount</strong>
                                    </h5>

                                    <h5 class="text-primary">
                                      <strong>Grand Total</strong>
                                    </h5>
                                  </td>
                                  <td>
                                    <p>${{ grandSubTotal }}</p>
                                    <p v-if="shippingCost">${{ shippingCost }}</p>
                                    <p v-if="salesTaxAmount">${{ salesTaxAmount }}</p>
                                    <p>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="discount"
                                        v-on:keyup="addDiscount"
                                        @change="addDiscount"
                                        placeholder="ex: 10%, 20% or 10, 20"
                                      />
                                    </p>
                                    <h5
                                      class="text-primary"
                                      :style="{'position': 'absolute', 'margin-top':'19px'}"
                                    >
                                      <strong v-if="grandTotal > 0">
                                        ${{
                                        parseFloat(
                                        (grandTotal-0) + (shippingCost-0) + (salesTaxAmount-0)
                                        ).toFixed(2)
                                        }}
                                      </strong>
                                      <strong v-else>$0</strong>
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <button
                            class="btn btn-success"
                            @click="setPaymentAmount"
                            data-bs-toggle="modal"
                            data-bs-target="#addPayment"
                          >Confirm Order</button>
                        </div>
                      </div>
                      <!-- Row end -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addPayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="addExpensesModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Card Information</h5>
              <button
                type="button"
                id="buttonClose"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="payment-container preload" style="margin: 0 auto">
                    <div class="creditcard">
                      <div class="front">
                        <div id="ccsingle"></div>
                        <svg
                          version="1.1"
                          id="cardfront"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 750 471"
                          style="enable-background: new 0 0 750 471"
                          xml:space="preserve"
                        >
                          <g id="Front">
                            <g id="CardBackground">
                              <g id="Page-1_1_">
                                <g id="amex_1_">
                                  <path
                                    id="Rectangle-1_1_"
                                    class="lightcolor grey"
                                    d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z"
                                  />
                                </g>
                              </g>
                              <path
                                class="darkcolor greydark"
                                d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"
                              />
                            </g>
                            <text
                              transform="matrix(1 0 0 1 60.106 295.0121)"
                              id="svgnumber"
                              class="st2 st3 st4"
                            >
                              {{
                              paymentInfo.cardNumber
                              ? paymentInfo.cardNumber
                              : "0123 4567 8910 1112"
                              }}
                            </text>
                            <text
                              transform="matrix(1 0 0 1 54.1064 428.1723)"
                              id="svgname"
                              class="st2 st5 st6"
                            >
                              {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                              }}
                            </text>
                            <text
                              transform="matrix(1 0 0 1 54.1074 389.8793)"
                              class="st7 st5 st8"
                            >cardholder name</text>
                            <text
                              transform="matrix(1 0 0 1 479.7754 388.8793)"
                              class="st7 st5 st8"
                            >expiration</text>
                            <text
                              transform="matrix(1 0 0 1 65.1054 241.5)"
                              class="st7 st5 st8"
                            >card number</text>
                            <g>
                              <text
                                transform="matrix(1 0 0 1 574.4219 433.8095)"
                                id="svgexpire"
                                class="st2 st5 st9"
                              >{{ expireDate ? expireDate : "01/23" }}</text>
                              <text
                                transform="matrix(1 0 0 1 479.3848 417.0097)"
                                class="st2 st10 st11"
                              >VALID</text>
                              <text
                                transform="matrix(1 0 0 1 479.3848 435.6762)"
                                class="st2 st10 st11"
                              >THRU</text>
                              <polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
                            </g>
                            <g id="cchip">
                              <g>
                                <path
                                  class="st2"
                                  d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                                />
                              </g>
                              <g>
                                <g>
                                  <rect x="82" y="70" class="st12" width="1.5" height="60" />
                                </g>
                                <g>
                                  <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
                                </g>
                                <g>
                                  <path
                                    class="st12"
                                    d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                  />
                                </g>
                                <g>
                                  <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
                                </g>
                                <g>
                                  <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
                                </g>
                                <g>
                                  <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
                                </g>
                                <g>
                                  <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g id="Back" />
                        </svg>
                      </div>
                      <div class="back">
                        <svg
                          version="1.1"
                          id="cardback"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 750 471"
                          style="enable-background: new 0 0 750 471"
                          xml:space="preserve"
                        >
                          <g id="Back">
                            <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
                          </g>
                          <g id="Back">
                            <g id="Page-1_2_">
                              <g id="amex_2_">
                                <path
                                  id="Rectangle-1_2_"
                                  class="darkcolor greydark"
                                  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z"
                                />
                              </g>
                            </g>
                            <rect y="61.6" class="st2" width="750" height="78" />
                            <g>
                              <path
                                class="st3"
                                d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z"
                              />
                              <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
                              <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
                              <path
                                class="st5"
                                d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                              />
                            </g>
                            <text
                              transform="matrix(1 0 0 1 621.999 227.2734)"
                              id="svgsecurity"
                              class="st6 st7"
                            >
                              {{
                              paymentInfo.securityCode
                              ? paymentInfo.securityCode
                              : "985"
                              }}
                            </text>
                            <g class="st8">
                              <text
                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                class="st9 st6 st10"
                              >security code</text>
                            </g>
                            <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
                            <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
                            <text
                              transform="matrix(1 0 0 1 59.5073 228.6099)"
                              id="svgnameback"
                              class="st12 st13"
                            >
                              {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                              }}
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="field-container mb-2">
                    <label class="payment-label" for="name">Name</label>
                    <input
                      class="payment-input"
                      id="name"
                      maxlength="20"
                      type="text"
                      v-model="paymentInfo.name"
                    />
                  </div>
                  <div class="field-container mb-2">
                    <label class="payment-label" for="cardnumber">Card Number</label>
                    <!-- <span id="generatecard">generate random</span> -->
                    <input
                      id="cardnumber"
                      type="text"
                      pattern="^[0-9]$"
                      v-on:keyup="formatCCnum"
                      name="cardnumber"
                      class="payment-input"
                      v-model="paymentInfo.cardNumber"
                    />
                    <svg
                      id="ccicon"
                      class="ccicon"
                      width="750"
                      height="471"
                      viewBox="0 0 750 471"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    />
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <div class="field-container">
                        <label class="payment-label" for="expirationdate">Expiration (mm/yy)</label>
                        <input
                          id="expirationdate"
                          class="payment-input"
                          type="text"
                          maxlength="5"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          v-model="expireDate"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="field-container">
                        <label class="payment-label" for="securitycode">Security Code</label>
                        <input
                          id="securitycode"
                          class="payment-input"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          v-model="paymentInfo.securityCode"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="field-container">
                        <label class="payment-label" for="securitycode">Amount</label>
                        <input class="payment-input" type="text" v-model="paymentInfo.amount" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary py-3 px-5 me-4"
                @click="handlePayment('Pay Now')"
              >CONTINUE PAYMENT</button>
              <button
                type="button"
                class="btn btn-secondary py-3 px-5"
                @click="handlePayment('Pay Later')"
              >CONTINUE WITHOUT PAYMENT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@import "../css/payment.css";
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container_check input:checked ~ .checkmark {
  background-color: #004dda;
  border: 1px solid transparent;
}

.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dddddd;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_check input:checked ~ .checkmark:after {
  display: block;
}

/* Radio buttons */
.container_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_radio input {
  position: absolute;
  opacity: 0;
}

.container_radio input:checked ~ .checkmark:after {
  opacity: 1;
}

.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: 50%;
}

.container_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #004dda;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>
<script src="../js/create-order.js"></script>