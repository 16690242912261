import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            previewImageOne: null,
            previewImageTwo: null,
            previewImageThree: null,
            imageOneFile: null,
            imageTwoFile: null,
            imageThreeFile: null,
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            dataLoaded: false,
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Footer";
        this.setURL = this.$serverURL + this.$api.cms.footerCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }

        this.dataLoaded = true;
    },
    methods: {
        uploadImageOne: function (event) {
            this.imageOneFile = event.target.files[0];
            let input = this.$refs.imageOne;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageOne = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageTwo: function (event) {
            this.imageTwoFile = event.target.files[0];
            let input = this.$refs.imageTwo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageTwo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                const formData = new FormData();
                formData.append("description", this.pageCMSData.description ? this.pageCMSData.description : '');
                formData.append("email", this.pageCMSData.email ? this.pageCMSData.email : '');
                formData.append("phone", this.pageCMSData.phone ? this.pageCMSData.phone : '');
                formData.append("fax", this.pageCMSData.fax ? this.pageCMSData.fax : '');
                formData.append("logo", this.imageOneFile ? this.imageOneFile : this.pageCMSData.logo ? this.pageCMSData.logo : '');
                formData.append("paymentMethodImage", this.imageTwoFile ? this.imageTwoFile : this.pageCMSData.paymentMethodImage ? this.pageCMSData.paymentMethodImage : '');
                formData.append("splashScreenHeading", this.pageCMSData.splashScreenHeading ? this.pageCMSData.splashScreenHeading : '');
                formData.append("splashScreenHeadingColor", this.pageCMSData.splashScreenHeadingColor ? this.pageCMSData.splashScreenHeadingColor : '');
                formData.append("splashScreenSubHeading", this.pageCMSData.splashScreenSubHeading ? this.pageCMSData.splashScreenSubHeading : '');
                formData.append("splashScreenSubHeadingColor", this.pageCMSData.splashScreenSubHeadingColor ? this.pageCMSData.splashScreenSubHeadingColor : '');
                formData.append("splashScreenFirstColor", this.pageCMSData.splashScreenFirstColor ? this.pageCMSData.splashScreenFirstColor : '');
                formData.append("splashScreenSecondColor", this.pageCMSData.splashScreenSecondColor ? this.pageCMSData.splashScreenSecondColor : '');
                formData.append("splashScreenThirdColor", this.pageCMSData.splashScreenThirdColor ? this.pageCMSData.splashScreenThirdColor : '');

                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.footerCMS,
                    data: formData,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.imageOne.value = null;
                this.$refs.imageTwo.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}