import { authHeader } from "../../../../../../auth";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';


export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            type: '',
            template: {
                logo: {
                    image: '',
                    url: 'https://golden-gal.nyc3.cdn.digitaloceanspaces.com/FooterCms/goldengal.a75cc544-501e-45ce-a766-534589c368da_do.png',
                    width: 200,
                    height: 66,
                    borderSize: 0,
                    borderStyle: null,
                    borderColor: null,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                    objectFit: null,
                    backgroundColor: '#f9f9f9',
                },
                welcome: {
                    background: {
                        bgColor: '#c2e0f4',
                        textColor: '#000000',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        width: 600,
                        borderRadius: 0,
                    },
                    image: {
                        sectionOneImageRaw: '',
                        url: 'https://i.ibb.co/Yf1v0Mf/Welcome-Email.png',
                        width: 315,
                        height: 297,
                        border: 0,
                        objectFit: null,
                        borderSize: 0,
                        borderStyle: null,
                        borderColor: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleOne: {
                        text: 'New Customer On-Board On Site.',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        color: '#000000',
                        fontSize: 32,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleTwo: {
                        text: 'Dear Admin,',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        color: null,
                        fontSize: 16,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleThree: {
                        text: 'A new customer has been joined to Golden Gal. Below check the cusotmer',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        color: null,
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    paragraph: {
                        text: 'Thank you for creating account!',
                        color: null,
                        fontSize: 22,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }

                },
                sectionTwo: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    title: {
                        text: "Let's Get Started",
                        fontSize: 28,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    subTitle: {
                        text: 'Call to action 1',
                        fontSize: 20,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                    },
                    paragraph: {
                        text: 'SenderName shared something special with you from Goldengal Jewelry',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        fontSize: 32,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    button: {
                        text: 'SHOP NOW',
                        link: 'https://goldengaljewelry.com/product-details/{{product.id}}',
                        fontSize: 14,
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        paddingTop: 10,
                        paddingRight: 0,
                        paddingBottom: 10,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    image: {
                        sectionThreeSideImageRaw: "",
                        url: 'https://serving.photos.photobox.com/6972900774b111a33d68c259a410f8993718c49ac2e723aa5cdf7da91b35df585744fe3b.jpg',
                        width: 193,
                        height: 217,
                        objectFit: null,
                        borderSize: 0,
                        borderStyle: null,
                        borderColor: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }
                },
                footer: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    paragraph: {
                        text: 'If you have any questions, feel free message us at support@mailus.com. All right reserved. Update',
                        fontSize: 14,
                        color: '#555555',
                        email: {
                            text: 'email preferences',
                            link: '',
                            color: '#555555',
                        },
                        unsubscribe: {
                            text: 'unsubscribe',
                            link: '',
                            color: '#555555',
                        },
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    termsPolicy: {
                        termsText: 'Terms of use',
                        policyText: 'Privacy Policy',
                        fontSize: 12,
                        color: '#555555',
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }
                },
                copyRight: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    whiteDown: {
                        whiteDownImageRow: '',
                        url: 'https://ibb.co/xj86D8M',
                        width: 700,
                        height: 21,
                    },
                    copyRightImage: {
                        copyRightImageRow: '',
                        url: 'https://neutrix.co/uploads/images/20230213-63ea88220b1bf.png',
                        link: '',
                        width: 34,
                        height: 32
                    },
                    copyRightText: {
                        text: 'Designed By Neutrix',
                        link: 'https://neutrix.co/',
                        fontSize: 15,
                        color: '#9d9d9d',
                        textDecoration: 'none'
                    },
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                }
            },
            colors: [
                "#E91E63", "#F44336", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"
                , "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#795548"],
            titleOneStatus: false,
            titleTwoStatus: false,
            titleThreeStatus: false,
            welcomeParagraphStatus: false,
            sectionTwoTitleStatus: false,
            sectionTwoSubTitleStatus: false,
            sectionTwoParagraphStatus: false,
            sectionTwoButtonTextStatus: false,
            footerParagraphStatus: false,
            footerTermsTextStatus: false,
            footerPolicyTextStatus: false,
            templateData: [],
        }
    },

    async mounted() {
        document.title = "Golden Gal - Create Admin Email Template";
        await this.getDataList();
        await this.getTemplate();
    },
    methods: {
        getTemplate: async function () {

            //let emailBody = document.querySelector("#emailBody").innerHTML="";
            //console.log('email Body ', emailBody);

            try {
                this.$swal.showLoading();
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.emailTemplate.NewCustomerAdminEmail,
                    headers: authHeader(),
                };

                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        if (response?.data?.data.template != null && JSON.parse(response?.data?.data.template)?.template != undefined) {
                            this.template = JSON.parse(response?.data?.data.template)?.template;
                        }
                        this.$swal.close();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        publishUpdateTemplate: async function () {
            let emailHead = `<!DOCTYPE html>

                    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                    <head>
                    <title></title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                    <style>
                        /* -------------------------------------
                        GLOBAL RESETS
                    ------------------------------------- */
                        /*All the styling goes here*/
                        img {
                            border: none;
                            -ms-interpolation-mode: bicubic;
                            max-width: 100%;
                        }

                        body {
                            background-color: #f6f6f6;
                            font-family: sans-serif;
                            -webkit-font-smoothing: antialiased;
                            font-size: 14px;
                            line-height: 1.4;
                            margin: 0;
                            padding: 0;
                            -ms-text-size-adjust: 100%;
                            -webkit-text-size-adjust: 100%;
                        }

                        table {
                            border-collapse: separate;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            width: 100%;
                        }

                        table td {
                            font-family: sans-serif;
                            font-size: 14px;
                            vertical-align: top;
                        }

                        /* -------------------------------------
                        BODY & CONTAINER
                    ------------------------------------- */
                        .body {
                            background-color: #f6f6f6;
                            width: 100%;
                        }

                        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                        .container {
                            display: block;
                            margin: 0 auto !important;
                            /* makes it centered */
                            max-width: 580px;
                            padding: 10px;
                            width: 580px;
                        }

                        /* This should also be a block element, so that it will fill 100% of the .container */
                        .content {
                            box-sizing: border-box;
                            display: block;
                            margin: 0 auto;
                            max-width: 580px;
                            padding: 10px;
                        }

                        /* -------------------------------------
                        HEADER, FOOTER, MAIN
                    ------------------------------------- */
                        .main {
                            background: #ffffff;
                            border-radius: 3px;
                            width: 100%;
                        }

                        .wrapper {
                            box-sizing: border-box;
                            padding: 20px;
                        }

                        .content-block {
                            padding-bottom: 10px;
                            padding-top: 10px;
                        }

                        .footer {
                            clear: both;
                            margin-top: 10px;
                            text-align: center;
                            width: 100%;
                        }

                        .footer td,
                        .footer p,
                        .footer span,
                        .footer a {
                            color: #999999;
                            font-size: 12px;
                            text-align: center;
                        }

                        /* -------------------------------------
                        TYPOGRAPHY
                    ------------------------------------- */
                        h1,
                        h2,
                        h3,
                        h4 {
                            color: #000000;
                            font-family: sans-serif;
                            font-weight: 400;
                            line-height: 1.4;
                            margin: 0;
                            margin-bottom: 30px;
                        }

                        h1 {
                            font-size: 35px;
                            font-weight: 300;
                            text-align: center;
                            text-transform: capitalize;
                        }

                        p,
                        ul,
                        ol {
                            font-family: sans-serif;
                            font-size: 14px;
                            font-weight: normal;
                            margin: 0;
                            margin-bottom: 15px;
                        }

                        p li,
                        ul li,
                        ol li {
                            list-style-position: inside;
                            margin-left: 5px;
                        }

                        a {
                            color: #3498db;
                            text-decoration: underline;
                        }

                        /* -------------------------------------
                        BUTTONS
                    ------------------------------------- */
                        .btn {
                            box-sizing: border-box;
                            width: 100%;
                        }

                        .btn>tbody>tr>td {
                            padding-bottom: 15px;
                        }

                        .btn table {
                            width: auto;
                        }

                        .btn table td {
                            background-color: #ffffff;
                            border-radius: 5px;
                            text-align: center;
                        }

                        .btn a {
                            background-color: #ffffff;
                            border: solid 1px #3498db;
                            border-radius: 5px;
                            box-sizing: border-box;
                            color: #3498db;
                            cursor: pointer;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: bold;
                            margin: 0;
                            padding: 12px 25px;
                            text-decoration: none;
                            text-transform: capitalize;
                        }

                        .btn-primary table td {
                            background-color: #3498db;
                        }

                        .btn-primary a {
                            background-color: #3498db;
                            border-color: #3498db;
                            color: #ffffff;
                        }

                        /* -------------------------------------
                        OTHER STYLES THAT MIGHT BE USEFUL
                    ------------------------------------- */
                        .last {
                            margin-bottom: 0;
                        }

                        .first {
                            margin-top: 0;
                        }

                        .align-center {
                            text-align: center;
                        }

                        .align-right {
                            text-align: right;
                        }

                        .align-left {
                            text-align: left;
                        }

                        .clear {
                            clear: both;
                        }

                        .mt0 {
                            margin-top: 0;
                        }

                        .mb0 {
                            margin-bottom: 0;
                        }

                        .preheader {
                            color: transparent;
                            display: none;
                            height: 0;
                            max-height: 0;
                            max-width: 0;
                            opacity: 0;
                            overflow: hidden;
                            mso-hide: all;
                            visibility: hidden;
                            width: 0;
                        }

                        .powered-by a {
                            text-decoration: none;
                        }

                        hr {
                            border: 0;
                            border-bottom: 1px solid #f6f6f6;
                            margin: 20px 0;
                        }

                        /* -------------------------------------
                        RESPONSIVE AND MOBILE FRIENDLY STYLES
                    ------------------------------------- */
                        @media only screen and (max-width: 620px) {
                            table[class=body] h1 {
                                font-size: 28px !important;
                                margin-bottom: 10px !important;
                            }

                            table[class=body] p,
                            table[class=body] ul,
                            table[class=body] ol,
                            table[class=body] td,
                            table[class=body] span,
                            table[class=body] a {
                                font-size: 16px !important;
                            }

                            table[class=body] .wrapper,
                            table[class=body] .article {
                                padding: 10px !important;
                            }

                            table[class=body] .content {
                                padding: 0 !important;
                            }

                            table[class=body] .container {
                                padding: 0 !important;
                                width: 100% !important;
                            }

                            table[class=body] .main {
                                border-left-width: 0 !important;
                                border-radius: 0 !important;
                                border-right-width: 0 !important;
                            }

                            table[class=body] .btn table {
                                width: 100% !important;
                            }

                            table[class=body] .btn a {
                                width: 100% !important;
                            }

                            table[class=body] .img-responsive {
                                height: auto !important;
                                max-width: 100% !important;
                                width: auto !important;
                            }
                        }

                        /* -------------------------------------
                        PRESERVE THESE STYLES IN THE HEAD
                    ------------------------------------- */
                        @media all {
                            .ExternalClass {
                                width: 100%;
                            }

                            .ExternalClass,
                            .ExternalClass p,
                            .ExternalClass span,
                            .ExternalClass font,
                            .ExternalClass td,
                            .ExternalClass div {
                                line-height: 100%;
                            }

                            .apple-link a {
                                color: inherit !important;
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                text-decoration: none !important;
                            }

                            #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                            }

                            .btn-primary table td:hover {
                                background-color: #34495e !important;
                            }

                            .btn-primary a:hover {
                                background-color: #34495e !important;
                                border-color: #34495e !important;
                            }
                        }
                    </style>
                    </head>
                    <body style="margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">`;
            let emailFotter = `</body></html>`;
            let emailBody = document.querySelector("#emailBody").innerHTML;
            console.log('email Body ', emailBody);

            try {
                let formData = {
                    'emailHead': emailHead,
                    'emailBody': emailBody,
                    'emailFotter': emailFotter,
                    'template': this.template,

                };
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.NewCustomerAdminEmail,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                console.log(config);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },

        uploadFeaturedImage: async function (event) {
            try {
                let input = this.$refs.backgroundImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.logo.url = response
                    this.$swal.close();
                } else {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        uploadSectionTwoImage: async function (event) {
            try {
                let input = this.$refs.sectionTwoImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.welcome.image.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadSectionThreeSideImage: async function (event) {
            try {
                let input = this.$refs.sectionThreeSideImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.sectionTwo.image.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadCopyRightImage: async function (event) {
            try {
                let input = this.$refs.copyRightImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.copyRight.copyRightImage.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },

        handleTitleOneTextShow: function (event) {
            this.titleOneStatus = true;
        },

        handleTitleOneTextClose: function (event) {
            this.titleOneStatus = false;
        },

        handleTitleTwoTextShow: function (event) {
            this.titleTwoStatus = true;
        },

        handleTitleTwoTextClose: function (event) {
            this.titleTwoStatus = false;
        },

        handleTitleThreeTextShow: function (event) {
            this.titleThreeStatus = true;
        },

        handleTitleThreeTextClose: function (event) {
            this.titleThreeStatus = false;
        },

        handleWelcomeParagraphTextShow: function (event) {
            this.welcomeParagraphStatus = true;
        },

        handleWelcomeParagraphTextClose: function (event) {
            this.welcomeParagraphStatus = false;
        },

        handleSectionTwoTitleTextShow: function (event) {
            this.sectionTwoTitleStatus = true;
        },

        handleSectionTwoTitleTextClose: function (event) {
            this.sectionTwoTitleStatus = false;
        },

        handleSectionTwoSubTitleTextShow: function (event) {
            this.sectionTwoSubTitleStatus = true;
        },

        handleSectionTwoSubTitleTextClose: function (event) {
            this.sectionTwoSubTitleStatus = false;
        },

        handleSectionTwoParagraphTextShow: function (event) {
            this.sectionTwoParagraphStatus = true;
        },

        handleSectionTwoParagraphTextClose: function (event) {
            this.sectionTwoParagraphStatus = false;
        },

        handleSectionTwoButtonTextShow: function (event) {
            this.sectionTwoButtonTextStatus = true;
        },

        handleSectionTwoButtonTextClose: function (event) {
            this.sectionTwoButtonTextStatus = false;
        },

        handleFooterParagraphTextShow: function (event) {
            this.footerParagraphStatus = true;
        },

        handleFooterParagraphTextClose: function (event) {
            this.footerParagraphStatus = false;
        },

        handleFooterTermsTextShow: function (event) {
            this.footerTermsTextStatus = true;
        },

        handleFooterTermsTextClose: function (event) {
            this.footerTermsTextStatus = false;
        },

        handleFooterPolicyTextShow: function (event) {
            this.footerPolicyTextStatus = true;
        },

        handleFooterPolicyTextClose: function (event) {
            this.footerPolicyTextStatus = false;
        },

        handleBackgroundCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleBackgroundCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleBackgroundCustomizationAction: function (event) {
            document.querySelector('.background-customize').classList.add('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleLogoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleLogoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleLogoCustomizationAction: function (event) {
            document.querySelector('.logo-customize').classList.add('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneCustomizationAction: function (event) {
            document.querySelector('.section-one-customize').classList.add('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneImageCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneImageCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneImageCustomizationAction: function (event) {
            document.querySelector('.section-one-image-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleOneCustomizationAction: function (event) {
            document.querySelector('.section-one-title-one-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleTwoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleTwoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleTwoCustomizationAction: function (event) {
            document.querySelector('.section-one-title-two-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleThreeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleThreeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleThreeCustomizationAction: function (event) {
            document.querySelector('.section-one-title-three-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneParagraphCustomizationAction: function (event) {
            document.querySelector('.section-one-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoTitleCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoTitleCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoTitleCustomizationAction: function (event) {
            document.querySelector('.section-two-title-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoSubTitleCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoSubTitleCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoSubTitleCustomizationAction: function (event) {
            document.querySelector('.section-two-subtitle-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoParagraphCustomizationAction: function (event) {
            document.querySelector('.section-two-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoButtonCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoButtonCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoButtonCustomizationAction: function (event) {
            document.querySelector('.section-two-button-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoImageCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoImageCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoImageCustomizationAction: function (event) {
            document.querySelector('.section-two-image-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphCustomizationAction: function (event) {
            document.querySelector('.footer-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphEmailCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphEmailCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphEmailCustomizationAction: function (event) {
            document.querySelector('.footer-paragraph-email-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphUnsubscribeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphUnsubscribeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphUnsubscribeCustomizationAction: function (event) {
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleTermsPolicyCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleTermsPolicyCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleTermsPolicyCustomizationAction: function (event) {
            document.querySelector('.terms-policy-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleCopyrightCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleCopyrightCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleCopyrightCustomizationAction: function (event) {
            document.querySelector('.copyright-customize').classList.add('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
        },


        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.elementor.emailKeyword,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.templateData = response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
    }
}