import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            name: '',
            warranty: '',
            prices: '',
            cost: '',
            description: '',
            image: '',
            previewImage: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Gift Card";
    },
    methods: {
        onImageChange: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
                return false;
            }
            if (!this.warranty) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter warranty!"
                });
                return false;
            }
            if (!this.prices) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter prices!"
                });
                return false;
            }
            if (!this.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter cost!"
                });
                return false;
            }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append('name', this.name);
                    formData.append('warranty', this.warranty);
                    formData.append('price', this.prices);
                    formData.append('cost', this.cost);
                    formData.append('description', this.description);
                    formData.append('image', this.image);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.giftCard.giftCardURL,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.name = '';
                    this.link = '';
                    this.image = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$router.push("/gift-card");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}