import { authHeader } from "../../../auth";
export default {
    name: "SalesTax",
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            image: "",
            previewImageOne: "",
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Sales Tax";
        this.setURL = this.$serverURL + this.$api.salesTax.requestURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async updateCms() {
            try {
                let formData = new FormData();
                formData.append('name', this.pageCMSData.name ? this.pageCMSData.name : '');
                formData.append('salesTax', this.pageCMSData.salesTax ? this.pageCMSData.salesTax : '');
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.salesTax.requestURL+'/'+this.pageCMSData.id,
                    data: {
                        name:this.pageCMSData.name ? this.pageCMSData.name : '',
                        salesTax:this.pageCMSData.salesTax ? this.pageCMSData.salesTax : ''
                    },
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.updateDataToBackend(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}