export default {
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            previewImageOne: null,
            previewImageTwo: null,
            previewImageThree: null,
            imageOneFile: null,
            imageTwoFile: null,
            imageThreeFile: null,
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Style Page";
        this.setURL = this.$serverURL + this.$api.cms.stylePage.sectionFiveURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadImageOne: function (event) {
            this.imageOneFile = event.target.files[0];
            let input = this.$refs.imageOne;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageOne = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageTwo: function (event) {
            this.imageTwoFile = event.target.files[0];
            let input = this.$refs.imageTwo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageTwo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadImageThree: function (event) {
            this.imageThreeFile = event.target.files[0];
            let input = this.$refs.imageThree;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageThree = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("sectionTitle", this.pageCMSData.sectionTitle ? this.pageCMSData.sectionTitle : '');
                formData.append("buttonText", this.pageCMSData.buttonText ? this.pageCMSData.buttonText : '');
                formData.append("imageOneTitle", this.pageCMSData.imageOneTitle ? this.pageCMSData.imageOneTitle : '');
                formData.append("imageOneLink", this.pageCMSData.imageOneLink ? this.pageCMSData.imageOneLink : '');
                formData.append("imageOne", this.imageOneFile ? this.imageOneFile : this.pageCMSData.imageOne ? this.pageCMSData.imageOne : '');
                formData.append("imageTwoTitle", this.pageCMSData.imageTwoTitle ? this.pageCMSData.imageTwoTitle : '');
                formData.append("imageTwoLink", this.pageCMSData.imageTwoLink ? this.pageCMSData.imageTwoLink : '');
                formData.append("imageTwo", this.imageTwoFile ? this.imageTwoFile : this.pageCMSData.imageTwo ? this.pageCMSData.imageTwo : '');
                formData.append("imageThreeTitle", this.pageCMSData.imageThreeTitle ? this.pageCMSData.imageThreeTitle : '');
                formData.append("imageThreeLink", this.pageCMSData.imageThreeLink ? this.pageCMSData.imageThreeLink : '');
                formData.append("imageThree", this.imageThreeFile ? this.imageThreeFile : this.pageCMSData.imageThree ? this.pageCMSData.imageThree : '');
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.stylePage.sectionFiveURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.imageOne.value = null;
                this.$refs.imageTwo.value = null;
                this.$refs.imageThree.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}