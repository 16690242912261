import { authHeader } from "../../../auth";
export default {
    data() {
        return {
            securityKey: "",
        }
    },
    async mounted() {
        document.title = "Golden Gal - Payment Settings";
        await this.getData();

    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.paymentConfig.nmiConfigURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.securityKey = await response.data.data[0].securityKey;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message,
                });
            });
        },
        updateSettings: async function () {
            if (!this.securityKey) {
                this.$swal({
                    icon: "error",
                    text: "Key required"
                });
                return;
            } else {
                let data = {
                    securityKey: this.securityKey,
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.paymentConfig.nmiConfigURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    console.log(response);
                    if (response.data.statusCode == 201 || response.data.statusCode == 200) {
                        this.securityKey = await response.data.data.securityKey;
                        this.$swal.fire({
                            icon:"success",
                            text: response.data.message
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message,
                    });
                });
            }
        }
    },

}