import { authHeader } from "../../../../auth";
import html2pdf from 'html2pdf.js';
import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            id: this.$route.params.id,
            orderDetails: {},
            billingAddress: {},
            shippingAddress: {},
            pageCMSData: [],
            productList: [],
            cardList: [],
            itemList: [],
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: ""
            },
            expireDate: "",
            paidAmount: 0,
            note: "",
            selectAll: "",
            selected: [],
            productForUpdateList: [],
            singleReturnProduct: {},
            returnProductList: [],
            bulkUpdate: false,
        }
    },
    async created() {
        this.loadJs();
        await this.getOrderDetails();
        await this.getReturnProductsList();
    },
    computed: {
        calculateGrandTotal() {
            if (parseFloat(this.orderDetails.refundAmount) > 0) {
                return parseFloat(((this.orderDetails.total - 0) + (this.orderDetails.shippingMethodCost - 0) + (this.orderDetails.salesTaxAmount - 0)) - parseFloat(this.orderDetails.refundAmount)).toFixed(2)

            } else {
                return parseFloat(((this.orderDetails.total - 0) + (this.orderDetails.shippingMethodCost - 0) + (this.orderDetails.salesTaxAmount - 0))).toFixed(2)
            }
        },
        calculateDue() {
            return (parseFloat(((this.orderDetails.total - 0) + (this.orderDetails.shippingMethodCost - 0) + (this.orderDetails.salesTaxAmount - 0))) - parseFloat(this.orderDetails.paymentAmount)).toFixed(2)
        },
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getFooterCMS: async function () {
            this.setURL = this.$serverURL + this.$api.cms.footerPageCMS;
            await this.getCmsData(this.setURL);
            if (this.pageCMS) {
                this.pageCMSData = this.pageCMS;
            }
            else {
                this.pageCMSData = [];
            }
        },
        formatCCnum: function () {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        async getOrderDetails() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderListURL + '/' + this.id,
                    headers: authHeader(),
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.itemList = [];
                        this.orderDetails = (response.data.Orders.length > 0) ? response.data.Orders[0] : {};
                        this.billingAddress = this.orderDetails?.billingAddress ? JSON.parse(this.orderDetails.billingAddress) : null;
                        this.shippingAddress = this.orderDetails?.shippingAddress ? JSON.parse(this.orderDetails.shippingAddress) : null;
                        this.productList = this.orderDetails.orderDetails;
                        this.cardList = this.orderDetails.giftCardDetails;
                        this.paymentInfo.amount = this.orderDetails.total - parseFloat(this.orderDetails.paymentAmount);
                        this.paidAmount = this.orderDetails.total - parseFloat(this.orderDetails.paymentAmount);
                        this.productList.map(el => {
                            el.type = "Product";
                            el.price = el.itemPrice;
                            el.amount = el.itemSubTotal;
                            this.itemList.push(el);
                        });
                        this.cardList.map(el => {
                            let data = {
                                name: el.name,
                                featuredImage: el.image,
                                type: "Gift Card",
                                status: el.status,
                                quantity: el.quantity,
                                price: el.itemPrice,
                                amount: el.itemSubTotal
                            }
                            this.itemList.push(data);
                        });
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async getReturnProductsList() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.returnProducts.requestByOrderIdURL + '/' + this.orderDetails.orderId,
                    headers: authHeader(),
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.returnProductList = response.data.data;
                        for (let i = 0; i < this.returnProductList.length; i++) {
                            if (this.returnProductList[i].status == 'Unapproved') {
                                this.bulkUpdate = true;
                                break;
                            } else {
                                this.bulkUpdate = false
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },

        updateReturnStatus: async function (item, status) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Update Status!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let data = {
                            status: status
                        }
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.returnProducts.requestURL + '/' + item.id,
                            data: data,
                            headers: authHeader(),
                        };
                        this.$swal.fire({
                            title: 'Please Wait...'
                        });
                        this.$swal.showLoading();
                        await this.$axios(config).then(async (response) => {
                            this.$swal.close();
                            if (response.data.statusCode == 200) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: response.data.message
                                })
                                await this.getOrderDetails();
                                await this.getReturnProductsList();
                            }
                        }).catch(error => {
                            this.$swal.close();
                            console.log(error)
                        });
                    } catch (error) {
                        this.$swal.close();
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        viewNote: function (data) {
            this.note = data.note;
        },

        increaseQuantity: function () {
            if (this.singleReturnProduct.insertQty >= this.singleReturnProduct.remainingQty) {
                this.singleReturnProduct.insertQty = this.singleReturnProduct.remainingQty;
            } else {
                this.singleReturnProduct.insertQty = this.singleReturnProduct.insertQty + 1;
            }
        },
        decreaseQuantity: function () {
            if (this.singleReturnProduct.insertQty <= 1) {
                this.singleReturnProduct.insertQty = 1;
            } else {
                this.singleReturnProduct.insertQty = this.singleReturnProduct.insertQty - 1;
            }
        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (this.paymentInfo.cardNumber.length <= 14) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Card number must be at least 14 digit"
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else if (!this.paymentInfo.amount) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter payment amount."
                });
                return false;
            } else if (this.paidAmount != this.paymentInfo.amount) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please pay total due amount"
                });
                return false;
            }
            else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        completePayment: async function () {
            if (this.paymentValidation()) {
                let data = {
                    ccNum: this.paymentInfo.cardNumber ? this.paymentInfo.cardNumber.split(' ').join('') : '',
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode,
                    amount: this.paidAmount,
                    orderId: this.orderDetails.orderId
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.paymentURL,
                    data: data,
                    headers: authHeader()
                };
                this.isLoading = true;
                await this.$axios(config).then(async (response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        this.orderDetails = {};
                        this.itemList = [];
                        await this.getOrderDetails();
                        document.getElementById('buttonClose').click();
                    } else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        }).then(result => {
                            console.log(result);
                            this.isLoading = false;
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.errors.responsetext,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        });
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }

        },
        generatePDF: function () {
            const doc = document.getElementById('doc');
            // var opt = {
            //     filename: this.orderDetails.orderId + '.pdf',
            //   };
            var opt = {
                filename: this.orderDetails.orderId + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all', before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        },
        handleReturn: function (data) {
            this.singleReturnProduct = data;
            this.singleReturnProduct.note = ""
            if (this.singleReturnProduct.quantity > this.singleReturnProduct.returnQuantity) {
                this.singleReturnProduct.remainingQty = (this.singleReturnProduct.quantity - this.singleReturnProduct.returnQuantity);
                this.singleReturnProduct.insertQty = this.singleReturnProduct.remainingQty;
            } else {
                this.singleReturnProduct.remainingQty = 0
                this.singleReturnProduct.insertQty = 0;
            }
        },
        //bulk return
        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.returnProductList.map((el, index) => {
                    if (el.status == 'Unapproved') {
                        this.selected[index] = true;
                        this.productForUpdateList.push(el);
                    }
                })
            } else if (this.selectAll == false) {
                this.returnProductList.map((el, index) => {
                    this.selected[index] = false;
                    this.productForUpdateList = [];
                })
            }
        },
        handleSelect: function (index, status, data) {
            if (status == true) {
                this.productForUpdateList[index] = data
            } else if (status == false) {
                this.productForUpdateList[index] = 0
            }
        },

        bulkStatusUpdate: async function (status) {
            if (this.selected.length == 0 || this.productForUpdateList.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a product first."
                })
                return;
            } else if (!status) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a action to perform."
                })
                return;
            }
            if (this.productForUpdateList.length > 0) {

                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Update Status!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let requests = [...new Set(this.productForUpdateList)];
                        let confirmedRequest = requests.filter(item => !(item == 0 || item == undefined));
                        let productList = confirmedRequest.map(el => {
                            return {
                                id: el.id,
                                status: status,
                            }
                        })
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.returnProducts.bulkUpdate,
                            data: {
                                bulkUpdate: productList
                            },
                            headers: authHeader(),
                        };
                        this.$swal.fire({
                            title: 'Please Wait...'
                        });
                        this.$swal.showLoading();
                        await this.$axios(config).then((response) => {
                            this.$swal.close();
                            if (response.data.statusCode == 200) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: response.data.message
                                });
                                this.productForUpdateList = [];
                                this.selected = [];
                                this.selectAll = false;
                                this.getReturnProductsList();
                                this.getOrderDetails();
                            }
                        }).catch(error => {
                            this.$swal.close();
                            console.log(error);
                        });
                    }
                })
            }
        },
        confirmReturn: async function () {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Return Product!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let data = {
                            orderId: this.singleReturnProduct.orderId,
                            customerId: this.singleReturnProduct.customerId,
                            customerName: this.orderDetails.customerName,
                            productId: this.singleReturnProduct.productId,
                            productName: this.singleReturnProduct.name,
                            quantity: this.singleReturnProduct.insertQty,
                            actualPrice: this.singleReturnProduct.itemPrice,
                            totalPrice: (this.singleReturnProduct.itemPrice * this.singleReturnProduct.insertQty),
                            note: this.singleReturnProduct.note,
                            status: 'Approved',
                        }
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.returnProducts.adminReturnRequestURL,
                            data: data,
                            headers: authHeader(),
                        };
                        this.$swal.fire({
                            title: 'Please Wait...'
                        });
                        this.$swal.showLoading();
                        await this.$axios(config).then(async (response) => {
                            this.$swal.close();
                            if (response.data.statusCode == 201) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: "Item returned successfully."
                                })
                                document.getElementById('return-close-btn').click();
                                this.getOrderDetails();
                                this.getReturnProductsList();
                            }
                        }).catch(error => {
                            this.$swal.close();
                            console.log(error)
                        });
                    } catch (error) {
                        this.$swal.close();
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }

    },
    watch: {
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        }
    }
}