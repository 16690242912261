<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Fedex settings</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Fedex CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <hr />

          <div class="form-body mt-4">
            <label class="form-label" style="font-size: 16px; font-weight: 600"
              >Credentials</label
            >
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        v-model="credential"
                        value="sandbox"
                        :checked="isSandbox"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >SandBox Credential</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        v-model="credential"
                        value="live"
                        :checked="isLive"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >Live Credential</label
                      >
                    </div>
                  </div>

                  <div v-if="credential == 'sandbox'">
                    <label class="form-label"
                      ><strong>Sandbox Credential</strong></label
                    >
                    <div class="mb-3">
                      <label class="form-label">Client Id</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxClientId"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Sandbox client id"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Client Secret</label>
                      <div class="input-group mb-3">
                        <input
                          :type="showPass ? 'text' : 'password'"
                          class="form-control"
                          placeholder="Sandbox client secret"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          v-model="pageCMSData.sandboxClientSecret"
                        />
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="button-addon2"
                          @click="showPass = !showPass"
                        >
                          <i class="fa-solid fa-eye ms-2"></i>
                        </button>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Sandbox Url</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxUrl"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Sandbox url"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Shipping Account Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxShippingAccountNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Shipping Account Number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Billing Account Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxBillingAccountNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Billing Account Number"
                      />
                    </div>
                  </div>
                  <div v-if="credential == 'live'">
                    <label class="form-label"
                      ><strong>Live Credential</strong></label
                    >

                    <div class="mb-3">
                      <label class="form-label">Client Id</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveClientId"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Live client id"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Client Secret</label>
                      <div class="input-group mb-3">
                        <input
                          :type="showPass ? 'text' : 'password'"
                          class="form-control"
                          placeholder="Live client secret"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          v-model="pageCMSData.liveClientSecret"
                        />
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="button-addon2"
                          @click="showPass = !showPass"
                        >
                          <i class="fa-solid fa-eye ms-2"></i>
                        </button>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Live Url</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveUrl"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Live url"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Shipping Account Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveShippingAccountNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Shipping Account Number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Billing Account Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveBillingAccountNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Billing Account Number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <label
                  class="form-label"
                  style="font-size: 16px; font-weight: 600"
                  >Shipper Information</label
                >
                <div class="border border-3 p-4 rounded">
                  <!-- <div class="mb-3">
                    <label class="form-label">Shipper Account Address</label>
                    <input
                      type="text"
                      v-model="shippingData.shippingAddress"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Shipper account address"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Street Address Line</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Street address line"
                      rows="3"
                      v-model="shippingData.shippingAddressLine"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">City</label>
                    <input
                      type="text"
                      v-model="shippingData.shippingCity"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="City"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">State Province Code</label>
                    <input
                      type="text"
                      v-model="shippingData.shippingStateProvianceCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="State code"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Postal Code</label>
                    <input
                      type="text"
                      v-model="shippingData.shippingPostalCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="postal code"
                    />
                  </div>
                  <div class="mb-3" hidden>
                    <select
                      v-model="country"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        Select Country
                      </option>
                      <option
                        v-for="(data, index) in countryList"
                        :key="index"
                        :value="{
                          name: data.countryNameEn,
                          code: data.countryCode,
                        }"
                      >
                        {{ data.countryNameEn }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <label
                  class="form-label"
                  style="font-size: 16px; font-weight: 600"
                  >Biller Information</label
                >
                <div class="border border-3 p-4 rounded">
                  <!-- <div class="mb-3">
                    <label class="form-label">Biller Account Address</label>
                    <input
                      type="text"
                      v-model="billingData.billingAccountAddress"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="shipping address"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Street Address Line</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="billingData.billingAddressLine"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">City</label>
                    <input
                      type="text"
                      v-model="billingData.billingCity"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="City"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">State Province Code</label>
                    <input
                      type="text"
                      v-model="billingData.billingStateProvianceCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="State code"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Postal Code</label>
                    <input
                      type="text"
                      v-model="billingData.billingPostalCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="postal code"
                    />
                  </div>
                  <div class="mb-3" hidden>
                    <select
                      v-model="country"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        Select Country
                      </option>
                      <option
                        v-for="(data, index) in countryList"
                        :key="index"
                        :value="{
                          name: data.countryNameEn,
                          code: data.countryCode,
                        }"
                      >
                        {{ data.countryNameEn }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            v-on:click="update()"
            type="button"
            class="btn btn-primary mt-3"
            style="float: right"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/fedex.js"></script>
