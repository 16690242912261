import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as XLSX from 'xlsx';
export default {
    name: "Contact",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            singleContact: {},
            setURL: this.$serverURL + this.$api.product.stockDetail + this.$route.params.id,
            productList: [],
            sheets: []
        }
    },
    async created() {
        document.title = "Golden Gal - StockList";
        await this.getStockProductList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getExcelStockIn: async function () {
            if(this.productList.length > 0){
                this.productList.map((el) => {
                    this.sheets.push({
                        'StockId': el.StockID,
                        "Name": el.Name,
                        'Current Stock': el.CurrentStock,
                        'New Stock': el.NewStock,
                        'New Total Stock': el.NewTotalStock,
                    });
                })
                const data = XLSX.utils.json_to_sheet(this.sheets)
                const wb = XLSX.utils.book_new();
                const date = new Date().toLocaleString();
                XLSX.utils.book_append_sheet(wb, data, 'data');
                XLSX.writeFile(wb,'product-stockin-list('+date+').xlsx');
                this.sheets = [];
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Stock In list is empty!"
                });
            }
            
                
        },
        getStockProductList: async function() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                   
                    this.productList = await response.data.stockin_detail;
                }
                else {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
    }
}