<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Items On Sale
            </li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-0 text-uppercase">Items On Sale</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="exampleOnSale" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Expire date</th>
                  <th>On Sales</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td v-if="data.count == false">{{ data.cost ? data.cost : 0 }}</td>
                  <td style="width: 12%;" v-else>
                    <div class="input-group">
                        <input type="text" v-model="data.cost" class="form-control">
                    </div>
                  </td>
                  <td v-if="data.flag == false">{{ data.price }}</td>
                  <td style="width: 12%;" v-else>
                    <div class="input-group">
                        <input type="text" v-model="data.price" class="form-control">
                    </div>
                  </td>
                  <td style="width: 12%;">
                    <div class="input-group">
                        <input type="date" v-model="data.expireDate" class="form-control" >
                    </div>
                  </td>
                  <td style="width: 12%;">
                    <div class="form-check form-switch">
                      <input class="form-check-input" v-model="data.isSale"  type="checkbox" :id="'flexSwitchCheckDefault-'+index" > 
                    </div>
                    
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Expire date</th>
                  <th>On Sales</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="ms-auto" style="margin-left: 0px !important;">
          <div class="btn-group">
            <button type="button" @click="save()" class="btn btn-primary px-5 btn-sm m-1">Submit</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script src='../js/items-on-sale.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>