import { authHeader } from "../../../auth";

export default {
    name: "EditGallery",
    data() {
        return {
            name: '',
            link: '',
            linkType: '',
            imageFile: '',
            image: '',
            editID: '',
            previewImage: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Gallery";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.requestURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id,
                            this.name = this.dataList.name,
                            this.linkType = this.dataList.linkType,
                            this.link = this.dataList.link,
                            this.image = this.dataList.image
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        save: async function () {
            try {
                if (!this.name) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter name!"
                    });
                    return;
                }
                else if (!this.linkType) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please select link type!"
                    });
                    return;
                }
                else if (!this.link) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter link!"
                    });
                    return;
                }
                else if (!this.image && !this.imageFile) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please upload image!"
                    });
                    return;
                }
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('image', this.imageFile ? this.imageFile : this.image);
                formData.append('linkType', this.linkType);
                formData.append('link', this.link);
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.gallery.requestURL + '/' + this.editID,
                    data: formData,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.previewImage = '';
                this.$refs.imageFile.value = null;
                this.$router.push("/gallery");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}