<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0 text-uppercase">Product Profit Report</h6>
                </div>
                <div class="col-6 float-right">
                    <a role="button" class="btn btn-warning btn-sm m-1 mb-md-0" style="float: right" @click="exportData()">
                        <i class="fa-solid fa-file-export"></i>Export
                    </a>
                    <a role="button" data-bs-toggle="modal" data-bs-target="#filterModal" id="filterTable" class="btn btn-success btn-sm m-1 mb-md-0" style="float: right">
                        <i class="bx bx-filter text-white float-left"></i>Filter
                    </a>
                </div>
            </div>
            <hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Order Id</th>
                                    <th>Product Name</th>
                                    <th>Cost</th>
                                    <th>Item Price</th>
                                    <th>Order Quantity</th>
                                    <th>Cost Total</th>
                                    <th>Price Total</th>
                                    <th>Profit</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in orderList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)">{{ data.orderId }}</a>
                                    </td>
                                    <td>{{ data.product_name }}</td>
                                    <td>{{ data.cost }}</td>
                                    <td>{{ data.itemPrice }}</td>
                                    <td>{{ data.orderQuantity }}</td>
                                    <td>{{ data.costTotal }}</td>
                                    <td>{{ data.priceTotal }}</td>
                                    <td>{{ data.profit }}</td>
                                    <td>{{ $filters.formatOrderDate(data.createdAt) }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Order Id</th>
                                    <th>Product Name</th>
                                    <th>${{ cost }}</th>
                                    <th>${{ itemPrice }}</th>
                                    <th>{{ orderQuantity }}</th>
                                    <th>${{ costTotal }}</th>
                                    <th>${{ priceTotal }}</th>
                                    <th>${{ profit }}</th>
                                    <th>Created At</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Filter -->
            <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="filterModalLabel">Filtering</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form class="filter_form">
                                <div class="row mb-3">
                                    <div class="col-12 col-sm-12">
                                        <label class="form-label">Order Id: {{ filterOrderId }}</label>
                                        <Multiselect v-model="filter.orderId" id="inputVendor" :options="filteredOrderIdList" :searchable="true"/>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Start Date:</label>
                                        <input v-model="filter.startDate" type="date" class="form-control"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">End Date</label>
                                        <input v-model="filter.endDate" type="date" class="form-control"/>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="text-center">
                                        <button type="submit" @click="clearFilter()" class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal">
                                            Clear
                                        </button>
                                        <button type="button" class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal" @click="validateFilter">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filter -->
        </div>
    </div>
</template>

<script src='../js/product-profit-report.js'></script>


<style scoped>
.edit-button {
    background-color: #49abab;
}
.confirm-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>