import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: '',
            pageCMSData: [],
            image: "",
            previewImageOne: "",
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.home.homePageSectionFourCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageOne = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let formData =  new FormData();
                formData.append('title', this.pageCMSData.title ? this.pageCMSData.title: '');
                formData.append('image', this.image ? this.image : this.pageCMSData.image ? this.pageCMSData.image: '');
                formData.append('description', this.pageCMSData.description ? this.pageCMSData.description: '');
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homePageSectionFourCMS,
                    data: formData,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}