import { authHeader } from "../../../../auth";
import * as XLSX from 'xlsx';
export default {
    name: "Review List",
    components: {
    },
    data() {
        return {
            getURL: this.$serverURL + this.$api.product.productReorder + '?page=1&limit=1000',
            setURL: this.$serverURL + this.$api.product.productReorder,
            imagePath: this.$imageURL + 'product-review/',
            sheets: []
        }
    },
    async created() {
        document.title = "Golden Gal - Review";
        await this.getReorderList(this.getURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getExcelProducts: async function () {
            if(this.dataList.length > 0){
                this.dataList.map((el) => {
                    let color = el.colors.map(el => { return el.colorName});
                    let size = el.sizes.map(el => { return el.sizeName});
                    this.sheets.push({
                        'Name': el.name,
                        'Sku': el.sku,
                        "Price": el.price,
                        'Quantity': el.stock,
                        'Quantity Reorder': el.quantityReorder,
                        'Weight': el.weight,
                        'Size': size.toString(),
                        'Color': color.toString(),
                        'Brand Name': el.brandName,
                        'Category': el.parentCategoryName,
                        'Sub Category': el.subCategoryName,
                        'Status': el.status
                    });
                })
                const data = XLSX.utils.json_to_sheet(this.sheets)
                const wb = XLSX.utils.book_new()
                const date = new Date().toLocaleString();
                XLSX.utils.book_append_sheet(wb, data, 'data')
                XLSX.writeFile(wb,'product-reorder('+date+').xlsx')
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Reorder list is empty!"
                });
            }
            
                
        },
        getReorderList: async function(url){
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = response.data.products.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        deleteReorder: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    }
}