import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    data() {
        return {
            url: this.$serverURL + this.$api.productHintList.productHintListURL,
            sheets: []
        }
    },
    async created() {
        document.title = "Golden Gal - Product Hint List";
        await this.getDataList(this.url);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.id,
                    'Sender Name': el.senderName,
                    "Sender Email": el.senderEmail,
                    "Friend Email": el.friendEmail,
                    "Second Friend Email": el.secondFriendEmail,
                    "Ring Size": el.ringSize,
                    "Message": el.message,
                    "Product Id": el.productId,
                    'Date': this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'product_hint_report(' + date + ').xlsx');
        },
        deleteData: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.url + "/" + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.url);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}