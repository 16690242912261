import { authHeader } from "../../../../auth";
import * as XLSX from 'xlsx';
export default {
    name: "Brand",
    data() {
        return {
            setURL: this.$serverURL + this.$api.product.productBrandAPI,
            singleAPI: this.$serverURL + this.$api.product.singleProductBrandAPI,
            imagePath: this.$imageURL + 'brand-image/',
            singleBrand: {},
            brandName: "",
            sheets: []
        }
    },
    async created() {
        document.title = "Golden Gal - Brand";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addBrand: async function () {
           
            if (!this.brandName) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Brand Name!"
                });
            }
            else {
                let data = {
                    brandName: this.brandName
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "application/json",
                    }
                };
                await this.postDataToBackend(config);
                await this.getDataList(this.setURL);
                this.brandName = '';

            }
        },
        getExcelProducts: async function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'BrandName': el.brandName,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb,'brand-report('+date+').xlsx');    
        },
        editBrand: async function (data) {
            this.singleBrand = data;
        },
        updateBrand: async function () {
            if (!this.singleBrand.brandName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Brand Name!"
                });
            }
            else {
                let data = {
                    brandName: this.singleBrand.brandName
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleBrand.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "application/json",
                    }
                };
                await this.updateDataToBackend(config);
                this.brandName = '';
                await this.getDataList(this.setURL);
            }
        },
        deleteBrand: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.singleAPI + id,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Failed! Error!" + error
                            });
                        }
                    }
            })
            
        }
    }
}