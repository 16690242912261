import { authHeader } from "../../../../auth";
export default {

    data() {
        return {
            productList: [],
            siteUrl: this.$serverURL + this.$api.product.shopByOccassionAPI,
            sheets: [],
        }
    },
    async created() {
        document.title = "Golden Gal - Products";
        await this.getDataList(this.siteUrl);

        // this.data.map((el) => {
        //     el.isOccasion = false;
        //     el.count = false;
        // })

        this.dataList = this.dataList.map(x =>{
            return {...x, isOccasion: x.isOccasion>0,  }
        });

        this.dataList.map((el) => {
            el.flag = false;
            el.count = false;
        });

        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {


        save: async function () {
            var product = [];
            for (const key in Object.keys(this.dataList)) {
                product.push({
                    productId: this.dataList[key].id,
                    productName: this.dataList[key].name,
                    expireDate: this.dataList[key].expireDate,
                    isOccasion: this.dataList[key].isOccasion == 1 ? true : false
                });
            }
            let products = {
                product: product
            }
           console.log("value",products);
            let config = {
                method: 'POST',
                url: this.$serverURL + this.$api.product.shopByOccassion,
                data: products,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    await this.getDataList(this.siteUrl);
                    this.dataList = this.dataList.map(x =>{
                        return {...x, isOccasion: x.isOccasion>0,  }
                    });
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
    }
}