import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {Base64UploadAdapter} from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
export default {
    data() {
        return {
            editorData: '<p>Hello, world!</p>',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            name: '',
            featuredImage: "",
            shortDescription: '',
            longDescription: '',
            price: '',
            cost: '',
            discounted_price: '',
            sku: '',
            stock: 1,
            vendor: '',
            weight: '',
            rating: '',
            isFeatured: false,
            isDeactive: false,
            isMerch: false,
            isGiftGuide: false,
            isReadyToShip: false,
            parentCategory: "",
            subCategory: { id: "", name: "" },
            childCategory: { id: "", name: "" },
            subCategoryList: [],
            childCategoryList: [],
            vendorList: [],
            colorValue: [],
            colorsList: [],
            sizeValue: [],
            sizesList: [],
            tagValue: [],
            tagList: [],
            highlightValue: [],
            highlightList: [],
            materialValue: [],
            materialList: [],
            addedDetails: [{ id: "" }],
            selectedDetails: [{ title: "", description: "" }],
            // brand: {},
            // brandList: [],
            // discount: {
            //     id: '',
            //     name: ''
            // },
            //discountList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            productVideo: {
                video: ""
            },
            preview: {
                video: ""
            },
            disableButton: false,
            setURL: this.$serverURL + this.$api.product.productURL,

            productList: [],
            relatedProducts: []
        }

    },
    async mounted() {
        this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
        this.childCategoryList.push({ id: '', name: 'No Child-Category' });
        await this.getAllVarient();
        await this.getParentCategoriesWithSubcategory();
        await this.getAllParentCategories();
        await this.getVendorList();
        await this.getDataList(this.setURL);
        this.productList = this.dataList.map(el => {
            return { value: el, label: el.name }
        })
    },
    methods: {
        uploadFeaturedImage: function () {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addDetails() {
            this.addedDetails.push({ id: "" });
            this.selectedDetails.push({ title: "", description: "" });
        },
        uploadProductImages: function () {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        uploadProductVideo(videoKey, previewVideoKey, ref, event) {
            this.productVideo.video = '';
            this.preview.video = '';
            this.productVideo[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewVideoKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        removeUploadedProductImage: function (index) {
            this.previewProductImages.splice(index, 1);
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
        getAllVarient: async function () {
            let url = this.$serverURL + this.$api.product.getVariants;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.colorsList = response.data.productColor.map((el) => {
                        return { value: el.name, label: el.name }
                    });
                    this.sizesList = response.data.productSize.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    this.tagList = response.data.productTag.map((el) => {
                        return { value: el.name, label: el.name }
                    });
                    this.highlightList = response.data.productHighlight.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    this.materialList = response.data.productMaterial.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    // this.brandList = response.data.brandData;
                    // this.discountList = response.data.discountData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getSubCategoryList: async function (id) {
            this.subCategoryList = [];
            this.subCategory = "";
            this.childCategoryList = [];
            this.childCategory = "";
            this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
            let url = this.$serverURL + this.$api.productCategory.getSubCategoryByParentCategory + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.subCategoryList = response.data.data;
                    this.subCategoryList.push({ id: '', name: 'No Sub-Category' });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getChildCategoryList: async function (id) {
            this.childCategoryList = [];
            this.childCategory = "";
            this.childCategoryList.push({ id: '', name: 'No Child-Category' });
            let url = this.$serverURL + this.$api.productCategory.getChildCategoryBySubCategory + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                    this.childCategoryList.push({ id: '', name: 'No Child-Category' });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },

        getVendorList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.vendor.requestURL
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.vendorList = response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        // changePrice: function () {
        //     if (this.discount.name != "None") {
        //         if (this.discount.name.includes("%")) {
        //             let converted_discount = parseFloat(this.discount.name) / 100.0;
        //             this.discounted_price = this.price - this.price * converted_discount;
        //         }
        //         else {
        //             try {
        //                 let converted_discount = parseInt(this.discount.name)
        //                 this.discounted_price = this.price - converted_discount;
        //             }
        //             catch (error) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Failed! please try again.Error:" + error
        //                 });
        //             }
        //         }
        //     } else {
        //         this.discounted_price = null;
        //     }
        // },
        // addDiscount: async function () {

        //     if (!this.discountTitle) {

        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter discount title!"
        //         });
        //     }
        //     else {
        //         let data = {
        //             discountTitle: this.discountTitle,
        //         };
        //         let config = {
        //             method: "POST",
        //             url: this.$serverURL + this.$api.product.productDiscountAPI,
        //             data: data,
        //             headers: {
        //                 "Authorization": authHeader()
        //             }
        //         };
        //         await this.$axios(config).then(async (response) => {
        //             if (response.status == 201) {

        //                 this.discount = {
        //                     id: response.data.data.id,
        //                     name: response.data.data.discountTitle
        //                 };
        //                 this.discountTitle = '';
        //                 await this.getAllVarient();
        //                 await this.changePrice();
        //             }
        //             else {

        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Failed! please try again."
        //                 });
        //             }
        //         }).catch(error => {

        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Failed! please try again." + error
        //             });
        //         });

        //     }
        // },

        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            else if (!this.shortDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product short description!"
                });
                return false;
            }
            else if (!this.longDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product long description!"
                });
                return false;
            }
            else if (!this.parentCategory.id) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            } else if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (this.productImageList.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product image!"
                });
                return false;
            } else if (!this.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } else if (!this.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            }
            else {
                return true;
            }

        },
        addProduct: async function () {
            this.$swal.showLoading();
            if (this.checkValidation()) {

                if (this.quantityReorder == null) {
                    this.quantityReorder = 0;
                }

                if (this.quantityReorder == "") {
                    this.quantityReorder = 0;
                }

                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                formData.append("parentCategoryId", this.parentCategory.id);
                formData.append("parentCategoryName", this.parentCategory.name);
                formData.append("subCategoryId", this.subCategory.id ? this.subCategory.id : "");
                formData.append("subCategoryName", this.subCategory.name ? this.subCategory.name : "");
                formData.append("childCategoryId", this.childCategory.id ? this.childCategory.id : "");
                formData.append("childCategoryName", this.childCategory.name ? this.childCategory.name : "");
                formData.append("featuredImage", this.featuredImage);
                formData.append("vendorName", this.vendor.name ? this.vendor.name : "");
                formData.append("sku", this.sku);
                formData.append("price", this.price);
                formData.append("stock", this.stock);
                formData.append("rating", this.rating);
                formData.append('video', this.productVideo.video);
                // formData.append("quantityReorder", this.quantityReorder);
                // formData.append("discountedPrice", this.discounted_price ? this.discounted_price : 0);
                // formData.append("brandName", this.brand.name);
                // formData.append("discountId", this.discount?.id ? this.discount.id : '');
                // formData.append("discountTitle", (this.discount?.name && this.discount.name != 'None') ? this.discount.name : '');
                this.selectedDetails.map(el => {
                    formData.append("extraDetail", JSON.stringify(el));
                })
                this.relatedProducts.map(el => {
                    let dt = {
                        relatedProductID: el.id,
                        relatedProductName: el.name
                    }
                    formData.append("relatedProducts", JSON.stringify(dt));
                })
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                })
                this.colorValue.map((el) => {
                    formData.append("color[]", el);
                });
                this.sizeValue.map((el) => {
                    formData.append("size[]", el);
                });
                this.tagValue.map((el) => {
                    formData.append("tag[]", el);
                });
                this.highlightValue.map((el) => {
                    formData.append("highlight[]", el);
                });
                this.materialValue.map((el) => {
                    formData.append("material[]", el);
                });
                formData.append('cost', this.cost);
                formData.append("weight", this.weight);
                formData.append("isFeatured", this.isFeatured ? 1 : 0);

                formData.append("isMerch", this.isMerch ? 1 : 0);
                formData.append("isGiftGuide", this.isGiftGuide ? 1 : 0);
                formData.append("isReadyToShip", this.isReadyToShip ? 1 : 0);
                formData.append("isDeactive", this.isDeactive ? 1 : 0);

                let config = {
                    method: "POST",
                    data: formData,
                    url: this.setURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    if (error.response.data) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }

                });
            }
        }
    },
    // watch: {
    //     price: function () {
    //         if (this.discount.name) {
    //             this.changePrice();
    //         }
    //     }
    // }
}