<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Section Four CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Page Section Four CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Title:</label>
                    <input
                      type="text"
                      v-model="pageCMSData.title"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="Enter title"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Description:</label>
                    <ckeditor
                      :editor="editor"
                      v-model="pageCMSData.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="mb-3">
                    <label for="inputProductTitle" class="form-label">
                      Image:
                      <strong>
                        [Preferred Image Size: 1028X768, Max Upload Limit:
                        1MB]
                      </strong>
                    </label>
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewImageOne"
                      :src="previewImageOne"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="pageCMSData.image"
                      :src="
                                            pageCMSData.image
                                        "
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="../../../../assets/images/default.png"
                      height="70"
                      width="100"
                    />
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="backgroundImage"
                      accept="image/*"
                      @change="uploadFeaturedImage"
                    />
                  </div>
                  <button class="btn btn-success mb-3" @click="$router.push('/section-four-slider-cms')">Go to slider setting</button>
                  <br/>
                  <div class="d-grid">
                    <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card">
        <div class="card-body">
          <h5 class="card-title">Select Slider Item</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Select Type:</label> &nbsp;
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="option1"
                      />
                      <label class="form-check-label" for="inlineRadio1">Product</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="option2"
                      />
                      <label class="form-check-label" for="inlineRadio2">Gift Card</label>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Product List:</label>
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">GiftCard List:</label>
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script src="../js/home-page-section-four.js"></script>