import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';
export default {
    components:{
        LvColorpicker
    },
    data() {
        return {
            type: '',
            template: {
                logo:'',
                logoBG:'',
                sectionTwoImage:'',
                sectionTwoBG:''
            },
            colors: ["#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50", "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                },
            },
            showModal: false,
            image: "",
            sectionTwoImageRaw: "",
            sectionThreeImageRaw: "",
            sectionThreeSideImageRaw: "",
            sectionSixImageRaw: "",
            sectionEightImageRaw: "",
            previewImage: "",
            previewSectionTwoImage: "",
            previewSectionThreeImage: "",
            previewSectionThreeSideImage: "",
            previewSectionSixImage: "",
            previewSectionEightImage: "",
            emailType: "Order Placed",
            logoChangeArea: false,
            sectionTwoChangeArea: false,
            sectionThreeChangeArea: false,
            sectionFourChangeArea: false,
            sectionFiveChangeArea: false,
            sectionSixChangeArea: false,
            sectionSevenChangeArea: false,
            sectionEightChangeArea: false,
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Email Template";
        await this.fetchEditData();
    },
    methods: {
        publishUpdateTemplate:async function(){
            let emailHead=`<!DOCTYPE html>

                    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                    <head>
                    <title></title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                    <style>
                            * {
                                box-sizing: border-box;
                            }
                    
                            body {
                                margin: 0;
                                padding: 0;
                            }
                    
                            a[x-apple-data-detectors] {
                                color: inherit !important;
                                text-decoration: inherit !important;
                            }
                    
                            #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                            }
                    
                            p {
                                line-height: inherit
                            }
                    
                            .desktop_hide,
                            .desktop_hide table {
                                mso-hide: all;
                                display: none;
                                max-height: 0px;
                                overflow: hidden;
                            }
                    
                            @media (max-width:720px) {
                                .desktop_hide table.icons-inner {
                                    display: inline-block !important;
                                }
                    
                                .icons-inner {
                                    text-align: center;
                                }
                    
                                .icons-inner td {
                                    margin: 0 auto;
                                }
                    
                                .image_block img.big,
                                .row-content {
                                    width: 100% !important;
                                }
                    
                                .mobile_hide {
                                    display: none;
                                }
                    
                                .stack .column {
                                    width: 100%;
                                    display: block;
                                }
                    
                                .mobile_hide {
                                    min-height: 0;
                                    max-height: 0;
                                    max-width: 0;
                                    overflow: hidden;
                                    font-size: 0px;
                                }
                    
                                .desktop_hide,
                                .desktop_hide table {
                                    display: table !important;
                                    max-height: none !important;
                                }
                            }
                        </style>
                    </head>
                    <body style="margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">`;
            let emailFotter=`</body></html>`;
            let emailBody=document.querySelector("#emailBody").innerHTML; 
            console.log('email Body ', emailBody);

            try {
                let formData =  new FormData();
                formData.append('rawFormatedCode', emailHead+emailBody+emailFotter);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.OrderPlacedEmail,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                console.log(config);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        changeLogo: function(){
            console.log('changeLogo');
            this.logoChangeArea = true;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = false;
        },
        changeSectionTwo: function(){
            console.log('changeSectionTwo');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = true;
            this.sectionFourChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = false;
        },
        changeSectionThree: function(){
            console.log('changeSectionThree');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionThreeChangeArea = true;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = false;
        },
        changeSectionFour: function(){
            console.log('changeSectionFour');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = true;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = false;
        },
        changeSectionFive: function(){
            console.log('changeSectionFive');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionFiveChangeArea = true;
            this.sectionSixChangeArea = false;
        },
        changeSectionSix: function(){
            console.log('changeSectionFive');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = true;
            this.sectionSevenChangeArea = true;
        },
        changeSectionSeven: function(){
            console.log('changeSectionSeven');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionFiveChangeArea = false;
            this.sectionSixChangeArea = false;
            this.sectionSevenChangeArea = true;
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionTwoImage: function (event) {
            this.sectionTwoImageRaw = event.target.files[0];
            let input = this.$refs.sectionTwoImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionTwoImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionThreeImage: function (event) {
            this.sectionThreeImageRaw = event.target.files[0];
            let input = this.$refs.sectionThreeImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionThreeImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionThreeSideImage: function (event) {
            this.sectionThreeSideImageRaw = event.target.files[0];
            let input = this.$refs.sectionThreeSideImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionThreeSideImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionSixImage: function (event) {
            this.sectionSixImageRaw = event.target.files[0];
            let input = this.$refs.sectionSixImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionSixImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionEightImage: function (event) {
            this.sectionEightImageRaw = event.target.files[0];
            let input = this.$refs.sectionEightImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionEightImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        fetchEditData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.OrderPlacedEmail,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log(response.data.data.template);
                    if(response.data.data.template)
                    {
                        let template = JSON.parse(response.data.data.template);
                        this.template = template;
                        if(this.template.logo)
                        {
                            this.previewImage = this.template.logo;
                        }
                        this.previewSectionTwoImage = this.template.sectionTwoImage;
                        this.previewSectionThreeImage = this.template.sectionThreeImage;
                        this.previewSectionSixImage = this.template.sectionSixImage;
                        this.previewSectionEightImage = this.template.sectionEightImage;
                        console.log('this.template =', this.template);
                    }
                    
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            try {
                if (!this.type) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please Select Type!"
                    });
                    return;
                }
                else if (!this.template) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter content!"
                    });
                    return;
                }
                let data = {
                    type: this.type,
                    template: this.template,
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.$router.push("/email-template");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async updateCms() {
            try {
                let formData =  new FormData();
                formData.append('logo', this.image ? this.image : this.template.logo);
                formData.append('logoBG', this.template.logoBG ? this.template.logoBG : '');
                formData.append('sectionTwoImage', this.sectionTwoImageRaw ? this.sectionTwoImageRaw : this.template.sectionTwoImage);
                formData.append('sectionTwoBG', this.template.sectionTwoBG ? this.template.sectionTwoBG : '');
                formData.append('sectionTwoFontColor', this.template.sectionTwoFontColor ? this.template.sectionTwoFontColor : '');
                formData.append('sectionTwoHeadingContent', this.template.sectionTwoHeadingContent ? this.template.sectionTwoHeadingContent : '');
                formData.append('sectionTwoShortDetail', this.template.sectionTwoShortDetail ? this.template.sectionTwoShortDetail : '');
                formData.append('sectionThreeImage', this.sectionThreeImageRaw ? this.sectionThreeImageRaw : this.template.sectionThreeImage);
                formData.append('sectionFourContent', this.template.sectionFourContent ? this.template.sectionFourContent : '');

                formData.append('sectionFourFirstBlockHeading', this.template.sectionFourFirstBlockHeading ? this.template.sectionFourFirstBlockHeading : '');
                formData.append('sectionFourFirstBlockFirstLine', this.template.sectionFourFirstBlockFirstLine ? this.template.sectionFourFirstBlockFirstLine : '');
                formData.append('sectionFourFirstBlockSecondLine', this.template.sectionFourFirstBlockSecondLine ? this.template.sectionFourFirstBlockSecondLine : '');
                formData.append('sectionFourFirstBlockThirdLine', this.template.sectionFourFirstBlockThirdLine ? this.template.sectionFourFirstBlockThirdLine : '');
                formData.append('sectionFourSecondBlockHeading', this.template.sectionFourSecondBlockHeading ? this.template.sectionFourSecondBlockHeading : '');
                formData.append('sectionFourSecondBlockFirstLine', this.template.sectionFourSecondBlockFirstLine ? this.template.sectionFourSecondBlockFirstLine : '');
                formData.append('sectionFourSecondBlockSecondLine', this.template.sectionFourSecondBlockSecondLine ? this.template.sectionFourSecondBlockSecondLine : '');
                formData.append('sectionFourSecondBlockThirdLine', this.template.sectionFourSecondBlockThirdLine ? this.template.sectionFourSecondBlockThirdLine : '');
                formData.append('sectionFourThirdBlockFirstHeading', this.template.sectionFourThirdBlockFirstHeading ? this.template.sectionFourThirdBlockFirstHeading : '');
                formData.append('sectionFourThirdBlockFirstHeadingContent', this.template.sectionFourThirdBlockFirstHeadingContent ? this.template.sectionFourThirdBlockFirstHeadingContent : '');
                formData.append('sectionFourThirdBlockSecondHeading', this.template.sectionFourThirdBlockSecondHeading ? this.template.sectionFourThirdBlockSecondHeading : '');
                formData.append('sectionFourThirdBlockSecondHeadingContent', this.template.sectionFourThirdBlockSecondHeadingContent ? this.template.sectionFourThirdBlockSecondHeadingContent : '');

                formData.append('sectionFiveBG', this.template.sectionFiveBG ? this.template.sectionFiveBG : '');
                formData.append('sectionFiveFontColor', this.template.sectionFiveFontColor ? this.template.sectionFiveFontColor : '');
                formData.append('sectionFiveButtonText', this.template.sectionFiveButtonText ? this.template.sectionFiveButtonText : '');

                formData.append('sectionSixImage', this.sectionSixImageRaw ? this.sectionSixImageRaw : this.template.sectionSixImage);

                formData.append('sectionSevenTitleOne', this.sectionSevenTitleOne ? this.sectionSevenTitleOne : this.template.sectionSevenTitleOne);
                formData.append('sectionSevenTitleTwo', this.sectionSevenTitleTwo ? this.sectionSevenTitleTwo : this.template.sectionSevenTitleTwo);
                formData.append('sectionSevenItemOneTitle', this.sectionSevenItemOneTitle ? this.sectionSevenItemOneTitle : this.template.sectionSevenItemOneTitle);
                formData.append('sectionSevenItemOneValue', this.sectionSevenItemOneValue ? this.sectionSevenItemOneValue : this.template.sectionSevenItemOneValue);
                formData.append('sectionSevenItemTwoTitle', this.sectionSevenItemTwoTitle ? this.sectionSevenItemTwoTitle : this.template.sectionSevenItemTwoTitle);
                formData.append('sectionSevenItemTwoValue', this.sectionSevenItemTwoValue ? this.sectionSevenItemTwoValue : this.template.sectionSevenItemTwoValue);
                formData.append('sectionSevenItemThreeTitle', this.sectionSevenItemThreeTitle ? this.sectionSevenItemThreeTitle : this.template.sectionSevenItemThreeTitle);
                formData.append('sectionSevenItemThreeValue', this.sectionSevenItemThreeValue ? this.sectionSevenItemThreeValue : this.template.sectionSevenItemThreeValue);
                formData.append('sectionSevenItemFourTitle', this.sectionSevenItemFourTitle ? this.sectionSevenItemFourTitle : this.template.sectionSevenItemFourTitle);
                formData.append('sectionSevenItemFourValue', this.sectionSevenItemFourValue ? this.sectionSevenItemFourValue : this.template.sectionSevenItemFourValue);
                formData.append('sectionSevenItemFiveTitle', this.sectionSevenItemFiveTitle ? this.sectionSevenItemFiveTitle : this.template.sectionSevenItemFiveTitle);
                formData.append('sectionSevenItemFiveValue', this.sectionSevenItemFiveValue ? this.sectionSevenItemFiveValue : this.template.sectionSevenItemFiveValue);
                formData.append('sectionSevenBG', this.sectionSevenBG ? this.sectionSevenBG : this.template.sectionSevenBG);
                formData.append('sectionSevenFontColor', this.sectionSevenFontColor ? this.sectionSevenFontColor : this.template.sectionSevenFontColor);

                formData.append('sectionEightContent', this.sectionEightContent ? this.sectionEightContent : this.template.sectionEightContent);
                formData.append('sectionEightLinkOneTitle', this.sectionEightLinkOneTitle ? this.sectionEightLinkOneTitle : this.template.sectionEightLinkOneTitle);
                formData.append('sectionEightLinkOneLink', this.sectionEightLinkOneLink ? this.sectionEightLinkOneLink : this.template.sectionEightLinkOneLink);
                formData.append('sectionEightLinkTwoTitle', this.sectionEightLinkTwoTitle ? this.sectionEightLinkTwoTitle : this.template.sectionEightLinkTwoTitle);
                formData.append('sectionEightLinkTwoLink', this.sectionEightLinkTwoLink ? this.sectionEightLinkTwoLink : this.template.sectionEightLinkTwoLink);
                formData.append('sectionEightLinkThreeTitle', this.sectionEightLinkThreeTitle ? this.sectionEightLinkThreeTitle : this.template.sectionEightLinkThreeTitle);
                formData.append('sectionEightLinkThreeLink', this.sectionEightLinkThreeLink ? this.sectionEightLinkThreeLink : this.template.sectionEightLinkThreeLink);

                formData.append('sectionEightImage', this.sectionEightImageRaw ? this.sectionEightImageRaw : this.template.sectionEightImage);

                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.OrderPlacedEmail,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                await this.fetchEditData();
                console.log(config);
                //this.$refs.backgroundImage.value = null;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}