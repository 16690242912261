<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Coupon List</li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary m-1"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Coupon
            </button>
            <!-- <button
                        type="button"
                        class="btn btn-success m-1"
                        data-bs-toggle="modal"
                        data-bs-target="#addFreeShippingModal">
                        <i class="bx bxs-plus-square"></i>Add Free Shipping Coupon
            </button>-->
          </div>
          <!-- Add Modal -->

          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addCoupon">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      <i class="fa-solid fa-square-plus"></i> &nbsp;Add Coupon
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      id="addCouponModal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="couponName"
                      placeholder="Coupon name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Code:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="couponCode"
                      placeholder="Coupon code"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Expire Date:</label>
                    <input
                      class="form-control mb-1"
                      type="date"
                      v-model="expireDate"
                      placeholder="About Content Title"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Type:</label>
                    <select v-model="couponType" class="form-select mb-3">
                      <option selected style="display: none" value>Select Coupon Type</option>
                      <option
                        v-for="(data, index) in filteredcouponTypes"
                        :value="data"
                        :key="index"
                      >{{ data }}</option>
                    </select>
                  </div>
                  <div class="modal-body" v-if="couponType">
                    <label class="form-label">
                      {{
                      couponType == "free shipping" ? "Minimum Order Amount" : couponType == "welcome" ? "Discount percentage" : "Discount Amount"
                      }}:
                    </label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="discount"
                      :placeholder="couponType == 'free shipping' ? 'Minimum Order Amount' : couponType == 'welcome' ? 'Discount percentage' : 'Discount Amount'"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      <i class="fa-solid fa-xmark"></i>Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      <i class="fa-solid fa-plus"></i>Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add Modal -->

          <!-- free shipping modal -->

          <div
            class="modal fade"
            id="addFreeShippingModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addFSCoupon">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      <i class="fa-solid fa-square-plus"></i>&nbsp;Add Free Shipping
                      Coupon
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      id="addFSCouponModal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Name</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="couponName"
                      placeholder="Coupon name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Code:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="couponCode"
                      placeholder="Coupon code"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Expire Date:</label>
                    <input
                      class="form-control mb-1"
                      type="date"
                      v-model="expireDate"
                      placeholder="About Content Title"
                      aria-label="default input example"
                    />
                  </div>
                  <!-- <div class="modal-body">
                    <label class="form-label">Coupon Type:</label>
                    <select v-model="couponType" class="form-select mb-3">
                      <option selected style="display:none;" value="">Select coupon type</option>
                      <option
                        v-for="(data, index) in couponTypes"
                        :value="data"
                        :key="index"
                        :hidden="data != 'free shipping'"
                        :selected="data=='free shipping'"
                      >
                        {{ data }}
                      </option>
                    </select>
                  </div>-->
                  <div class="modal-body">
                    <label class="form-label">Order Amount Exceed:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="discount"
                      placeholder="Exceed order amount"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      <i class="fa-solid fa-xmark"></i>Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      <i class="fa-solid fa-plus"></i>Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- free shipping modal -->

          <!-- Edit Role Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateCoupon">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update Coupon</h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCoupon.couponName"
                      placeholder="Coupon name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Code:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCoupon.couponCode"
                      placeholder="Coupon code"
                      aria-label="default input example"
                    />
                  </div>

                  <div class="modal-body">
                    <label class="form-label">Current Expire Date:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="currentExpireDate"
                      placeholder="Expire Date"
                      aria-label="default input example"
                      disabled
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Expire Date:</label>
                    <input
                      class="form-control mb-1"
                      type="date"
                      v-model="singleCoupon.expireDate"
                      placeholder="Expire Date"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Coupon Type:</label>
                    <select
                      v-model="singleCoupon.couponType"
                      class="form-select mb-3"
                      :disabled="
                        singleCoupon.couponType == 'free shipping' ||
                        singleCoupon.couponType == 'welcome'
                      "
                    >
                      <option
                        v-for="(data, index) in couponTypes"
                        :value="data"
                        :key="index"
                        :disabled="
                          (data == 'welcome' && isWelcomeCouponExist) ||
                          (data == 'free shipping' && isFreeShippingExist)
                        "
                      >{{ data }}</option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">
                      {{
                      singleCoupon.couponType == "free shipping"
                      ? "Minimum Order Amount" : singleCoupon.couponType == "welcome" ? "Discount percentage"
                      : "Discount Amount"
                      }}:
                    </label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCoupon.discount"
                      :placeholder="singleCoupon.couponType == 'free shipping' ? 'Minimum Order Amount' : singleCoupon.couponType == 'welcome' ? 'Discount percentage' : 'Discount Amount'"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Coupon List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Coupon Name</th>
                  <th>Coupon Code</th>
                  <th>Coupon Type</th>
                  <th>Discount</th>
                  <th>Exceed Order Amount</th>
                  <th>Expire Date</th>
                  <th>Coupon In Use</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.couponName }}</td>
                  <td>{{ data.couponCode }}</td>
                  <td>{{ data.couponType }}</td>
                  <td>{{ data.couponType == "free shipping" ? 0 : data.discount }}</td>
                  <td>{{ data.couponType == "free shipping" ? data.discount : 0 }}</td>
                  <td>{{ $filters.formatCouponDate(data.expireDate) }}</td>
                  <td>{{ data.cuponUsedTotal ? data.cuponUsedTotal : 0 }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editCoupon(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteCoupon(data.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Coupon Name</th>
                  <th>Coupon Code</th>
                  <th>Coupon Type</th>
                  <th>Discount</th>
                  <th>Exceed Order Amount</th>
                  <th>Expire Date</th>
                  <th>Coupon In Use</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/coupon.js"></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>
