<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Style Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Section Five CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Section Five CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Section Title:</label>
                                        <input type="text" v-model="pageCMSData.sectionTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="pageCMSData.buttonText" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image One Title:</label>
                                        <input type="text" v-model="pageCMSData.imageOneTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image One Link:</label>
                                        <input type="text" v-model="pageCMSData.imageOneLink" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image One: <strong>[Preferred Image Size: 516X562, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageOne($event)" ref="imageOne" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageOne" :src="previewImageOne" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.imageOne" :src="pageCMSData.imageOne" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Two Title:</label>
                                        <input type="text" v-model="pageCMSData.imageTwoTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Two Link:</label>
                                        <input type="text" v-model="pageCMSData.imageTwoLink" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Two: <strong>[Preferred Image Size: 516X562, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageTwo($event)" ref="imageTwo" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageTwo" :src="previewImageTwo" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.imageTwo" :src="pageCMSData.imageTwo" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Three Title:</label>
                                        <input type="text" v-model="pageCMSData.imageThreeTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Three Link:</label>
                                        <input type="text" v-model="pageCMSData.imageThreeLink" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image Three: <strong>[Preferred Image Size: 516X562, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageThree($event)" ref="imageThree" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageThree" :src="previewImageThree" height="100" width="150"/>
                                            <img v-else-if="pageCMSData.imageThree" :src="pageCMSData.imageThree" height="100" width="150"/>
                                            <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/style-section-five.js"></script>