<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Shop Guide CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Shop Guide CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Shop Guide CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Image: <strong>[Preferred Image Size: 470X250, Max Upload Limit: 1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImage" :src="previewImage" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else-if="pageCMSData.image" :src="pageCMSData.image" height="70" width="100"/>
                                        <img style="position: relative; left: 11px" v-else src="../../../../assets/images/default.png" height="70" width="100"/>
                                        <input class="form-control mt-3" type="file" ref="image" accept="image/*" @change="uploadImage"/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Description:</label>
                                        <ckeditor :editor="editor" v-model="pageCMSData.description" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="pageCMSData.buttonText" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Link:</label>
                                        <input type="text" v-model="pageCMSData.buttonLink" class="form-control">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <LinkShortcut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/shop-guide-cms.js"></script>