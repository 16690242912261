import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            previewSectionTwoImage: null,
            sectionTwoImageFile: null,
            previewSectionFourImage: null,
            sectionFourImageFile: null,
            previewSectionSixImage: null,
            sectionSixImageFile: null,
            previewSectionSevenImage: null,
            sectionSevenImageFile: null,
            previewSectionOneImage: null,
            sectionOneImageFile: null
        }
    },
    async mounted() {
        document.title = "Golden Gal - Learn Page CMS"
        this.setURL = this.$serverURL + this.$api.cms.learn.pageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadSectionOneImage: function (event) {
            this.sectionOneImageFile = event.target.files[0];
            let input = this.$refs.sectionOneImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewSectionOneImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadSectionTwoImage: function (event) {
            this.sectionTwoImageFile = event.target.files[0];
            let input = this.$refs.sectionTwoImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewSectionTwoImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadSectionFourImage: function (event) {
            this.sectionFourImageFile = event.target.files[0];
            let input = this.$refs.sectionFourImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewSectionFourImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadSectionSixImage: function (event) {
            this.sectionSixImageFile = event.target.files[0];
            let input = this.$refs.sectionSixImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewSectionSixImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadSectionSevenImage: function (event) {
            this.sectionSevenImageFile = event.target.files[0];
            let input = this.$refs.sectionSevenImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewSectionSevenImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                let token = localStorage.getItem('token');
                // const formData = new FormData();
                let data = {
                    pageTitle: this.pageCMSData.pageTitle ? this.pageCMSData.pageTitle : '',
                    pageSubTitle: this.pageCMSData.pageSubTitle ? this.pageCMSData.pageSubTitle : '',
                    pageDescription: this.pageCMSData.pageDescription ? this.pageCMSData.pageDescription : '',
                    sectionOneTitle: this.pageCMSData.sectionOneTitle ? this.pageCMSData.sectionOneTitle : '',
                    sectionOneDescription: this.pageCMSData.sectionOneDescription ? this.pageCMSData.sectionOneDescription : ''
                }
                // formData.append("pageTitle", this.pageCMSData.pageTitle ? this.pageCMSData.pageTitle : '');
                // formData.append("pageSubTitle", this.pageCMSData.pageSubTitle ? this.pageCMSData.pageSubTitle : '');
                // formData.append("pageDescription", this.pageCMSData.pageDescription ? this.pageCMSData.pageDescription : '');
                // formData.append("sectionOneDescription", this.pageCMSData.sectionOneDescription ? this.pageCMSData.sectionOneDescription : '');
                // formData.append("sectionOneTitle", this.pageCMSData.sectionOneTitle ? this.pageCMSData.sectionOneTitle : '');
                // formData.append("sectionOneImage", this.sectionOneImageFile ? this.sectionOneImageFile : this.pageCMSData.sectionOneImage ? this.pageCMSData.sectionOneImage : '');
                // formData.append("sectionTwoTitle", this.pageCMSData.sectionTwoTitle ? this.pageCMSData.sectionTwoTitle : '');
                // formData.append("sectionTwoDescription", this.pageCMSData.sectionTwoDescription ? this.pageCMSData.sectionTwoDescription : '');
                // formData.append("sectionTwoImage", this.sectionTwoImageFile ? this.sectionTwoImageFile : this.pageCMSData.sectionTwoImage ? this.pageCMSData.sectionTwoImage : '');
                // formData.append("sectionThreeTitle", this.pageCMSData.sectionThreeTitle ? this.pageCMSData.sectionThreeTitle : '');
                // formData.append("sectionThreeDescription", this.pageCMSData.sectionThreeDescription ? this.pageCMSData.sectionThreeDescription : '');
                // formData.append("sectionFourImage", this.sectionFourImageFile ? this.sectionFourImageFile : this.pageCMSData.sectionFourImage ? this.pageCMSData.sectionFourImage : '');
                // formData.append("sectionFourTitle", this.pageCMSData.sectionFourTitle ? this.pageCMSData.sectionFourTitle : '');
                // formData.append("sectionFourDescription", this.pageCMSData.sectionFourDescription ? this.pageCMSData.sectionFourDescription : '');
                // formData.append("sectionFiveTitle", this.pageCMSData.sectionFiveTitle ? this.pageCMSData.sectionFiveTitle : '');
                // formData.append("sectionFiveDescription", this.pageCMSData.sectionFiveDescription ? this.pageCMSData.sectionFiveDescription : '');
                // formData.append("sectionSixTitle", this.pageCMSData.sectionSixTitle ? this.pageCMSData.sectionSixTitle : '');
                // formData.append("sectionSixDescription", this.pageCMSData.sectionSixDescription ? this.pageCMSData.sectionSixDescription : '');
                // formData.append("sectionSixImage", this.sectionSixImageFile ? this.sectionSixImageFile : this.pageCMSData.sectionSixImage ? this.pageCMSData.sectionSixImage : '');
                // formData.append("sectionSevenTitle", this.pageCMSData.sectionSevenTitle ? this.pageCMSData.sectionSevenTitle : '');
                // formData.append("sectionSevenDescription", this.pageCMSData.sectionSevenDescription ? this.pageCMSData.sectionSevenDescription : '');
                // formData.append("sectionSevenImage", this.sectionSevenImageFile ? this.sectionSevenImageFile : this.pageCMSData.sectionSevenImage ? this.pageCMSData.sectionSevenImage : '');
                // formData.append("sectionEightTitle", this.pageCMSData.sectionEightTitle ? this.pageCMSData.sectionEightTitle : '');
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.learn.pageCMS,
                    data: data,
                    headers: {
                        "Authorization": "Bearer " + token,
                        // "Content-Type": "multipart/form-data"
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.sectionOneImage.value = null;
                this.$refs.sectionTwoImage.value = null;
                this.$refs.sectionFourImage.value = null;
                this.$refs.sectionSixImage.value = null;
                this.$refs.sectionSevenImage.value = null;
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}