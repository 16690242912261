<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Update Email Template</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Update Email Template</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Update Email Template</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Template Type:</label>
                    <select v-model="type" disabled class="form-select">
                      <option style="display:none" value>Select Type</option>
                      <option value="Account Create Admin">Account Create Admin</option>
                      <option value="Order Shipped">Order Shipped</option>
                      <option value="Password Reset ">Password Reset</option>
                      <option value="Create Customer ">Create Customer</option>
                      <option value="Create Customer By Admin ">Create Customer By Admin</option>
                    </select>
                  </div>
                  <div class="mb-3" style="text-align:center;">

                    <ThankYouEmail />
                    
                  </div>
                  <div class="d-grid">
                    <button v-on:click="save" type="button" class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/edit-email-template.js"></script>
<style scoped>
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }

  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
  }

  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
  }

  p {
    line-height: inherit
  }

  .desktop_hide,
  .desktop_hide table {
    mso-hide: all;
    display: none;
    max-height: 0px;
    overflow: hidden;
  }

  @media (max-width:720px) {
    .desktop_hide table.icons-inner {
      display: inline-block !important;
    }

    .icons-inner {
      text-align: center;
    }

    .icons-inner td {
      margin: 0 auto;
    }

    .image_block img.big,
    .row-content {
      width: 100% !important;
    }

    .mobile_hide {
      display: none;
    }

    .stack .column {
      width: 100%;
      display: block;
    }

    .mobile_hide {
      min-height: 0;
      max-height: 0;
      max-width: 0;
      overflow: hidden;
      font-size: 0px;
    }

    .desktop_hide,
    .desktop_hide table {
      display: table !important;
      max-height: none !important;
    }
  }
</style>