import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ThankYouEmail from '../template/include/ThankYouEmail.vue'
export default {
    components:{
        ThankYouEmail
    },
    data() {
        return {
            type: '',
            template: '',
            id: this.$route.params.id,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                },
            },
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Email Template";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.type = response.data.data.type;
                    this.template = response.data.data.template;
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            try {
                if (!this.type) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please Select Type!"
                    });
                    return;
                }
                else if (!this.template) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter content!"
                    });
                    return;
                }
                let data = {
                    type: this.type,
                    template: this.template,
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.$router.push("/email-template");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}