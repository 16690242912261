<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="pageCMSData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Title:</label>
                                                <input type="text" v-model="pageCMSData.shopPagetitle" class="form-control" placeholder="Shop page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.shopPageMetaKeyword" class="form-control" placeholder="Shop page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.shopPageMetaDescription" class="form-control" placeholder="Shop page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Guide Page Title:</label>
                                                <input type="text" v-model="pageCMSData.giftGuidePageTitle" class="form-control" placeholder="Gift guide page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Guide Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.giftGuidePageMetaKeyword" class="form-control" placeholder="Gift guide page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Guide Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.giftGuidePageMetaDescription" class="form-control" placeholder="Gift guide page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Style Page Title:</label>
                                                <input type="text" v-model="pageCMSData.stylePageTitle" class="form-control" placeholder="Style page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Style Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.stylePageMetaKeyword" class="form-control" placeholder="Style page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Style Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.stylePageMetaDescription" class="form-control" placeholder="Style page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Merch Page Title:</label>
                                                <input type="text" v-model="pageCMSData.merchPageTitle" class="form-control" placeholder="Merch page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Merch Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.merchPageMetaKeyword" class="form-control" placeholder="Merch page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Merch Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.merchPageMetaDescription" class="form-control" placeholder="Merch page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Learn Page Title:</label>
                                                <input type="text" v-model="pageCMSData.learnPageTitle" class="form-control" placeholder="Learn page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Learn Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.learnPageMetaKeyword" class="form-control" placeholder="Learn page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Learn Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.learnPageMetaDescription" class="form-control" placeholder="Learn page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Gallery Page Title:</label>
                                                <input type="text" v-model="pageCMSData.galleryPageTitle" class="form-control" placeholder="Gallery page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gallery Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.galleryPageMetaKeyword" class="form-control" placeholder="Gallery page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gallery Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.galleryPageMetaDescription" class="form-control" placeholder="Gallery page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input type="text" v-model="pageCMSData.aboutPageTitle" class="form-control" placeholder="About page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaKeyword" class="form-control" placeholder="About page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaDescription" class="form-control" placeholder="About page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Collection Page Title:</label>
                                                <input type="text" v-model="pageCMSData.collectionPageTitle" class="form-control" placeholder="Collection page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Collection Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.collectionPageMetaKeyword" class="form-control" placeholder="Collection page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Collection Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.collectionPageMetaDescription" class="form-control" placeholder="Collection page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Return / Exchange Page Title:</label>
                                                <input type="text" v-model="pageCMSData.returnPageTitle" class="form-control" placeholder="Return page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Return / Exchange Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.returnPageMetaKeyword" class="form-control" placeholder="Return page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Return / Exchange Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.returnPageMetaDescription" class="form-control" placeholder="Return page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.termsPageTitle" class="form-control" placeholder="Terms page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaKeyword" class="form-control" placeholder="Terms page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaDescription" class="form-control" placeholder="Terms page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Card Page Title:</label>
                                                <input type="text" v-model="pageCMSData.giftCardPageTitle" class="form-control" placeholder="Gift card page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Card Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.giftCardPageMetaKeyword" class="form-control" placeholder="Gift card page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gift Card Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.giftCardPageMetaDescription" class="form-control" placeholder="Gift card page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Promotion Page Title:</label>
                                                <input type="text" v-model="pageCMSData.promotionPageTitle" class="form-control" placeholder="Promotion page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Promotion Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.promotionPageMetaKeyword" class="form-control" placeholder="Promotion page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Promotion Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.promotionPageMetaDescription" class="form-control" placeholder="Promotion page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Title:</label>
                                                <input type="text" v-model="pageCMSData.contactPageTitle" class="form-control" placeholder="Contact page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaKeyword" class="form-control" placeholder="Contact page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaDescription" class="form-control" placeholder="Contact page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Title:</label>
                                                <input type="text" v-model="pageCMSData.cartPageTitle" class="form-control" placeholder="Cart page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.cartPageMetaKeyword" class="form-control" placeholder="Cart page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.cartPageMetaDescription" class="form-control" placeholder="Cart page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Title:</label>
                                                <input type="text" v-model="pageCMSData.wishlistPageTitle" class="form-control" placeholder="Wishlist page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.wishlistPageMetaKeyword" class="form-control" placeholder="Wishlist page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.wishlistPageMetaDescription" class="form-control" placeholder="Wishlist page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Title:</label>
                                                <input type="text" v-model="pageCMSData.checkoutPageTitle" class="form-control" placeholder="Checkout page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.checkoutPageMetaKeyword" class="form-control" placeholder="Checkout page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.checkoutPageMetaDescription" class="form-control" placeholder="Checkout page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Title:</label>
                                                <input type="text" v-model="pageCMSData.loginPageTitle" class="form-control" placeholder="Login page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.loginPageMetaKeyword" class="form-control" placeholder="Login page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.loginPageMetaDescription" class="form-control" placeholder="Login page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Registration Page Title:</label>
                                                <input type="text" v-model="pageCMSData.registrationPageTitle" class="form-control" placeholder="Registration page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Registration Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.registrationPageMetaKeyword" class="form-control" placeholder="Registration page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Registration Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.registrationPageMetaDescription" class="form-control" placeholder="Registration page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Title:</label>
                                                <input type="text" v-model="pageCMSData.productDetailsPageTitle" class="form-control" placeholder="Product details page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.productDetailsPageMetaKeyword" class="form-control" placeholder="Product details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.productDetailsPageMetaDescription" class="form-control" placeholder="Product details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/siteTitleCMS.js"></script>