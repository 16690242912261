<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Section Four Slider</li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Slider
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addSlider" enctype="multipart/form-data">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Slider</h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">
                      Select Slider Type:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="sliderType"
                        value="product"
                      />
                      <label class="form-check-label" for="inlineRadio1">Product</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="sliderType"
                        value="giftCard"
                      />
                      <label class="form-check-label" for="inlineRadio2">GiftCard</label>
                    </div>
                  </div>
                  <div class="modal-body" v-if="sliderType=='product'">
                    <label class="form-label">
                      Product:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <select
                      v-model="sliderModel"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display:none" selected value>Select Product</option>
                      <option
                        v-for="(data, index) in productList"
                        :key="index"
                        :value="{type:'product', productOrGiftCardId: data.id}"
                      >{{data.name}}</option>
                    </select>
                  </div>
                  <div class="modal-body" v-if="sliderType=='giftCard'">
                    <label class="form-label">
                      Gift Card:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <select
                      v-model="sliderModel"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display:none" selected value>Select Gift Card</option>
                      <option
                        v-for="(data, index) in giftCardList"
                        :key="index"
                        :value="{type:'giftCard', productOrGiftCardId: data.id}"
                      >{{data.name}}</option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Section Four Slider List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in sliderList" :key="index">
                  <td>{{ index + 1 }}</td>

                  <td>{{ data.detail.name }}</td>

                  <td>{{ data.type }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteSlider(data.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/section-four-slider.js'></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>