import { authHeader } from "../../../auth";

export default {
    name: "EditHomeSlider",
    data() {
        return {
            buttonText: '',
            buttonLink: '',
            imageFile: '',
            image: '',
            editID: '',
            previewImage: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Home Slider";
        if(this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.homeSliderURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id,
                        this.buttonText = this.dataList.buttonText,
                        this.buttonLink = this.dataList.buttonLink,
                        this.image = this.dataList.image
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function() {
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                });
                return false;
            }
            if(!this.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                try {
                    let formData =  new FormData();
                    formData.append('buttonText', this.buttonText);
                    formData.append('image', this.imageFile ? this.imageFile : this.image);
                    formData.append('buttonLink', this.buttonLink);
                    let config = {
                        method: 'PATCH',
                        url: this.$serverURL + this.$api.home.homeSliderURL + '/' + this.editID,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.updateDataToBackend(config);
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$router.push("/home-slider");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}