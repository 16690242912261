import { authHeader } from "../../../auth";

export default {
    name: "Coupon",
    data() {
        return {
            setURL: this.$serverURL + this.$api.coupon.couponAPI,
            singleAPI: this.$serverURL + this.$api.coupon.singleCouponAPI,
            singleCoupon: {},
            couponName: '',
            couponCode: '',
            expireDate: '',
            discount: '',
            couponTypes: ['percent', 'fixed', 'onetime', 'welcome', 'free shipping'],
            filteredcouponTypes: [],
            isWelcomeCouponExist: false,
            isFreeShippingExist: false,
            couponType: '',
            currentExpireDate: ''
        }
    },
    async created() {
        document.title = "GoldenGal - Coupon";
        await this.getDataList(this.setURL);
        this.handleCoupon();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        handleCoupon: function () {
            this.isWelcomeCouponExist = false;
            this.isFreeShippingExist = false;
            if (this.dataList.length > 0) {
                for (let i = 0; i < this.dataList.length; i++) {
                    if (this.dataList[i].couponType == 'welcome') {
                        this.isWelcomeCouponExist = true;
                    } else if (this.dataList[i].couponType == 'free shipping') {
                        this.isFreeShippingExist = true;
                    }
                }
            }
            this.filteredcouponTypes = this.couponTypes;
            let tempCouponType = this.filteredcouponTypes;
            if (this.isWelcomeCouponExist == true) {
                this.filteredcouponTypes = tempCouponType.filter(el => el != 'welcome')
            }
            if (this.isFreeShippingExist == true) {
                tempCouponType = this.filteredcouponTypes;
                this.filteredcouponTypes = tempCouponType.filter(el => el != 'free shipping')
            }
        },
        addCoupon: async function () {
            if (!this.couponName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon name!"
                });
            }
            else if (!this.couponCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon code!"
                });
            }
            else if (!this.expireDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon expire date!"
                });
            }
            else if (!this.couponType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon type!"
                });
            }
            else if (!this.discount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.couponName,
                    couponCode: this.couponCode,
                    expireDate: this.expireDate,
                    couponType: this.couponType,
                    discount: this.discount,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                document.getElementById('addCouponModal').click();
                document.getElementById('addFSCouponModal').click();
                this.couponName = '';
                this.couponCode = '';
                this.expireDate = '';
                this.couponType = '';
                this.discount = '';
                await this.getDataList(this.setURL);
                this.handleCoupon();
            }
        },
        addFSCoupon: async function () {
            if (!this.couponName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon name!"
                });
            }
            else if (!this.couponCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon code!"
                });
            }
            else if (!this.expireDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon expire date!"
                });
            }
            else if (!this.discount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.couponName,
                    couponCode: this.couponCode,
                    expireDate: this.expireDate,
                    couponType: 'free shipping',
                    discount: this.discount,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                document.getElementById('addCouponModal').click();
                document.getElementById('addFSCouponModal').click();
                this.couponName = '';
                this.couponCode = '';
                this.expireDate = '';
                this.couponType = '';
                this.discount = '';
                await this.getDataList(this.setURL);
            }
        },
        editCoupon: async function (data) {
            this.singleCoupon = data;
            this.currentExpireDate = this.$filters.formatUpdateDate(data.expireDate).toString();
        },
        updateCoupon: async function () {
            if (!this.singleCoupon.couponName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon name!"
                });
            }
            else if (!this.singleCoupon.couponCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon code!"
                });
            }
            else if (!this.singleCoupon.expireDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon expire date!"
                });
            }
            else if (!this.singleCoupon.couponType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon type!"
                });
            }
            else if (!this.singleCoupon.discount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.singleCoupon.couponName,
                    couponCode: this.singleCoupon.couponCode,
                    expireDate: this.singleCoupon.expireDate,
                    couponType: this.singleCoupon.couponType,
                    discount: this.singleCoupon.discount,
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleCoupon.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                document.getElementById('closeButton').click();
                this.singleCoupon = {};
                this.currentExpireDate = '';
                await this.getDataList(this.setURL);
                this.handleCoupon();
            }
        },
        deleteCoupon: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.singleAPI + id,
                            headers: authHeader(),
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                        this.handleCoupon();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}