import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LinkShortcut from "../../layouts/template/LinkShortcut";
export default {
    components:{
        LinkShortcut
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            buttonText: '',
            buttonLink: '',
            image: '',
            previewImage: '',
            title: '',
            description: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Home Page Section Two";
    },
    methods: {
        onImageChange: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button text!"
                });
                return false;
            }
            if(!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            if(!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                try {
                    let formData =  new FormData();
                    formData.append('title', this.title);
                    formData.append('description', this.description);
                    formData.append('buttonText', this.buttonText);
                    formData.append('buttonLink', this.buttonLink);
                    formData.append('image', this.image);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.home.sectionTwoURL,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.title = '';
                    this.description = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.image = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$router.push("/section-two");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}