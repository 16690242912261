<template>
    <div class="border border-3 p-4 rounded">                 
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Category Link:</label>
                <input type="text" disabled value="/shop/category/gold" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" style="cursor: pointer;" @click="cpLinkShop('/shop/category/gold')">Copy Link</span>
                </div>
            </div>
            
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Color Link:</label>
                <input type="text" disabled value="/shop/color/Red" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" style="cursor: pointer;"  @click="cpLinkShop('/shop/color/Red')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Size Link:</label>
                <input type="text" disabled value="/shop/size/M" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"  style="cursor: pointer;" @click="cpLinkShop('/shop/size/M')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Tag Link:</label>
                <input type="text" disabled value="/shop/tag/Ring" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" style="cursor: pointer;"  @click="cpLinkShop('/shop/tag/Ring')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Highlight Link:</label>
                <input type="text" disabled value="/shop/highlight/Special Collection" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" style="cursor: pointer;"  @click="cpLinkShop('/shop/highlight/Special Collection')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label col-md-12">Material Link:</label>
                <input type="text" disabled value="/shop/material/Diamond" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" style="cursor: pointer;" @click="cpLinkShop('/shop/material/Diamond')">Copy Link</span>
                </div>
            </div>
        </div>
        
    </div>
</template>