import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditCustomerReview",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            reviewerName: '',
            message: '',
            imageFile: '',
            reviewerImage: '',
            editID: '',
            previewImage: '',
            id: this.$route.params.id
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Customer Review";
        if (this.id) {
            this.fetchEditData(this.id);
        }
    },
    methods: {
        fetchEditData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.customerReviewURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if ((response.data.data).length > 0) {
                        this.dataList = response.data.data[0];
                        this.editID = this.dataList.id,
                            this.reviewerName = this.dataList.reviewerName,
                            this.message = this.dataList.message,
                            this.reviewerImage = this.dataList.reviewerImage
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        update: async function () {
            try {
                if (!this.reviewerName) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter reviewer name!"
                    });
                    return;
                }
                else if (!this.imageFile && !this.reviewerImage) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please upload reviewer image!"
                    });
                    return;
                }
                else if (!this.message) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter message!"
                    });
                    return;
                }
                let formData = new FormData();
                formData.append('reviewerName', this.reviewerName);
                formData.append('reviewerImage', this.imageFile ? this.imageFile : this.reviewerImage);
                formData.append('message', this.message);
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.home.customerReviewURL + '/' + this.editID,
                    data: formData,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.updateDataToBackend(config);
                this.$swal.hideLoading();
                this.previewImage = '';
                this.$refs.imageFile.value = null;
                this.$router.push("/customer-review");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}