import { authHeader } from "../../../auth";
export default {
    name: "Admins",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            userType: 'admin',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            gender: '',
            setURL: '',
            singleAdmin: {},
            id: localStorage.getItem('id'),
        }
    },
    async created() {
        document.title = "Golden Gal - Admin";
        this.setURL = this.$serverURL + this.$api.user.adminUserList;

        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        checkValidation: function () {
            if (!this.fullName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter full name!"
                });
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter email!"
                });
                return false;
            }
            if (!this.phone) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter phone number!"
                });
                return false;
            }
            if (this.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone number nust be at least 10 digits!"
                });
                return false;
            }
            if (!this.password) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter password!"
                });
                return false;
            }
            if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter confirm password!"
                });
                return false;
            }
            if (this.password != this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    title: "Password and Confirm Password does not match!"
                });
                return false;
            }
            return true;
        },
        addAdmin: async function () {

            if (this.checkValidation()) {
                let data = {
                    fullName: this.fullName,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                    country: "United States of America",
                    userType: this.userType,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.registrationURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                document.getElementById('addModalCloseBtn').click();
                this.fullName = '';
                this.email = '';
                this.phone = '';
                this.userType = 'admin';
                this.password = '';
                this.confirmPassword = '';
                this.inputTypePassword = "password";
                this.inputTypeConfirmPassword = "password";
                await this.getDataList(this.setURL);
            }
        },
        editAdmin: function (data) {
            this.singleAdmin = data;
        },
        checkUpdateValidation: function () {
            if (!this.singleAdmin.fullName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter full name!"
                });
                return false;
            }
            if (!this.singleAdmin.email) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter email!"
                });
                return false;
            }
            if (!this.singleAdmin.phone) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter phone number!"
                });
                return false;
            }
            if (this.singleAdmin.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone number nust be at least 10 digits!"
                });
                return false;
            }
            return true;
        },
        updateAdmin: async function () {

            if (this.checkUpdateValidation()) {
                let data = {
                    fullName: this.singleAdmin.fullName,
                    phone: this.singleAdmin.phone,
                    userType: this.singleAdmin.userType,
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUserDetails + this.singleAdmin.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                document.getElementById('editModalCloseBtn').click();
                this.singleAdmin = {};
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteAdmin: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUserDetails + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        }
    }
}