<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Product Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Product Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Product Page CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Page Title:</label>
                                        <input type="text" v-model="pageCMSData.pageTitle" class="form-control"
                                            id="inputPageHeaderTitle" placeholder="Enter page title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Page Sub Title:</label>
                                        <input type="text" v-model="pageCMSData.pageSubTitle" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter sub title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Header Title:</label>
                                        <input type="text" v-model="pageCMSData.headerTitle" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter header title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Header Sub Title:</label>
                                        <input type="text" v-model="pageCMSData.headerSubTitle" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter header sub title">
                                    </div>
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Discount Image: <strong>[Preferred Image Size: 257X546, Max Upload Limit: 1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImage" :src="previewImage" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else-if="pageCMSData.discountImage" :src="pageCMSData.discountImage" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else  src="../../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="backgroundImage" accept="image/*" @change="uploadFeaturedImage" />
                                    </div>
                                    
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button"
                                            class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/product-cms.js"></script>