
import { authHeader } from "../../../../auth";
export default {
    components: {
        isCreated: false,
    },
    data() {
        return {
            singleContent: {},
            name: "",
            featuredImage: "",
            previewImage: "",
            updateImage: "",
            previewUpdateImage: "",
            setURL: this.$serverURL + this.$api.productCategory.parentProductCategoryURL,
            imageURL: this.$imageURL + "category-image/"

        }
    },
    async created() {
        document.title = "Golden Gal - Category";
        await this.getAllParentCategories();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage(event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.addFeaturedImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImage(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.updateFeaturedImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addCategory: async function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category name!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('name', this.name);
                if (this.featuredImage) {
                    formData.append('image', this.featuredImage);
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                document.getElementById('addModalCloseBtn').click();
                await this.getAllParentCategories();
                this.categoryType = null;
                this.parentCategoryValue = {};
                this.name = '';
                this.featuredImage = '';
                this.$refs.addFeaturedImage.value = "";
                this.previewImage = "";
            }
        },
        editCategory: function (data) {
            this.singleContent = data;
        },
        updateCategory: async function () {
            if(!this.singleContent.name){
                this.$swal.fire({
                    icon:'error',
                    text: "Please enter category name."
                })
                return;
            }
            let formData = new FormData();
            formData.append('name', this.singleContent.name);
            if (this.updateImage) {
                formData.append('image', this.updateImage);
            } else {
                formData.append('image', this.singleContent.image);
            }
            let config = {
                method: "PATCH",
                url: this.setURL + "/" + this.singleContent.id,
                data: formData,
                headers: authHeader(),
            };
            await this.updateDataToBackend(config);
            document.getElementById('updateModalCloseBtn').click();
            await this.getAllParentCategories();
            this.parentCategoryValue = "";
            this.updateImage = '';
            this.previewUpdateImage = '';
            this.singleContent = {};
            this.$refs.updateFeaturedImage.value = null;
        },
        deleteCategory: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + "/" + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getAllParentCategories();
                        await this.getSubCategories();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later."
                        });
                    }
                }
            })

        }
    }
}