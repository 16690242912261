import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: '',
            pageCMSData: [],
            image: "",
            previewImageOne: "",
            titleColor: "",
            dataLoaded: false,
            colors: [
                 "#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50"
                                                      , "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ]
        }
    },
    async created() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.componentCMS.homePageURL;
        
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    sectionOneTitleFontColor: this.pageCMSData.sectionOneTitleFontColor ? this.pageCMSData.sectionOneTitleFontColor : '#262626',
                    backgroundFontColor: this.pageCMSData.backgroundFontColor ? this.pageCMSData.backgroundFontColor : '#FFFFFF',
                    sectionTwoTitleFontColor: this.pageCMSData.sectionTwoTitleFontColor ? this.pageCMSData.sectionTwoTitleFontColor : '#ED1122',
                    sectionTwoSubTitleFontColor: this.pageCMSData.sectionTwoSubTitleFontColor ? this.pageCMSData.sectionTwoSubTitleFontColor : '#000000',
                    sectionThreeTitleFontColor: this.pageCMSData.sectionThreeTitleFontColor ? this.pageCMSData.sectionThreeTitleFontColor : '#000000',
                    sectionFourTitleFontColor: this.pageCMSData.sectionFourTitleFontColor ? this.pageCMSData.sectionFourTitleFontColor : '#000000',
                    sectionFiveTitleFontColor: this.pageCMSData.sectionFiveTitleFontColor ? this.pageCMSData.sectionFiveTitleFontColor : '#ED1122',
                    sectionFiveSubTitleFontColor: this.pageCMSData.sectionFiveSubTitleFontColor ? this.pageCMSData.sectionFiveSubTitleFontColor : '#000000',
                    sectionSixTitleFontColor: this.pageCMSData.sectionSixTitleFontColor ? this.pageCMSData.sectionSixTitleFontColor : '#000000',
                    sectionEightTitleFontColor: this.pageCMSData.sectionEightTitleFontColor ? this.pageCMSData.sectionEightTitleFontColor : '#ED1122',
                    sectionEightSubTitleFontColor: this.pageCMSData.sectionEightSubTitleFontColor ? this.pageCMSData.sectionEightSubTitleFontColor : '#000000',
                    sectionNineTitleFontColor: this.pageCMSData.sectionNineTitleFontColor ? this.pageCMSData.sectionNineTitleFontColor : '#000000',
                    sliderTitleUnderlineColor: this.pageCMSData.sliderTitleUnderlineColor ? this.pageCMSData.sliderTitleUnderlineColor : '#ED1122',
                    sliderDotColor: this.pageCMSData.sliderDotColor ? this.pageCMSData.sliderDotColor : '#ED1122',
                    sectionTwoButtonColor: this.pageCMSData.sectionTwoButtonColor ? this.pageCMSData.sectionTwoButtonColor : '#ED1122',
                    sectionThreeButtonColor: this.pageCMSData.sectionThreeButtonColor ? this.pageCMSData.sectionThreeButtonColor : '#ED1122',

                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}