import ChangePassword from "../template/ChangePassword";
import { authHeader } from "../../../auth";

export default {
    name: "Profile",
    components: {
        ChangePassword
    },
    data() {
        return {
            userDetails: {},
            id: '',
            email: '',
            isEdit: false
        }
    },
    async mounted() {
        document.title = "Golden Gal - Profile";
        this.id = localStorage.getItem("id");
        this.email = localStorage.getItem("email");
        await this.getUserInfo();
    },
    methods: {
        getUserInfo: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.singleUserDetails + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.userDetails = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("fullName", this.userDetails.fullName);
            formData.append("displayName", this.userDetails.displayName);
            formData.append("phone", this.userDetails.phone);
            formData.append("streetAddress", this.userDetails.streetAddress);
            formData.append("city", this.userDetails.city);
            formData.append("postCode", this.userDetails.postCode);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.updateProfile,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem("fullName", this.userDetails.fullName);
                    localStorage.setItem("displayName", this.userDetails.displayName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("phone", this.userDetails.phone);
                    this.$swal.fire({
                        icon: "success",
                        text: "Data updated successfully!"
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
            this.isEdit = false;
        }
    }
}
