import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "About Services",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            aboutServicesList: [],
            singleAboutUsService: {},
            title: "",
            iconClass: "",
            description: "",
            setURL: this.$serverURL + this.$api.aboutUs.aboutUsService,
            setSingleContentURL: this.$serverURL + this.$api.aboutUs.singleAboutUsService,
        }
    },
    async created() {
        document.title = "Golden Gal - About Services";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addAboutUsService: async function () {
            
            if (!this.title || !this.description || !this.iconClass) {
                
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, icon class and description!"
                });
            }
            else {
                let data = {
                    title: this.title,
                    iconClass: this.iconClass,
                    description: this.description,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.title = '';
                this.iconClass = '';
                this.description = '';
                await this.getDataList(this.setURL);
            }
        },
        editAboutUsService: function (data) {
            this.singleAboutUsService = data;
        },
        updateAboutUsService: async function () {
            if (!this.singleAboutUsService.title || !this.singleAboutUsService.description || !this.singleAboutUsService.iconClass) {
                
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, icon class and description!"
                });
            }
            else {
                let data = {
                    title: this.singleAboutUsService.title,
                    iconClass: this.singleAboutUsService.iconClass,
                    description: this.singleAboutUsService.description,
                };
                let config = {
                    method: "PATCH",
                    url: this.setSingleContentURL + this.singleAboutUsService.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleAboutUsService = {};
                await this.getDataList(this.setURL);
            }
        },
        deleteAboutUsService: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setSingleContentURL + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}