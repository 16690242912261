<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Stock
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelStockIn">
                            <i class="bx bxs-plus-square"></i>Export</button>
                    </div>
                </div>

            </div>
            <h6 class="mb-0 text-uppercase">Stock List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Stock Id</th>
                                    <th>Total Current Stock</th>
                                    <th>Total New Stock</th>
                                    <th>Total Stock</th>
                                    <th>Total Product</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in productList" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td><a href="javascript:void()"
                                            @click="$router.push('/stock-detail/' + data.StockID)">{{data.StockID}}</a>
                                    </td>
                                    <td>{{data.current_stock}}</td>
                                    <td>{{data.new_stock}}</td>
                                    <td>{{data.total_stock}}</td>
                                    <td>{{data.total_product}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0)" class="edit-button"
                                                @click="$router.push('/stock-detail/' + data.StockID)"><i
                                                    class="fa-solid fa-eye text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Stock Id</th>
                                    <th>Total Current Stock</th>
                                    <th>Total New Stock</th>
                                    <th>Total Stock</th>
                                    <th>Total Product</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/stock-list.js'></script>


<style scoped>
    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>