<template>
   <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Create Customer Account - Email Template - Elementor</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Create Customer Account - Email Template - Elementor</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Customer Account - Email Template - Elementor</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row" style="position: relative;">
              <div class="col-lg-9 col-md-6" style="overflow:hidden;">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3 d-flex justify-center" style="text-align:center; display: flex !important;justify-content: center; flex-direction: column;" id="emailBody">
                    
                    <table id="u_body" class="u-body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 700px;margin: auto;background-color: #ffffff;width:100%; display: flex !important; justify-content: center !important;" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr style="vertical-align: top">
                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                                    
                                    <!-- Start Logo Section -->
                                    <div class="u-row-container" :style="'padding: 0px;background-color: '+template.logo.backgroundColor+'; position: relative;'" @mouseenter="handleLogoCustomizationShow"  @mouseleave="handleLogoCustomizationClose">
                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleLogoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>
                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.sectionOne.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word; background-color: '+template.logo.backgroundColor+' !important;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.sectionOne.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                <tr>
                                                                    <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
                                                                        <div :align="template.logo.align" class="alignment" style="line-height:10px"><img alt="Alternate text" :src="template.logo.url" :style="'display: block; height: '+template.logo.height+'px; border: '+ template.logo.borderSize +'px '+template.logo.borderStyle +' '+template.logo.borderColor+'; width: '+template.logo.width+'px; max-width: 100%; padding-top: '+template.logo.paddingTop+'px; padding-right: '+template.logo.paddingRight+'px; padding-bottom: '+template.logo.paddingBottom+'px; padding-left: '+template.logo.paddingLeft+'px; margin-top: '+template.logo.marginTop+'px; margin-right: '+template.logo.marginRight+'px;margin-bottom: '+template.logo.marginBottom+'px; margin-left: '+template.logo.marginLeft+'px; object-fit: '+template.logo.objectFit+';'" title="Alternate text" width="200"/></div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Logo Section -->
                                    
                                    <!-- Start Section One -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionCustomizationShow"  @mouseleave="handleSectionCustomizationClose">

                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.sectionOne.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.sectionOne.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div :style="'background-color: '+template.sectionOne.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.sectionOne.background.borderRadius+'px;-webkit-border-radius: '+template.sectionOne.background.borderRadius+'px; -moz-border-radius: '+template.sectionOne.background.borderRadius+'px;'">
                                                        
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:40px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                            <tr>
                                                                                <td style="padding-right: 0px;padding-left: 0px; position: relative;" :align="template.sectionOne.image.align" @mouseenter="handleIconCustomizationShow"  @mouseleave="handleIconCustomizationClose">
                                                                                    <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneIconCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <img align="center" border="0" :src="template.sectionOne.image.url" alt="Cart Icon" title="Cart Icon" :style="'outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: '+template.sectionOne.image.width+'px; height: '+template.sectionOne.image.height+'px; object-fit: '+template.sectionOne.image.objectFit+'; border: '+template.sectionOne.image.borderSize+'px '+template.sectionOne.image.borderStyle+' '+template.sectionOne.image.borderColor+'; border-radius: '+template.sectionOne.image.borderRadius+'%; padding-top: '+template.sectionOne.image.paddingTop+'px; padding-right: '+template.sectionOne.image.paddingRight+'px; padding-bottom: '+template.sectionOne.image.paddingBottom+'px; padding-left: '+template.sectionOne.image.paddingLeft+'px; margin-top: '+template.sectionOne.image.marginTop+'px; margin-right: '+template.sectionOne.image.marginRight+'px;margin-bottom: '+template.sectionOne.image.marginBottom+'px; margin-left: '+template.sectionOne.image.marginLeft+'px;'" :width="template.sectionOne.image.width"/>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif; position: relative;" align="left" @mouseenter="handleSectionTitleOneCustomizationShow"  @mouseleave="handleSectionTitleOneCustomizationClose">

                                                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <div :style="'line-height: 140%; text-align: '+template.sectionOne.titleOne.align+'; word-wrap: break-word;'">
                                                                            <p v-if="titleOneStatus" style="font-size: 14px; line-height: 140%;">
                                                                                <input type="text" v-model="template.sectionOne.titleOne.text" 
                                                                                :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleOne.align+'; line-height: 25.2px; font-size: '+template.sectionOne.titleOne.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleOne.paddingTop+'px; padding-right: '+template.sectionOne.titleOne.paddingRight+'px; padding-bottom: '+template.sectionOne.titleOne.paddingBottom+'px; padding-left: '+template.sectionOne.titleOne.paddingLeft+'px; margin-top: '+template.sectionOne.titleOne.marginTop+'px; margin-right: '+template.sectionOne.titleOne.marginRight+'px;margin-bottom: '+template.sectionOne.titleOne.marginBottom+'px; margin-left: '+template.sectionOne.titleOne.marginLeft+'px;'"
                                                                                @blur="handleTitleOneTextClose">
                                                                            </p>
                                                                            <p v-else @click="handleTitleOneTextShow" style="font-size: 14px; line-height: 140%;"><span :style="'display: block; font-size: '+template.sectionOne.titleOne.fontSize+'px; line-height: '+template.sectionOne.titleOne.lineHeight+'px; font-family: '+template.sectionOne.titleOne.fontFamily+'; padding-top: '+template.sectionOne.titleOne.paddingTop+'px; padding-right: '+template.sectionOne.titleOne.paddingRight+'px; padding-bottom: '+template.sectionOne.titleOne.paddingBottom+'px; padding-left: '+template.sectionOne.titleOne.paddingLeft+'px; margin-top: '+template.sectionOne.titleOne.marginTop+'px; margin-right: '+template.sectionOne.titleOne.marginRight+'px;margin-bottom: '+template.sectionOne.titleOne.marginBottom+'px; margin-left: '+template.sectionOne.titleOne.marginLeft+'px;'"><strong><span :style="'line-height: 25.2px; font-size: '+template.sectionOne.titleOne.fontSize+'px; color: '+template.sectionOne.titleOne.color+';'">{{ template.sectionOne.titleOne.text }}</span></strong></span></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div :style="'line-height: 140%; word-wrap: break-word;'">
                                                                            <div style="position: relative;" @mouseenter="handleSectionTitleTwoCustomizationShow"  @mouseleave="handleSectionTitleTwoCustomizationClose">
                                                                                <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleTwoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p v-if="titleTwoStatus" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.sectionOne.titleTwo.text" 
                                                                                    :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleTwo.align+'; line-height: 19.6px; font-size: '+template.sectionOne.titleTwo.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleTwo.paddingTop+'px; padding-right: '+template.sectionOne.titleTwo.paddingRight+'px; padding-bottom: '+template.sectionOne.titleTwo.paddingBottom+'px; padding-left: '+template.sectionOne.titleTwo.paddingLeft+'px; margin-top: '+template.sectionOne.titleTwo.marginTop+'px; margin-right: '+template.sectionOne.titleTwo.marginRight+'px;margin-bottom: '+template.sectionOne.titleTwo.marginBottom+'px; margin-left: '+template.sectionOne.titleTwo.marginLeft+'px;'"
                                                                                    @blur="handleTitleTwoTextClose">
                                                                                </p>
                                                                                <p v-else @click="handleTitleTwoTextShow" :style="'font-size: 14px; line-height: 140%; text-align: '+template.sectionOne.titleTwo.align+';'">
                                                                                    <span :style="'display: block; font-size: '+template.sectionOne.titleTwo.fontSize+'px; line-height: '+template.sectionOne.titleTwo.lineHeight+'px; font-family: '+template.sectionOne.titleTwo.fontFamily+'; padding-top: '+template.sectionOne.titleTwo.paddingTop+'px; padding-right: '+template.sectionOne.titleTwo.paddingRight+'px; padding-bottom: '+template.sectionOne.titleTwo.paddingBottom+'px; padding-left: '+template.sectionOne.titleTwo.paddingLeft+'px; margin-top: '+template.sectionOne.titleTwo.marginTop+'px; margin-right: '+template.sectionOne.titleTwo.marginRight+'px;margin-bottom: '+template.sectionOne.titleTwo.marginBottom+'px; margin-left: '+template.sectionOne.titleTwo.marginLeft+'px; color: '+template.sectionOne.titleTwo.color+';'">{{ template.sectionOne.titleTwo.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div style="position: relative;" @mouseenter="handleSectionTitleThreeCustomizationShow"  @mouseleave="handleSectionTitleThreeCustomizationClose">
                                                                                <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleThreeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p v-if="titleThreeStatus" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.sectionOne.titleThree.text" 
                                                                                    :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleThree.align+'; line-height: 19.6px; font-size: '+template.sectionOne.titleThree.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleThree.paddingTop+'px; padding-right: '+template.sectionOne.titleThree.paddingRight+'px; padding-bottom: '+template.sectionOne.titleThree.paddingBottom+'px; padding-left: '+template.sectionOne.titleThree.paddingLeft+'px; margin-top: '+template.sectionOne.titleThree.marginTop+'px; margin-right: '+template.sectionOne.titleThree.marginRight+'px;margin-bottom: '+template.sectionOne.titleThree.marginBottom+'px; margin-left: '+template.sectionOne.titleThree.marginLeft+'px;'"
                                                                                    @blur="handleTitleThreeTextClose">
                                                                                </p>
                                                                                <p v-else @click="handleTitleThreeTextShow" :style="'font-size: 14px; line-height: 140%; text-align: '+template.sectionOne.titleThree.align+';'">
                                                                                    <span :style="'display: block; font-size: '+template.sectionOne.titleThree.fontSize+'px; line-height: '+template.sectionOne.titleThree.lineHeight+'px; font-family: '+template.sectionOne.titleThree.fontFamily+'; padding-top: '+template.sectionOne.titleThree.paddingTop+'px; padding-right: '+template.sectionOne.titleThree.paddingRight+'px; padding-bottom: '+template.sectionOne.titleThree.paddingBottom+'px; padding-left: '+template.sectionOne.titleThree.paddingLeft+'px; margin-top: '+template.sectionOne.titleThree.marginTop+'px; margin-right: '+template.sectionOne.titleThree.marginRight+'px;margin-bottom: '+template.sectionOne.titleThree.marginBottom+'px; margin-left: '+template.sectionOne.titleThree.marginLeft+'px; color: '+template.sectionOne.titleThree.color+';'">{{ template.sectionOne.titleThree.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td :style="'overflow-wrap:break-word;word-break:break-word; padding:10px 10px 20px;font-family: '+template.sectionOne.button.fontFamily+'; position: relative;'" align="left" @mouseenter="handleSectionButtonCustomizationShow"  @mouseleave="handleSectionButtonCustomizationClose">

                                                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionButtonCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <div :align="template.sectionOne.button.align" :style="'margin-top: '+template.sectionOne.button.marginTop+'px; margin-right: '+template.sectionOne.button.marginRight+'px;margin-bottom: '+template.sectionOne.button.marginBottom+'px; margin-left: '+template.sectionOne.button.marginLeft+'px;'">
                                                                            <a :href="template.sectionOne.button.link" target="_blank" class="v-button" :style="'box-sizing: border-box;display: inline-block;font-family:'+template.sectionOne.button.fontFamily+';text-decoration: none;-webkit-text-size-adjust: none;text-align: '+template.sectionOne.button.align+';color: '+template.sectionOne.button.color+'; background-color: '+template.sectionOne.button.backgroundColor+'; border-radius: '+template.sectionOne.button.borderRadius+'px;-webkit-border-radius: '+template.sectionOne.button.borderRadius+'px; -moz-border-radius: '+template.sectionOne.button.borderRadius+'px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;'">
                                                                            <span :style="'display:block;padding-top: '+template.sectionOne.button.paddingTop+'px; padding-right: '+template.sectionOne.button.paddingRight+'px; padding-bottom: '+template.sectionOne.button.paddingBottom+'px; padding-left: '+template.sectionOne.button.paddingLeft+'px; line-height:120%;'"><span :style="'font-family: '+template.sectionOne.button.fontFamily+'; font-size: '+template.sectionOne.button.fontSize+'px; line-height: 16.8px;'"><strong>{{ template.sectionOne.button.text }}</strong></span></span>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Section One -->


                                    <!-- Start Contact Us Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionContactUsCustomizationShow"  @mouseleave="handleSectionContactUsCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionContactUsCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.contactUsSection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.contactUsSection.background.width+'px;display: table-cell;vertical-align: top;'">
                                                <div :style="'background-color: '+template.contactUsSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.contactUsSection.background.borderRadius+'px;-webkit-border-radius: '+template.contactUsSection.background.borderRadius+'px; -moz-border-radius: '+template.contactUsSection.background.borderRadius+'px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:20px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div :style="'line-height: 140%; word-wrap: break-word;'">
                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleOneCustomizationShow"  @mouseleave="handleContactUsTitleOneCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleOne.align+';'">
                                                                                    <span v-if="ContactUsTOStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleOne.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleOne.align+'; line-height: '+template.contactUsSection.orderTitleOne.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleOne.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleOne.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleOne.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleOne.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleOne.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleOne.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleOne.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleOne.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleOne.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTOTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTOTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleOne.fontSize+'px; line-height: '+template.contactUsSection.orderTitleOne.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleOne.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleOne.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleOne.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleOne.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleOne.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleOne.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleOne.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleOne.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleOne.marginLeft+'px; color: '+template.contactUsSection.orderTitleOne.color+';'">{{ template.contactUsSection.orderTitleOne.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleTwoCustomizationShow"  @mouseleave="handleContactUsTitleTwoCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleTwoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleTwo.align+';'">
                                                                                    <span v-if="ContactUsTTStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleTwo.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleTwo.align+'; line-height: '+template.contactUsSection.orderTitleTwo.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleTwo.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleTwo.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleTwo.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleTwo.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleTwo.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleTwo.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleTwo.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleTwo.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleTwo.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTTTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTTTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleTwo.fontSize+'px; line-height: '+template.contactUsSection.orderTitleTwo.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleTwo.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleTwo.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleTwo.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleTwo.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleTwo.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleTwo.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleTwo.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleTwo.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleTwo.marginLeft+'px; color: '+template.contactUsSection.orderTitleTwo.color+';'">{{ template.contactUsSection.orderTitleTwo.text }}</span>
                                                                                </p>
                                                                            </div>

                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleThreeCustomizationShow"  @mouseleave="handleContactUsTitleThreeCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleThreeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleThree.align+';'">
                                                                                    <span v-if="ContactUsTThStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleThree.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleThree.align+'; line-height: '+template.contactUsSection.orderTitleThree.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleThree.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleThree.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleThree.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleThree.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleThree.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleThree.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleThree.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleThree.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleThree.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTThTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTThTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleThree.fontSize+'px; line-height: '+template.contactUsSection.orderTitleThree.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleThree.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleThree.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleThree.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleThree.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleThree.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleThree.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleThree.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleThree.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleThree.marginLeft+'px; color: '+template.contactUsSection.orderTitleThree.color+';'">{{ template.contactUsSection.orderTitleThree.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Contact Us Section -->

                                    <!-- Start Copyright Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;">
                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                                    <div :style="'background-color: #f9f9f9;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-11" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                                        <table border="0" cellpadding="0" cellspacing="0" class="icons_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                                        <tr>
                                                                                            <td class="pad" style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">
                                                                                                <table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                                                    <tr>
                                                                                                    <td class="alignment" style="vertical-align: middle; text-align: center;">
                                                                                                        <table cellpadding="0" cellspacing="0" class="icons-inner" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                                                                                            <tr style="display: flex; justify-content: center; align-items: center;">
                                                                                                                <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;"><a href="https://neutrix.co/" style="text-decoration: none;" target="_blank"><img align="center" alt="Designed with BEE" class="icon" height="32" src="https://neutrix.co/uploads/images/20230213-63ea88220b1bf.png" style="display: block; height: auto; margin: 0 auto; border: 0;" width="34"/></a></td>
                                                                                                                <td style="font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; font-size: 14px; color: #9d9d9d; vertical-align: middle; letter-spacing: undefined; text-align: center;"><a href="https://neutrix.co/" style="color: #9d9d9d; text-decoration: none;" target="_blank">BUILT BY NEUTRIX</a></td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Copyright Section -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                  </div>
                  <div class="mb-3" style="text-align: center;">
                        <button v-on:click="publishUpdateTemplate" type="button" class="btn btn-primary">Publish Updated Template</button> 
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6" style="position: absolute; right: 0px; top: 0px; max-height: 1800px; overflow: auto;">
                    <div class="border border-3 p-4 rounded my-2">
                        <code v-for="(level, index) in templateData" :key="index">{{ level.emailKeywordLabel }}</code>   
                        <hr />
                        <h6>How to use:</h6>
                        <p>Hi Mr <code style="color:black;">[FullName]</code>, Nice to meet you.</p>
                        
                    </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--start logo switcher-->
  <div class="switcher-wrapper logo-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Logo Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="inputProductTitle" class="form-label" style="text-align:left; font-weight: bold;">Logo Image: <br /><strong>[Image Size: 200 X 66, Max: 200 KB]</strong></label>
                <input class="form-control mb-1" type="file" ref="backgroundImage" accept="image/*" @change="uploadFeaturedImage" />
                <div class="logo-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.logo.width }}] px</span></div>
                    <input v-model="template.logo.width" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.logo.height }}] px</span></div>
                    <input v-model="template.logo.height" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-object-fit" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="left"><input v-model="template.logo.align" type="radio" name="align" id="left" value="left"> Left</label>&nbsp;
                    <label for="center"><input v-model="template.logo.align" type="radio" name="align" id="center" value="center"> Center</label>&nbsp;
                    <label for="right"><input v-model="template.logo.align" type="radio" name="align" id="right" value="right"> Right</label>
                </div>

                <LvColorpicker label="Background Color:" v-model="template.logo.backgroundColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span>
                    <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>

                    <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                        <span style="width: 100%;">Border Style: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.borderStyle" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.logo.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                    
                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                    

                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                </div>
            </div>
        </div>
  </div>
  <!--end logo switcher-->

  <!--start section one icon switcher-->
  <div class="switcher-wrapper section-one-icon-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Icon Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="sectionTwoImage" class="form-label" style="text-align:left; margin-top: 10px; font-weight: bold;">Icon: <br /><strong>[Image Size: 72 X 72, Max: 200 KB]</strong></label>
                    <input class="form-control mb-1" type="file" ref="sectionOneImage" accept="image/*" @change="uploadSectionOneImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.sectionOne.image.width }}] px</span></div>
                    <input v-model="template.sectionOne.image.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.sectionOne.image.height }}] px</span></div>
                    <input v-model="template.sectionOne.image.height" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="leftSO" value="left"> Left</label>&nbsp;
                    <label for="centerSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="centerSO" value="center"> Center</label>&nbsp;
                    <label for="rightSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="rightSO" value="right"> Right</label>
                </div>

                <div class="section-image-height" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.image.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-image-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 10px;">
                        <span style="width: 100%;">Border Style:</span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.image.borderStyle" class="form-control" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.sectionOne.image.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />

                    <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Radius: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> %</span></div>
                    <input v-model="template.sectionOne.image.borderRadius" type="range" min="0" max="100" class="w-100">
                </div>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one icon switcher-->

  <!--start section titleOne switcher-->
  <div class="switcher-wrapper section-title-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleOne.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleOne.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleOne.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.sectionOne.titleOne.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="leftSTO" value="left"> Left</label>&nbsp;
                    <label for="centerSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="centerSTO" value="center"> Center</label>&nbsp;
                    <label for="rightSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="rightSTO" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section titleOne switcher-->

  <!--start section titleTwo switcher-->
  <div class="switcher-wrapper section-title-two-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Two Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleTwo.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-Two" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleTwo.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleTwo.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color" v-model="template.sectionOne.titleTwo.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="leftSTT" value="left"> Left</label>&nbsp;
                    <label for="centerSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="centerSTT" value="center"> Center</label>&nbsp;
                    <label for="rightSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="rightSTT" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>               
            </div>
        </div>
  </div>
  <!--end section titleTwo switcher-->

  <!--start section titleThree switcher-->
  <div class="switcher-wrapper section-title-three-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Three Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleThree.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-Three" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleThree.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleThree.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.sectionOne.titleThree.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                    <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="leftSTTh" value="left"> Left</label>&nbsp;
                    <label for="centerSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="centerSTTh" value="center"> Center</label>&nbsp;
                    <label for="rightSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="rightSTTh" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>

            </div>
        </div>
  </div>
  <!--end section titleThree switcher-->

  <!--start section button switcher-->
  <div class="switcher-wrapper section-button-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Button Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one my-3" style="margin-top: 6px;">
                    Text: 
                    <input v-model="template.sectionOne.button.text" type="text" class="form-control"  /><br/>
                    Link: 
                    <input v-model="template.sectionOne.button.link" type="text" class="form-control" />
                </div>
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.button.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                    <LvColorpicker label="Background Color: " v-model="template.sectionOne.button.backgroundColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                    <LvColorpicker label="Color: " v-model="template.sectionOne.button.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="leftSTPTbtn" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="centerSTSPTbtn" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="rightSTSPTbtn" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div> 

            </div>
        </div>
  </div>
  <!--end section button switcher-->

  <!--start section one switcher-->
  <div class="switcher-wrapper section-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.sectionOne.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
            </div>
        </div>
  </div>
  <!--end section one switcher-->

  
  <!--start section contact us switcher-->
  <div class="switcher-wrapper section-contact-us-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Contact Us Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.contactUsSection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                <input v-model="template.contactUsSection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end section contact us switcher-->
  
  <!--start contact us title one switcher-->
  <div class="switcher-wrapper contact-us-title-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleOne.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleOne.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleOne.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleOne.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleOne"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="leftContactTitleOne" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleOne
                    OrderS"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="centerContactTitleOne
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleOne
                    OrderS"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="rightContactTitleOne
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title one switcher-->
  
  <!--start contact us title two switcher-->
  <div class="switcher-wrapper contact-us-title-two-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title Two Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleTwo.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleTwo.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleTwo.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleTwo.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleTwo"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="leftContactTitleTwo" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleTwo
                    OrderS"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="centerContactTitleTwo
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleTwo
                    OrderS"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="rightContactTitleTwo
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title two switcher-->
  
  <!--start contact us title three switcher-->
  <div class="switcher-wrapper contact-us-title-three-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title Three Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleThree.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleThree.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleThree.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleThree.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleThree"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="leftContactTitleThree" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleThree
                    OrderS"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="centerContactTitleThree
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleThree
                    OrderS"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="rightContactTitleThree
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title three switcher-->

</template>

<script src="../../../js/elementor/ForgotPassword/ForgotPasswordEmailTemplate.js"></script>

