import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    data() {
        return {
            page: 1,
            limit: 10,
            orderList: [],
            filter: {
                startDate: "",
                endDate: "",
                orderId: ""
            },
            filteredOrderIdList: [],
            sheets: [],
            cost: 0,
            costTotal: 0,
            itemPrice: 0,
            orderQuantity: 0,
            priceTotal: 0,
            profit: 0
        }
    },
    async mounted() {
        document.title = "Golden Gal - Profit Report";
        await this.getOrderList();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.report.productProfitReportURL + '?page=' + this.page + '&limit=' + this.limit + '&orderId=' + this.filter.orderId + '&startDate=' + this.filter.startDate + '&endDate=' + this.filter.endDate,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.orderDetails.data;
                        let cost = 0;
                        let costTotal = 0;
                        let itemPrice = 0;
                        let orderQuantity = 0;
                        let priceTotal = 0;
                        let profit = 0;
                        for (let index = 0; index < this.orderList.length; index++) {
                            const costSum = this.orderList[index]['cost'];
                            const costTotalSum = this.orderList[index]['costTotal'];
                            const itemPriceSum = this.orderList[index]['itemPrice'];
                            const orderQuantitySum = this.orderList[index]['orderQuantity'];
                            const priceTotalSum = this.orderList[index]['priceTotal'];
                            const profitSum = this.orderList[index]['profit'];
                            cost += (costSum - 0);
                            costTotal += (costTotalSum - 0);
                            itemPrice += (itemPriceSum - 0);
                            orderQuantity += (orderQuantitySum - 0);
                            priceTotal += (priceTotalSum - 0);
                            profit += (profitSum - 0);
                        }
                        this.cost = cost;
                        this.costTotal = costTotal;
                        this.itemPrice = itemPrice;
                        this.orderQuantity = orderQuantity;
                        this.priceTotal = priceTotal;
                        this.profit = profit;
                        if (this.filteredOrderIdList.length == 0) {
                            let uniqueOrder = this.orderList.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.orderId === value.orderId
                                ))
                            )
                            this.filteredOrderIdList = uniqueOrder.map(el => {
                                return { value: el.orderId, label: el.orderId }
                            });
                        }
                    }

                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try again later!"
                });
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date!"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date!"
                        });
                        return;
                    }
                }
                if (this.filter.orderId == null) {
                    this.filter.orderId = '';
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try again later!"
                });
            }
        },
        async clearFilter() {
            this.filter = {
                startDate: "",
                endDate: "",
                orderId: ""
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        exportData: function () {
            this.orderList.map((el) => {
                this.sheets.push({
                    'Order Id': el.orderId,
                    "Product Name": el.product_name,
                    "Cost": el.cost,
                    "Item Price": el.itemPrice,
                    "Order Quantity": el.orderQuantity,
                    "Cost Total": el.costTotal,
                    "Price Total": el.priceTotal,
                    "Profit": el.profit,
                    'Created At': this.$filters.formatDate(el.createdAt)
                });
            });
            this.sheets.push({
                'Order Id': "",
                "Product Name": "",
                "Cost":  this.cost,
                "Item Price": this.itemPrice,
                "Order Quantity": this.orderQuantity,
                "Cost Total":  this.costTotal,
                "Price Total": this.priceTotal,
                "Profit": this.profit,
                'Created At': "",
            });
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'product_profit_report(' + date + ').xlsx');
            this.sheets = [];
        }
    }
}