<template>
   <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Create Customer Account - Email Template - Elementor</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Create Customer Account - Email Template - Elementor</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Customer Account - Email Template - Elementor</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row" style="position: relative;">
              <div class="col-lg-9 col-md-6" style="overflow:hidden;">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3" style="text-align:center;" id="emailBody">
               
                      <div :style="'background-color: '+template.logo.backgroundColor+' !important; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none; max-width: 700px; position: relative;'" @mouseenter="handleBackgroundCustomizationShow"  @mouseleave="handleBackgroundCustomizationClose">

                            <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleBackgroundCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                            <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" :style="'mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: '+template.logo.backgroundColor+' !important;font-family: '+template.welcome.background.fontFamily+';'" width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <div class="spacer_block" style="height:10px;line-height:10px;font-size:1px;"> </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="width:100%;padding-right:0px;padding-left:0px; position: relative;" @mouseenter="handleLogoCustomizationShow"  @mouseleave="handleLogoCustomizationClose">

                                                                                <span style="position: absolute; top: 0; right: 20px; display: none; font-size: 20px;cursor: pointer;" @click="handleLogoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <div :align="template.logo.align" class="alignment" style="line-height:10px"><img alt="Alternate text" :src="template.logo.url" :style="'display: block; height: '+template.logo.height+'px; border: '+ template.logo.borderSize +'px '+template.logo.borderStyle +' '+template.logo.borderColor+'; width: '+template.logo.width+'px; max-width: 100%; padding-top: '+template.logo.paddingTop+'px; padding-right: '+template.logo.paddingRight+'px; padding-bottom: '+template.logo.paddingBottom+'px; padding-left: '+template.logo.paddingLeft+'px; margin-top: '+template.logo.marginTop+'px; margin-right: '+template.logo.marginRight+'px;margin-bottom: '+template.logo.marginBottom+'px; margin-left: '+template.logo.marginLeft+'px; object-fit: '+template.logo.objectFit+';'" title="Alternate text" width="200"/></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <div class="spacer_block" style="height:15px;line-height:15px;font-size:1px;"> </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
                                                                                <div align="center" class="alignment" style="line-height:10px"><img alt="Alternate text" class="big" src="https://res.cloudinary.com/deist90as/image/upload/v1681228930/Up_pink_ezeitc.png" style="display: block; height: auto; border: 0; width: 700px; max-width: 100%;" title="Alternate text" width="700"/></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style="position: relative;" @mouseenter="handleSectionOneCustomizationShow"  @mouseleave="handleSectionOneCustomizationClose">

                                                            <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer; z-index: 1" @click="handleSectionOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" :style="'mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: '+template.welcome.background.bgColor+'; color: '+template.welcome.background.textColor+'; width: '+template.welcome.background.width+'px; border-radius: '+template.welcome.background.borderRadius+'px;'" :width="template.welcome.background.width">
                                                            <tbody :style="'color: '+template.welcome.background.textColor+' !important; font-family: '+template.welcome.background.fontFamily+' !important;'">
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;padding-top:30px; position: relative;" @mouseenter="handleSectionOneImageCustomizationShow"  @mouseleave="handleSectionOneImageCustomizationClose">

                                                                                <span style="position: absolute; top: 40; right: 20px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneImageCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <div :align="template.welcome.image.align" class="alignment" style="line-height:10px"><img alt="I'm an image" :src="template.welcome.image.url" :style="'display: block; height: '+template.welcome.image.height+'px; border: '+template.welcome.image.borderSize+'px '+template.welcome.image.borderStyle+' '+template.welcome.image.borderColor+'; width: '+template.welcome.image.width+'px; max-width: 100%; padding-top: '+template.welcome.image.paddingTop+'px; padding-right: '+template.welcome.image.paddingRight+'px; padding-bottom: '+template.welcome.image.paddingBottom+'px; padding-left: '+template.welcome.image.paddingLeft+'px; margin-top: '+template.welcome.image.marginTop+'px; margin-right: '+template.welcome.image.marginRight+'px;margin-bottom: '+template.welcome.image.marginBottom+'px; margin-left: '+template.welcome.image.marginLeft+'px; object-fit:'+template.welcome.image.objectFit+';'" title="I'm an image" :width="template.welcome.image.width"/></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:10px;padding-left:40px;padding-right:40px;padding-top:10px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 18px; line-height: 1.5; font-family: '+ template.welcome.background.fontFamily +'; position: relative;'" @mouseenter="handleSectionOneTitleOneCustomizationShow"  @mouseleave="handleSectionOneTitleOneCustomizationClose">

                                                                                    <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneTitleOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <p v-if="titleOneStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                        <input type="text" v-model="template.welcome.titleOne.text" 
                                                                                        :style="'width: 100%; border: none; background: transparent; text-align: '+template.welcome.titleOne.align+'; mso-line-height-alt: 24px; font-size: '+template.welcome.titleOne.fontSize+'px;  font-weight: bold; padding-top: '+template.welcome.titleOne.paddingTop+'px; padding-right: '+template.welcome.titleOne.paddingRight+'px; padding-bottom: '+template.welcome.titleOne.paddingBottom+'px; padding-left: '+template.welcome.titleOne.paddingLeft+'px; margin-top: '+template.welcome.titleOne.marginTop+'px; margin-right: '+template.welcome.titleOne.marginRight+'px;margin-bottom: '+template.welcome.titleOne.marginBottom+'px; margin-left: '+template.welcome.titleOne.marginLeft+'px;'"
                                                                                        @blur="handleTitleOneTextClose">
                                                                                    </p>
                                                                                    <p v-else @click="handleTitleOneTextShow" :style="'margin: 0; font-size: 16px; text-align: '+template.welcome.titleOne.align+'; mso-line-height-alt: 24px;padding-top: '+template.welcome.titleOne.paddingTop+'px; padding-right: '+template.welcome.titleOne.paddingRight+'px; padding-bottom: '+template.welcome.titleOne.paddingBottom+'px; padding-left: '+template.welcome.titleOne.paddingLeft+'px; margin-top: '+template.welcome.titleOne.marginTop+'px; margin-right: '+template.welcome.titleOne.marginRight+'px;margin-bottom: '+template.welcome.titleOne.marginBottom+'px; margin-left: '+template.welcome.titleOne.marginLeft+'px;'"><strong><span :style="'font-size:'+template.welcome.titleOne.fontSize+'px; color: '+template.welcome.titleOne.color+';'">{{ template.welcome.titleOne.text }} </span></strong></p>
                                                                                    <p :style="'margin: 0; font-size: 16px; mso-line-height-alt: 24px;'">
                                                                                        <strong style="position: relative;" @mouseenter="handleSectionOneTitleTwoCustomizationShow"  @mouseleave="handleSectionOneTitleTwoCustomizationClose">

                                                                                            <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneTitleTwoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                            <span v-if="titleTwoStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                                <input type="text" v-model="template.welcome.titleTwo.text" 
                                                                                                :style="'width: 100%; font-size: '+template.welcome.titleTwo.fontSize+'px; border: none; background: transparent; text-align: '+template.welcome.titleTwo.align+'; font-weight: bold; padding-top: '+template.welcome.titleTwo.paddingTop+'px; padding-right: '+template.welcome.titleTwo.paddingRight+'px; padding-bottom: '+template.welcome.titleTwo.paddingBottom+'px; padding-left: '+template.welcome.titleTwo.paddingLeft+'px; margin-top: '+template.welcome.titleTwo.marginTop+'px; margin-right: '+template.welcome.titleTwo.marginRight+'px;margin-bottom: '+template.welcome.titleTwo.marginBottom+'px; margin-left: '+template.welcome.titleTwo.marginLeft+'px;'"
                                                                                                @blur="handleTitleTwoTextClose">
                                                                                            </span>
                                                                                            <span v-else @click="handleTitleTwoTextShow" :style="'display: block; text-align: '+template.welcome.titleTwo.align+'; font-size:'+template.welcome.titleTwo.fontSize+'px; color: '+template.welcome.titleTwo.color+'; padding-top: '+template.welcome.titleTwo.paddingTop+'px; padding-right: '+template.welcome.titleTwo.paddingRight+'px; padding-bottom: '+template.welcome.titleTwo.paddingBottom+'px; padding-left: '+template.welcome.titleTwo.paddingLeft+'px; margin-top: '+template.welcome.titleTwo.marginTop+'px; margin-right: '+template.welcome.titleTwo.marginRight+'px;margin-bottom: '+template.welcome.titleTwo.marginBottom+'px; margin-left: '+template.welcome.titleTwo.marginLeft+'px;'">{{ template.welcome.titleTwo.text }} </span>
                                                                                        </strong>
                                                                                        <strong style="position: relative;" @mouseenter="handleSectionOneTitleThreeCustomizationShow"  @mouseleave="handleSectionOneTitleThreeCustomizationClose">

                                                                                            <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneTitleThreeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                            <span v-if="titleThreeStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                                <input type="text" v-model="template.welcome.titleThree.text" 
                                                                                                :style="'width: 100%; font-size: '+template.welcome.titleThree.fontSize+'px; border: none; background: transparent; text-align: '+template.welcome.titleThree.align+'; font-weight: bold; padding-top: '+template.welcome.titleThree.paddingTop+'px; padding-right: '+template.welcome.titleThree.paddingRight+'px; padding-bottom: '+template.welcome.titleThree.paddingBottom+'px; padding-left: '+template.welcome.titleThree.paddingLeft+'px; margin-top: '+template.welcome.titleThree.marginTop+'px; margin-right: '+template.welcome.titleThree.marginRight+'px;margin-bottom: '+template.welcome.titleThree.marginBottom+'px; margin-left: '+template.welcome.titleThree.marginLeft+'px;'"
                                                                                                @blur="handleTitleThreeTextClose">
                                                                                            </span>
                                                                                            <span v-else @click="handleTitleThreeTextShow" :style="'display: block; text-align: '+template.welcome.titleThree.align+'; font-size:'+template.welcome.titleThree.fontSize+'px; color: '+template.welcome.titleThree.color+'; padding-top: '+template.welcome.titleThree.paddingTop+'px; padding-right: '+template.welcome.titleThree.paddingRight+'px; padding-bottom: '+template.welcome.titleThree.paddingBottom+'px; padding-left: '+template.welcome.titleThree.paddingLeft+'px; margin-top: '+template.welcome.titleThree.marginTop+'px; margin-right: '+template.welcome.titleThree.marginRight+'px;margin-bottom: '+template.welcome.titleThree.marginBottom+'px; margin-left: '+template.welcome.titleThree.marginLeft+'px;'"> {{ ' '+ template.welcome.titleThree.text }}</span>
                                                                                        </strong>
                                                                                    </p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:65px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 14.399999999999999px; line-height: 1.2; font-family: '+ template.welcome.background.fontFamily +'; position: relative;'" @mouseenter="handleSectionOneParagraphCustomizationShow"  @mouseleave="handleSectionOneParagraphCustomizationClose">

                                                                                    <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneParagraphCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <p :style="'margin: 0; font-size: 14px; text-align: '+template.welcome.paragraph.align+'; mso-line-height-alt: 16.8px;'">
                                                                                        <span v-if="welcomeParagraphStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                            <input type="text" v-model="template.welcome.paragraph.text" 
                                                                                            :style="'width: 100%; font-size: '+template.welcome.paragraph.fontSize+'px; border: none; background: transparent; text-align: '+template.welcome.paragraph.align+'; padding-top: '+template.welcome.paragraph.paddingTop+'px; padding-right: '+template.welcome.paragraph.paddingRight+'px; padding-bottom: '+template.welcome.paragraph.paddingBottom+'px; padding-left: '+template.welcome.paragraph.paddingLeft+'px; margin-top: '+template.welcome.paragraph.marginTop+'px; margin-right: '+template.welcome.paragraph.marginRight+'px;margin-bottom: '+template.welcome.paragraph.marginBottom+'px; margin-left: '+template.welcome.paragraph.marginLeft+'px; '"
                                                                                            @blur="handleWelcomeParagraphTextClose">
                                                                                        </span>
                                                                                        <span v-else @click="handleWelcomeParagraphTextShow" :style="'display: block; font-size:'+template.welcome.paragraph.fontSize+'px; color: '+template.welcome.paragraph.color+'; padding-top: '+template.welcome.paragraph.paddingTop+'px; padding-right: '+template.welcome.paragraph.paddingRight+'px; padding-bottom: '+template.welcome.paragraph.paddingBottom+'px; padding-left: '+template.welcome.paragraph.paddingLeft+'px; margin-top: '+template.welcome.paragraph.marginTop+'px; margin-right: '+template.welcome.paragraph.marginRight+'px;margin-bottom: '+template.welcome.paragraph.marginBottom+'px; margin-left: '+template.welcome.paragraph.marginLeft+'px;'">{{ template.welcome.paragraph.text }}</span>
                                                                                    </p>
                                                                                </div> 
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-6" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-left:20px;padding-right:20px;padding-top:35px;" >
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 18px; color: #191919; line-height: 1.5; font-family: '+template.sectionTwo.fontFamily+'; position: relative;'" @mouseenter="handleSectionTwoTitleCustomizationShow"  @mouseleave="handleSectionTwoTitleCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleSectionTwoTitleCustomizationAction"><i class='bx bxs-edit-alt'></i></span> 

                                                                                    <p v-if="sectionTwoTitleStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                        <input type="text" v-model="template.sectionTwo.title.text" 
                                                                                        :style="'width: 100%; border: none; background: transparent; text-align: '+template.sectionTwo.title.align+'; mso-line-height-alt: 42px; font-size: '+template.sectionTwo.title.fontSize+'px; font-weight: bold; padding-top: '+template.sectionTwo.title.paddingTop+'px; padding-right: '+template.sectionTwo.title.paddingRight+'px; padding-bottom: '+template.sectionTwo.title.paddingBottom+'px; padding-left: '+template.sectionTwo.title.paddingLeft+'px; margin-top: '+template.sectionTwo.title.marginTop+'px; margin-right: '+template.sectionTwo.title.marginRight+'px;margin-bottom: '+template.sectionTwo.title.marginBottom+'px; margin-left: '+template.sectionTwo.title.marginLeft+'px;'"
                                                                                        @blur="handleSectionTwoTitleTextClose">
                                                                                    </p>
                                                                                    <p v-else @click="handleSectionTwoTitleTextShow" :style="'margin: 0; font-size: 16px; text-align: '+template.sectionTwo.title.align+'; mso-line-height-alt: 42px;'" >
                                                                                        <span :style="'display: block; font-size:'+template.sectionTwo.title.fontSize+'px; color: '+template.sectionTwo.title.color+'; padding-top: '+template.sectionTwo.title.paddingTop+'px; padding-right: '+template.sectionTwo.title.paddingRight+'px; padding-bottom: '+template.sectionTwo.title.paddingBottom+'px; padding-left: '+template.sectionTwo.title.paddingLeft+'px; margin-top: '+template.sectionTwo.title.marginTop+'px; margin-right: '+template.sectionTwo.title.marginRight+'px;margin-bottom: '+template.sectionTwo.title.marginBottom+'px; margin-left: '+template.sectionTwo.title.marginLeft+'px;'"><strong><span style="">{{ template.sectionTwo.title.text }}</span></strong></span></p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="5" cellspacing="0" class="divider_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" :style="'padding-left:20px;padding-right:20px;'">
                                                                                <div :align="template.sectionTwo.title.align" class="alignment">
                                                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="15%">
                                                                                    <tr>
                                                                                        <td class="divider_inner" :style="'display: block; width: 104px; font-size: 1px; line-height: 1px; border-top: 2px solid '+template.welcome.background.bgColor+';'"><span> </span></td>
                                                                                    </tr>
                                                                                </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="divider_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" :style="'padding-left:20px;padding-right:20px;'">
                                                                                <div :align="template.sectionTwo.title.align" class="alignment">
                                                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="5%">
                                                                                    <tr>
                                                                                        <td class="divider_inner" :style="'display: block; width: 35px; font-size: 1px; line-height: 1px; border-top: 2px solid '+template.welcome.background.bgColor+';'"><span> </span></td>
                                                                                    </tr>
                                                                                </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-7" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="66.66666666666667%">
                                                                    <div class="spacer_block" style="height:5px;line-height:5px;font-size:1px;"> </div>
                                                                    <div class="spacer_block mobile_hide" style="height:20px;line-height:20px;font-size:1px;"> </div>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:10px;padding-left:40px;padding-right:10px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 24px; color: #34495e; line-height: 2; font-family: '+template.sectionTwo.fontFamily+'; position: relative;'" @mouseenter="handleSectionTwoSubTitleCustomizationShow"  @mouseleave="handleSectionTwoSubTitleCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleSectionTwoSubTitleCustomizationAction"><i class='bx bxs-edit-alt'></i></span> 

                                                                                    <p v-if="sectionTwoSubTitleStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                        <input type="text" v-model="template.sectionTwo.subTitle.text" 
                                                                                        :style="'width: 100%; border: none; background: transparent; text-align: '+template.sectionTwo.subTitle.align+'; mso-line-height-alt: 40px; font-size: '+template.sectionTwo.subTitle.fontSize+'px; font-weight: bold; color: '+template.sectionTwo.subTitle.color+' !important; padding-top: '+template.sectionTwo.subTitle.paddingTop+'px; padding-right: '+template.sectionTwo.subTitle.paddingRight+'px; padding-bottom: '+template.sectionTwo.subTitle.paddingBottom+'px; padding-left: '+template.sectionTwo.subTitle.paddingLeft+'px; margin-top: '+template.sectionTwo.subTitle.marginTop+'px; margin-right: '+template.sectionTwo.subTitle.marginRight+'px;margin-bottom: '+template.sectionTwo.subTitle.marginBottom+'px; margin-left: '+template.sectionTwo.subTitle.marginLeft+'px;'"
                                                                                        @blur="handleSectionTwoSubTitleTextClose">
                                                                                    </p>
                                                                                    <p v-else @click="handleSectionTwoSubTitleTextShow" :style="'margin: 0; font-size: 14px; text-align: '+template.sectionTwo.subTitle.align+'; mso-line-height-alt: 40px;'"><span :style="'display: block; font-size:'+template.sectionTwo.subTitle.fontSize+'px; color: '+template.sectionTwo.subTitle.color+'; padding-top: '+template.sectionTwo.subTitle.paddingTop+'px; padding-right: '+template.sectionTwo.subTitle.paddingRight+'px; padding-bottom: '+template.sectionTwo.subTitle.paddingBottom+'px; padding-left: '+template.sectionTwo.subTitle.paddingLeft+'px; margin-top: '+template.sectionTwo.subTitle.marginTop+'px; margin-right: '+template.sectionTwo.subTitle.marginRight+'px;margin-bottom: '+template.sectionTwo.subTitle.marginBottom+'px; margin-left: '+template.sectionTwo.subTitle.marginLeft+'px;'"><strong><span style="">{{ template.sectionTwo.subTitle.text }}</span></strong></span></p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:10px;padding-left:40px;padding-right:30px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 24px; color: #555555; line-height: 2; font-family: '+template.sectionTwo.fontFamily+'; position: relative;'" @mouseenter="handleSectionTwoParagraphCustomizationShow"  @mouseleave="handleSectionTwoParagraphCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleSectionTwoParagraphCustomizationAction"><i class='bx bxs-edit-alt'></i></span> 

                                                                                    <p v-if="sectionTwoParagraphStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                        <textarea type="text" v-model="template.sectionTwo.paragraph.text" 
                                                                                        :style="'width: 100%; border: none; background: transparent; text-align: '+template.sectionTwo.paragraph.align+'; mso-line-height-alt: 28px; font-size: '+template.sectionTwo.paragraph.fontSize+'px; color: '+template.sectionTwo.paragraph.color+' !important; padding-top: '+template.sectionTwo.paragraph.paddingTop+'px; padding-right: '+template.sectionTwo.paragraph.paddingRight+'px; padding-bottom: '+template.sectionTwo.paragraph.paddingBottom+'px; padding-left: '+template.sectionTwo.paragraph.paddingLeft+'px; margin-top: '+template.sectionTwo.paragraph.marginTop+'px; margin-right: '+template.sectionTwo.paragraph.marginRight+'px;margin-bottom: '+template.sectionTwo.paragraph.marginBottom+'px; margin-left: '+template.sectionTwo.paragraph.marginLeft+'px;'"
                                                                                        @blur="handleSectionTwoParagraphTextClose"></textarea>
                                                                                    </p>
                                                                                    <p v-else @click="handleSectionTwoParagraphTextShow" :style="'margin: 0; font-size: '+template.sectionTwo.paragraph.fontSize+'px; text-align: '+template.sectionTwo.paragraph.align+'; mso-line-height-alt: 28px; color: '+template.sectionTwo.paragraph.color+'; padding-top: '+template.sectionTwo.paragraph.paddingTop+'px; padding-right: '+template.sectionTwo.paragraph.paddingRight+'px; padding-bottom: '+template.sectionTwo.paragraph.paddingBottom+'px; padding-left: '+template.sectionTwo.paragraph.paddingLeft+'px; margin-top: '+template.sectionTwo.paragraph.marginTop+'px; margin-right: '+template.sectionTwo.paragraph.marginRight+'px;margin-bottom: '+template.sectionTwo.paragraph.marginBottom+'px; margin-left: '+template.sectionTwo.paragraph.marginLeft+'px;'">{{ template.sectionTwo.paragraph.text }}</p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:15px;padding-left:45px;padding-right:10px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 24px; color: #a96b7d; line-height: 2; font-family: '+template.sectionTwo.fontFamily+'; position: relative;'" @mouseenter="handleSectionTwoButtonCustomizationShow"  @mouseleave="handleSectionTwoButtonCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleSectionTwoButtonCustomizationAction"><i class='bx bxs-edit-alt'></i></span> 

                                                                                    <p :style="'margin: 0; font-size: 14px; text-align: '+template.sectionTwo.button.align+'; mso-line-height-alt: 28px;'"><span :style="'display: block; font-size:'+template.sectionTwo.button.fontSize+'px; padding-top: '+template.sectionTwo.button.paddingTop+'px; padding-right: '+template.sectionTwo.button.paddingRight+'px; padding-bottom: '+template.sectionTwo.button.paddingBottom+'px; padding-left: '+template.sectionTwo.button.paddingLeft+'px; margin-top: '+template.sectionTwo.button.marginTop+'px; margin-right: '+template.sectionTwo.button.marginRight+'px;margin-bottom: '+template.sectionTwo.button.marginBottom+'px; margin-left: '+template.sectionTwo.button.marginLeft+'px;'"><strong><span style=""><span style=""><a :href="template.sectionTwo.button.link" :style="'color: '+template.sectionTwo.button.color+';'">{{ template.sectionTwo.button.text }}</a></span></span></strong></span></p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                    <td class="column column-2" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="33.333333333333336%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-right:40px;width:100%;padding-left:0px;padding-top:5px;padding-bottom:5px; position: relative;" @mouseenter="handleSectionTwoImageCustomizationShow"  @mouseleave="handleSectionTwoImageCustomizationClose">

                                                                                <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleSectionTwoImageCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <div :align="template.sectionTwo.image.align" class="alignment" style="line-height:10px"><img alt="I'm an image" :src="template.sectionTwo.image.url" :style="'display: block; height: '+template.sectionTwo.image.height+'px; border: '+template.sectionTwo.image.borderSize+'px '+template.sectionTwo.image.borderStyle+' '+template.sectionTwo.image.borderColor+'; width: '+template.sectionTwo.image.width+'px; max-width: 100%; object-fit: '+template.sectionTwo.image.objectFit+'; padding-top: '+template.sectionTwo.image.paddingTop+'px; padding-right: '+template.sectionTwo.image.paddingRight+'px; padding-bottom: '+template.sectionTwo.image.paddingBottom+'px; padding-left: '+template.sectionTwo.image.paddingLeft+'px; margin-top: '+template.sectionTwo.image.marginTop+'px; margin-right: '+template.sectionTwo.image.marginRight+'px;margin-bottom: '+template.sectionTwo.image.marginBottom+'px; margin-left: '+template.sectionTwo.image.marginLeft+'px;'" title="I'm an image" width="193"/></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-8" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="padding-bottom:10px;padding-left:40px;padding-right:40px;padding-top:40px;">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; mso-line-height-alt: 24px; color: #555555; line-height: 2; font-family: '+template.footer.fontFamily+'; position: relative;'" @mouseenter="handleFooterParagraphCustomizationShow"  @mouseleave="handleFooterParagraphCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleFooterParagraphCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <p v-if="footerParagraphStatus" style="display: flex; justify-content: center; margin-bottom: 0px;">
                                                                                        <textarea type="text" v-model="template.footer.paragraph.text" 
                                                                                        :style="'width: 100%; border: none; background: transparent; text-align: '+template.footer.paragraph.align+'; mso-line-height-alt: 28px; font-size: '+template.footer.paragraph.fontSize+'px; color: '+template.footer.paragraph.color+'; padding-top: '+template.footer.paragraph.paddingTop+'px; padding-right: '+template.footer.paragraph.paddingRight+'px; padding-bottom: '+template.footer.paragraph.paddingBottom+'px; padding-left: '+template.footer.paragraph.paddingLeft+'px; margin-top: '+template.footer.paragraph.marginTop+'px; margin-right: '+template.footer.paragraph.marginRight+'px;margin-bottom: '+template.footer.paragraph.marginBottom+'px; margin-left: '+template.footer.paragraph.marginLeft+'px;'"
                                                                                        @blur="handleFooterParagraphTextClose"></textarea>
                                                                                    </p>
                                                                                    <p v-else @click="handleFooterParagraphTextShow" :style="'margin: 0; font-size: '+template.footer.paragraph.fontSize+'px; text-align: '+template.footer.paragraph.align+'; mso-line-height-alt: 28px; color: '+template.footer.paragraph.color+'; padding-top: '+template.footer.paragraph.paddingTop+'px; padding-right: '+template.footer.paragraph.paddingRight+'px; padding-bottom: '+template.footer.paragraph.paddingBottom+'px; padding-left: '+template.footer.paragraph.paddingLeft+'px; margin-top: '+template.footer.paragraph.marginTop+'px; margin-right: '+template.footer.paragraph.marginRight+'px;margin-bottom: '+template.footer.paragraph.marginBottom+'px; margin-left: '+template.footer.paragraph.marginLeft+'px;'">  {{ template.footer.paragraph.text }} 

                                                                                        <span style="position: relative;" @mouseenter="handleFooterParagraphEmailCustomizationShow"  @mouseleave="handleFooterParagraphEmailCustomizationClose">
                                                                                            <span style="display: none;position: absolute;top: -10px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleFooterParagraphEmailCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                            <a :href="template.footer.paragraph.email.link" rel="noopener" :style="'text-decoration: none; color: '+template.footer.paragraph.email.color+';'" target="_blank">{{ template.footer.paragraph.email.text }} </a>
                                                                                        </span>    
                                                                                         or 

                                                                                        <span style="position: relative;" @mouseenter="handleFooterParagraphUnsubscribeCustomizationShow"  @mouseleave="handleFooterParagraphUnsubscribeCustomizationClose">

                                                                                            <span style="display: none;position: absolute;top: -10px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleFooterParagraphUnsubscribeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                            <a :href="template.footer.paragraph.unsubscribe.link" rel="noopener" :style="'text-decoration: none; color: '+template.footer.paragraph.unsubscribe.color+';'" target="_blank">{{ template.footer.paragraph.unsubscribe.text }}</a>.
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-9" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="10" cellspacing="0" class="text_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                                        <tr>
                                                                            <td class="pad">
                                                                                <div style="font-family: sans-serif">
                                                                                <div class="" :style="'font-size: 12px; font-family: '+template.footer.fontFamily+'; mso-line-height-alt: 14.399999999999999px; color: '+template.footer.termsPolicy.color+'; line-height: 1.2; position: relative;'" @mouseenter="handleTermsPolicyCustomizationShow"  @mouseleave="handleTermsPolicyCustomizationClose">

                                                                                    <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleTermsPolicyCustomizationAction"><i class='bx bxs-edit-alt'></i></span>
                                                                                    
                                                                                    <p :style="'margin: 0; font-size: '+template.footer.termsPolicy.fontSize+'px; text-align: '+template.footer.termsPolicy.align+'; mso-line-height-alt: 14.399999999999999px; color: '+template.footer.termsPolicy.color+'; padding-top: '+template.footer.termsPolicy.paddingTop+'px; padding-right: '+template.footer.termsPolicy.paddingRight+'px; padding-bottom: '+template.footer.termsPolicy.paddingBottom+'px; padding-left: '+template.footer.termsPolicy.paddingLeft+'px; margin-top: '+template.footer.termsPolicy.marginTop+'px; margin-right: '+template.footer.termsPolicy.marginRight+'px;margin-bottom: '+template.footer.termsPolicy.marginBottom+'px; margin-left: '+template.footer.termsPolicy.marginLeft+'px;'">
                                                                                        <span>
                                                                                            <span v-if="footerTermsTextStatus">
                                                                                            <input type="text" v-model="template.footer.termsPolicy.termsText" 
                                                                                                :style="'width: 20%; border: none; background: transparent; text-align: center;'"
                                                                                                @blur="handleFooterTermsTextClose">
                                                                                            </span>
                                                                                            <span v-else @click="handleFooterTermsTextShow">
                                                                                            {{ template.footer.termsPolicy.termsText }} 
                                                                                            </span>
                                                                                            
                                                                                            <strong> | </strong> 

                                                                                            <span v-if="footerPolicyTextStatus">
                                                                                            <input type="text" v-model="template.footer.termsPolicy.policyText" 
                                                                                                :style="'width: 20%; border: none; background: transparent; text-align: center;'"
                                                                                                @blur="handleFooterPolicyTextClose">
                                                                                            </span>
                                                                                            <span v-else @click="handleFooterPolicyTextShow">
                                                                                            {{ template.footer.termsPolicy.policyText }}
                                                                                            </span>
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-10" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
                                                                                <div align="center" class="alignment" style="line-height:10px"><img alt="Alternate text" class="big" src="https://res.cloudinary.com/deist90as/image/upload/v1681228073/white_down_g8qh7t.png" :style="'display: block; height: '+template.copyRight.whiteDown.height+'px; border: 0; width: '+template.copyRight.whiteDown.width+'px; max-width: 100%;'" title="Alternate text" :width="template.copyRight.whiteDown.width"/></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-11" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;" width="700">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="icons_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                        <tr>
                                                                            <td class="pad" style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">
                                                                                <table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                                <tr>
                                                                                    <td class="alignment" :style="'vertical-align: middle; text-align: '+template.copyRight.align+'; position: relative;'" @mouseenter="handleCopyrightCustomizationShow"  @mouseleave="handleCopyrightCustomizationClose">

                                                                                        <span style="display: none;position: absolute;top: 0px;right: 0px; font-size: 20px; cursor: pointer;" @click="handleCopyrightCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                        <!--[if vml]>
                                                                                        <table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;">
                                                                                            <![endif]-->
                                                                                            <!--[if !vml]><!-->
                                                                                            <table cellpadding="0" cellspacing="0" class="icons-inner" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                                                                            <!--<![endif]-->
                                                                                            <tr>
                                                                                                <td :style="'vertical-align: middle; text-align: '+template.copyRight.align+'; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;'"><a :href="template.copyRight.copyRightText.link" :style="'display: block; text-decoration: none; text-align: '+template.copyRight.align+' padding-top: '+template.copyRight.paddingTop+'px; padding-right: '+template.copyRight.paddingRight+'px; padding-bottom: '+template.copyRight.paddingBottom+'px; padding-left: '+template.copyRight.paddingLeft+'px; margin-top: '+template.copyRight.marginTop+'px; margin-right: '+template.copyRight.marginRight+'px;margin-bottom: '+template.copyRight.marginBottom+'px; margin-left: '+template.copyRight.marginLeft+'px;'" target="_blank"><img align="center" alt="Designed with BEE" class="icon" :height="template.copyRight.copyRightImage.height" :src="template.copyRight.copyRightImage.url" :style="'display: block; height: '+template.copyRight.copyRightImage.height+'; margin: 0 auto; border: 0;'" :width="template.copyRight.copyRightImage.width"/></a></td>
                                                                                                <td :style="'font-family: '+template.copyRight.fontFamily+'; font-size: '+template.copyRight.copyRightText.fontSize+'px; color: '+template.copyRight.copyRightText.color+'; vertical-align: middle; letter-spacing: undefined; text-align: '+template.copyRight.align+';'"><a :href="template.copyRight.copyRightText.link" :style="'color: '+template.copyRight.copyRightText.color+'; text-decoration: '+template.copyRight.copyRightText.textDecoration+';'" target="_blank">{{ template.copyRight.copyRightText.text }}</a></td>
                                                                                            </tr>
                                                                                            </table>
                                                                                            </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        <!-- End -->
                      </div>

                    
                  </div>
                  <div class="mb-3" style="text-align: center;">
                        <button v-on:click="publishUpdateTemplate" type="button" class="btn btn-primary">Publish Updated Template</button> 
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6" style="position: absolute; right: 0px; top: 0px; max-height: 1300px; overflow: auto;">
                    <div class="border border-3 p-4 rounded my-2">
                        <code v-for="(level, index) in templateData" :key="index">{{ level.emailKeywordLabel }}</code>
                        <hr />
                        <h6>How to use:</h6>
                        <p>Hi Mr <code style="color:black;">[FullName]</code>, Nice to meet you.</p>
                        
                    </div>
                    
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--start background switcher-->
  <div class="switcher-wrapper background-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Background Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color:" v-model="template.logo.backgroundColor" :clearable="true"
                                                    :bottomBar="true" :colors="colors" style="width: 100%" />
            </div>
        </div>
  </div>
  <!--end background switcher-->

  <!--start logo switcher-->
  <div class="switcher-wrapper logo-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Logo Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="inputProductTitle" class="form-label" style="text-align:left;">Logo Image: <br /><strong>[Image Size: 200 X 66, Max: 200 KB]</strong></label>
                <input class="form-control mb-1" type="file" ref="backgroundImage" accept="image/*" @change="uploadFeaturedImage" />
                <div class="logo-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.logo.width }}] px</span></div>
                    <input v-model="template.logo.width" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.logo.height }}] px</span></div>
                    <input v-model="template.logo.height" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-object-fit" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="left"><input v-model="template.logo.align" type="radio" name="align" id="left" value="left"> Left</label>&nbsp;
                    <label for="center"><input v-model="template.logo.align" type="radio" name="align" id="center" value="center"> Center</label>&nbsp;
                    <label for="right"><input v-model="template.logo.align" type="radio" name="align" id="right" value="right"> Right</label>
                </div>
                
                <div class="logo-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span>
                    <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>

                    <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                        <span style="width: 100%;">Border Style: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.borderStyle" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.logo.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                    
                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                    

                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                </div>
            </div>
        </div>
  </div>
  <!--end logo switcher-->

  <!--start section one switcher-->
  <div class="switcher-wrapper section-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.welcome.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />
                <LvColorpicker label="Text Color: " v-model="template.welcome.background.textColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />

                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%; display: block;">Font Family:</span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.welcome.background.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.welcome.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
            </div>
        </div>
  </div>
  <!--end section one switcher-->

  <!--start section one image switcher-->
  <div class="switcher-wrapper section-one-image-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section One Image Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="sectionTwoImage" class="form-label" style="text-align:left; margin-top: 10px;">Image: <br /><strong>[Image Size: 200 X 200, Max: 200 KB]</strong></label>
                    <input class="form-control mb-1" type="file" ref="sectionTwoImage" accept="image/*" @change="uploadSectionTwoImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.welcome.image.width }}] px</span></div>
                    <input v-model="template.welcome.image.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.welcome.image.height }}] px</span></div>
                    <input v-model="template.welcome.image.height" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSO"><input v-model="template.welcome.image.align" type="radio" name="align" id="leftSO" value="left"> Left</label>&nbsp;
                    <label for="centerSO"><input v-model="template.welcome.image.align" type="radio" name="align" id="centerSO" value="center"> Center</label>&nbsp;
                    <label for="rightSO"><input v-model="template.welcome.image.align" type="radio" name="align" id="rightSO" value="right"> Right</label>
                </div>

                <div class="section-image-height" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.welcome.image.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-image-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 10px;">
                        <span style="width: 100%;">Border Style:</span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.welcome.image.borderStyle" class="form-control" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.welcome.image.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.image.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one image switcher-->

  <!--start section one title one switcher-->
  <div class="switcher-wrapper section-one-title-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.welcome.titleOne.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color:" v-model="template.welcome.titleOne.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTO"><input v-model="template.welcome.titleOne.align" type="radio" name="align" id="leftSTO" value="left"> Left</label>&nbsp;
                    <label for="centerSTO"><input v-model="template.welcome.titleOne.align" type="radio" name="align" id="centerSTO" value="center"> Center</label>&nbsp;
                    <label for="rightSTO"><input v-model="template.welcome.titleOne.align" type="radio" name="align" id="rightSTO" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleOne.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one title one switcher-->

  <!--start section one title two switcher-->
  <div class="switcher-wrapper section-one-title-two-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Two Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-Two" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.welcome.titleTwo.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color" v-model="template.welcome.titleTwo.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTT"><input v-model="template.welcome.titleTwo.align" type="radio" name="align" id="leftSTT" value="left"> Left</label>&nbsp;
                    <label for="centerSTT"><input v-model="template.welcome.titleTwo.align" type="radio" name="align" id="centerSTT" value="center"> Center</label>&nbsp;
                    <label for="rightSTT"><input v-model="template.welcome.titleTwo.align" type="radio" name="align" id="rightSTT" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleTwo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one title two switcher-->

  <!--start section one title three switcher-->
  <div class="switcher-wrapper section-one-title-three-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Three Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-Three" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.welcome.titleThree.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color: " v-model="template.welcome.titleThree.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                    <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTTh"><input v-model="template.welcome.titleThree.align" type="radio" name="align" id="leftSTTh" value="left"> Left</label>&nbsp;
                    <label for="centerSTTh"><input v-model="template.welcome.titleThree.align" type="radio" name="align" id="centerSTTh" value="center"> Center</label>&nbsp;
                    <label for="rightSTTh"><input v-model="template.welcome.titleThree.align" type="radio" name="align" id="rightSTTh" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.titleThree.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one title three switcher-->

  <!--start section one paragraph switcher-->
  <div class="switcher-wrapper section-one-paragraph-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Paragraph Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-paragraph" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.welcome.paragraph.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color" v-model="template.welcome.paragraph.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTP"><input v-model="template.welcome.paragraph.align" type="radio" name="align" id="leftSTP" value="left"> Left</label>&nbsp;
                    <label for="centerSTP"><input v-model="template.welcome.paragraph.align" type="radio" name="align" id="centerSTP" value="center"> Center</label>&nbsp;
                    <label for="rightSTP"><input v-model="template.welcome.paragraph.align" type="radio" name="align" id="rightSTP" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.welcome.paragraph.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one paragraph switcher-->

  <!--start section two title switcher-->
  <div class="switcher-wrapper section-two-title-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Two Title Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionTwo.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px">Title: </label> <br />
                <hr style="margin: 0px;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionTwo.title.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color: " v-model="template.sectionTwo.title.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTTr"><input v-model="template.sectionTwo.title.align" type="radio" name="align" id="leftSTTr" value="left"> Left</label>&nbsp;
                    <label for="centerSTTr"><input v-model="template.sectionTwo.title.align" type="radio" name="align" id="centerSTTr" value="center"> Center</label>&nbsp;
                    <label for="rightSTTr"><input v-model="template.sectionTwo.title.align" type="radio" name="align" id="rightSTTr" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.title.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section two title switcher-->

  <!--start section two subtitle switcher-->
  <div class="switcher-wrapper section-two-subtitle-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Two Sub-Title Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionTwo.subTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color" v-model="template.sectionTwo.subTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTSTr"><input v-model="template.sectionTwo.subTitle.align" type="radio" name="align" id="leftSTSTr" value="left"> Left</label>&nbsp;
                    <label for="centerSTSTr"><input v-model="template.sectionTwo.subTitle.align" type="radio" name="align" id="centerSTSTr" value="center"> Center</label>&nbsp;
                    <label for="rightSTSTr"><input v-model="template.sectionTwo.subTitle.align" type="radio" name="align" id="rightSTSTr" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.subTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section two subtitle switcher-->

  <!--start section two paragraph switcher-->
  <div class="switcher-wrapper section-two-paragraph-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Two Paragraph Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionTwo.paragraph.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color: " v-model="template.sectionTwo.paragraph.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTr"><input v-model="template.sectionTwo.paragraph.align" type="radio" name="align" id="leftSTPTr" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTr"><input v-model="template.sectionTwo.paragraph.align" type="radio" name="align" id="centerSTSPTr" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTr"><input v-model="template.sectionTwo.paragraph.align" type="radio" name="align" id="rightSTSPTr" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.paragraph.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section two paragraph switcher-->

  <!--start section two button switcher-->
  <div class="switcher-wrapper section-two-button-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Two Button Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one my-3" style="margin-top: 6px;">
                    Text: 
                    <input v-model="template.sectionTwo.button.text" type="text" class="form-control"  /><br/>
                    Link: 
                    <input v-model="template.sectionTwo.button.link" type="text" class="form-control" />
                </div>
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionTwo.button.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                    <LvColorpicker label="Color: " v-model="template.sectionTwo.button.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTbtn"><input v-model="template.sectionTwo.button.align" type="radio" name="align" id="leftSTPTbtn" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTbtn"><input v-model="template.sectionTwo.button.align" type="radio" name="align" id="centerSTSPTbtn" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTbtn"><input v-model="template.sectionTwo.button.align" type="radio" name="align" id="rightSTSPTbtn" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.button.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section two button switcher-->

  <!--start section two image switcher-->
  <div class="switcher-wrapper section-two-image-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Two Image Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <input class="form-control mb-1" type="file" ref="sectionThreeSideImage" accept="image/*" @change="uploadSectionThreeSideImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.sectionTwo.image.width }}] px</span></div>
                    <input v-model="template.sectionTwo.image.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.sectionTwo.image.height }}] px</span></div>
                    <input v-model="template.sectionTwo.image.height" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-image-height" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionTwo.image.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-image-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Border Style</span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionTwo.image.borderStyle" class="form-control" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.sectionTwo.image.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTimg"><input v-model="template.sectionTwo.image.align" type="radio" name="align" id="leftSTPTimg" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTimg"><input v-model="template.sectionTwo.image.align" type="radio" name="align" id="centerSTSPTimg" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTimg"><input v-model="template.sectionTwo.image.align" type="radio" name="align" id="rightSTSPTbtn" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionTwo.image.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section two image switcher-->

  <!--start footer paragraph switcher-->
  <div class="switcher-wrapper footer-paragraph-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Footer Paragraph Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.footer.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px">Paragraph: </label> <br />
                <hr style="margin: 0px;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.footer.paragraph.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker          label="Color: " v-model="template.footer.paragraph.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTFP"><input v-model="template.footer.paragraph.align" type="radio" name="align" id="leftSTPTFP" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTFP"><input v-model="template.footer.paragraph.align" type="radio" name="align" id="centerSTSPTFP" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTFP"><input v-model="template.footer.paragraph.align" type="radio" name="align" id="rightSTSPTFP" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.paragraph.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end footer paragraph switcher-->

  <!--start footer paragraph email switcher-->
  <div class="switcher-wrapper footer-paragraph-email-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Footer Paragraph Email Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one my-3" style="margin-top: 6px;">
                    Text: 
                    <input v-model="template.footer.paragraph.email.text" type="text" class="form-control"  /><br/>
                    Link: 
                    <input v-model="template.footer.paragraph.email.link" type="text" class="form-control" />
                </div>
                <LvColorpicker label="Color" v-model="template.footer.paragraph.email.color" :clearable="true" :bottomBar="true" :colors="colors" style="width: 100%" />
            </div>
        </div>
  </div>
  <!--end footer paragraph email switcher-->

  <!--start footer paragraph unsubscribe switcher-->
  <div class="switcher-wrapper footer-paragraph-unsubscribe-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Footer Paragraph Unsubscribe Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one my-3" style="margin-top: 6px;">
                    Text: 
                    <input v-model="template.footer.paragraph.unsubscribe.text" type="text" class="form-control" /><br/>
                    Link: 
                    <input v-model="template.footer.paragraph.unsubscribe.link" type="text" class="form-control" />
                </div>
                <LvColorpicker label="Color" v-model="template.footer.paragraph.unsubscribe.color" :clearable="true" :bottomBar="true" :colors="colors" style="width: 100%" />
            </div>
        </div>
  </div>
  <!--end footer paragraph unsubscribe switcher-->

  <!--start terms & privacy policy switcher-->
  <div class="switcher-wrapper terms-policy-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Terms and Privacy Policy Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.footer.termsPolicy.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker label="Color" v-model="template.footer.termsPolicy.color" :clearable="true"
                :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTFPF"><input v-model="template.footer.termsPolicy.align" type="radio" name="align" id="leftSTPTFPF" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTFPF"><input v-model="template.footer.termsPolicy.align" type="radio" name="align" id="centerSTSPTFPF" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTFPF"><input v-model="template.footer.termsPolicy.align" type="radio" name="align" id="rightSTSPTFPF" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.footer.termsPolicy.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end terms & privacy policy switcher-->

  <!--start copyright switcher-->
  <div class="switcher-wrapper copyright-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Copyright Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.copyRight.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <label for="copyRightImage" class="form-label" style="text-align:left; margin-top: 10px;">CopyRight Image: <br /><strong>[Image Size: 34 X 32, Max: 200 KB]</strong></label>
                    <input class="form-control mb-1" type="file" ref="copyRightImage" accept="image/*" @change="uploadCopyRightImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.copyRight.copyRightImage.width }}] px</span></div>
                    <input v-model="template.copyRight.copyRightImage.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.copyRight.copyRightImage.height }}] px</span></div>
                    <input v-model="template.copyRight.copyRightImage.height" type="range" min="10" max="500" class="w-100">
                </div>
                

                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px">Copy Right Text: </label> <br />
                <div class="section-title-one my-3" style="margin-top: 10px;">
                    <input v-model="template.copyRight.copyRightText.text" type="text" class="form-control" />
                </div>
                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px; font-weight: bold;">Copy Right Link: </label> <br />
                <div class="section-title-one">
                    <input v-model="template.copyRight.copyRightText.link" type="text" class="form-control" />
                </div>
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.copyRightText.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.copyRight.copyRightText.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker          label="Color" v-model="template.copyRight.copyRightText.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Text Decoration: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.copyRight.copyRightText.textDecoration" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="none">none</option>
                                <option value="overline">overline</option>
                                <option value="line-through">line-through</option>
                                <option value="underline">underline</option>
                                <option value="underline overline">underline overline</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="leftSTPTFC" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="centerSTSPTFC" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="rightSTSPTFC" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end copyright switcher-->

</template>

<script src="../../../js/elementor/CustomerSignup/CreateCustomerEmailElementor.js"></script>

