import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: []
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Gallery CMS";
        this.setURL = this.$serverURL + this.$api.cms.galleryPageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async update() {
            try {
                let data = {
                    title: this.pageCMSData.title ? this.pageCMSData.title : '',
                    subTitle: this.pageCMSData.subTitle ? this.pageCMSData.subTitle : ''
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.galleryPageCMS,
                    data: data,
                    headers: authHeader()
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}