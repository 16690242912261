import { authHeader } from "../../../../auth";
import { states } from "../../../../../config/states-list";
export default {
    data() {
        return {
            productValue: [],
            cardValue: [],
            selectedProduct: [],
            selectedCard: [],
            productURL: this.$serverURL + this.$api.product.productURL,
            cardURL: this.$serverURL + this.$api.giftCard.giftCardURL,
            smURL: this.$serverURL + this.$api.shippingMethods.requestURL,
            stURL: this.$serverURL + this.$api.salesTax.requestURL,
            addedProductList: [{
                productId: ""
            }],
            addedCardList: [{
                cardId: ""
            }],
            confirmedProduct: [],
            colorValue: [''],
            sizeValue: [''],
            materialValue: [''],
            subTotal: 0,
            grandTotal: 0,
            isExistingUser: null,
            setURL: this.$serverURL + this.$api.user.customerList,
            userList: [],
            userDetails: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            shippingName: "",
            shippingEmail: "",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingContactNo: "",
            shippingCountry: {
                name: 'United States of America',
                code: 'US',
            },
            shippingPostCode: "",
            stateCode: "",
            shippingCity: "",
            remarks: "",
            productList: [],
            cardList: [],
            discount: '',
            total: 0,
            idx: 0,
            card_idx: 0,
            priceList: [],
            cardPriceList:[],
            states: states,
            stateList: [],
            shippingType: "",
            serviceTypeList: [],
            serviceType: "",
            packageType: "",
            shippingRemarks: "",
            totalWeight: 0,
            shippingAddressData: {
            },
            shippingCost: 0,
            cardSubTotal: 0,
            cardTotal: 0,
            grandSubTotal: 0,
            expireDate: "",
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: ""
            },
            smList:[],
            stRate:0,
            stRateTitle:'',
            salesTaxAmount:0,
            paymentMethodName:"",
            paymentStatus:"",

        }
    },
    async created() {
        document.title = "Golden Gal - Order";
        await this.getDataList(this.productURL);
        this.productList = this.dataList.map(el => {
            return { value: el, label: el.name + " - " + el.sku }
        });
        await this.getDataList(this.cardURL);
        this.cardList = this.dataList.map(el => {
            return { value: el, label: el.name }
        });
        await this.getCustomerList();
        await this.getSMList();
        await this.getSTList();
    },
    methods: {
        //setShippingAddressOne(locationData) {
            // let location = locationData.formatted_address;
            // let locationSplit = location.split(',');
            // if (locationSplit[0].trim() != locationData.name) {
            //     location = locationData.name + ', ' + location;
            // }
            // this.shippingAddressData.addressOne = locationData.name;
            // this.shippingAddressData.city = locationData.vicinity;
        //},
        //setShippingAddressTwo(locationData) {
            // console.log("Location: ", locationData);
            // let location = locationData.formatted_address;
            // let locationSplit = location.split(',');
            // if (locationSplit[0].trim() != locationData.name) {
            //     location = locationData.name + ', ' + location;
            // }
            // this.shippingAddressData.addressTwo = locationData.name;
        //},

        setShippingAddressOne(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }

            //this.shippingAddressData.addressOne = location;
            this.shippingAddressData.city = "";
            this.shippingAddressData.postCode = "";
            this.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {


                if (addressComponent[i].types[0] == "street_number") {
                    this.shippingAddressData.addressOne = addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "route") {

                    this.shippingAddressData.addressOne +=" "+addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.stateCode = addressComponent[i].short_name;
                }
            }
        },
        setShippingAddressTwo(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressTwo = location;
        },

        setShippingPrice: function(sm){
            console.log(sm);
            this.shippingCost = sm.price;
            this.shippingMethodID = sm.id;
            this.shippingMethodName = sm.name;
            this.shippingMethodCost = sm.price;
        },
        async getSTList() {
            let config = {
                method: "GET",
                url: this.stURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    let stList = await response.data.data;
                    this.stRate = stList[0].salesTax;
                    this.stRateTitle = stList[0].name;
                    // console.log('this.stList = ', stList);
                    // console.log('this.stRate = ', this.stRate);
                }
                else 
                {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async getSMList() {
            let config = {
                method: "GET",
                url: this.smURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.smList = await response.data.data;
                    // console.log('this.smList = ', this.smList);
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el, label: el.fullName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getShippingAddress: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getAddress + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    this.shippingAddressData = response.data.data;
                    this.stateCode = this.shippingAddressData.stateCode;
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async setUserData(data) {
            this.shippingAddressData ={};
            this.userName = data.fullName;
            this.userEmail = data.email;
            this.shippingEmail = data.email;
            this.userMobile = data.phone;
            await this.getShippingAddress(data.id);
        },
        addProduct() {
            this.idx = this.idx + 1;
            this.addedProductList.push({ productId: "" });
            this.colorValue[this.idx] = '';
            this.sizeValue[this.idx] = '';
        },
        addCard() {
            this.card_idx = this.card_idx + 1;
            this.addedCardList.push({ cardId: "" });
        },
        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.selectedProduct.length; i++) {
                this.totalWeight = this.totalWeight + parseFloat(this.selectedProduct[i].weight) * parseFloat(this.selectedProduct[i].quantity);
            }
        },
        productData(data, index) {
            if (this.selectedProduct[index] != undefined) {
                this.selectedProduct[index] = data;
                this.priceList[index] = data.price
                data.quantity = 1
            } else {
                data.quantity = 1
                this.selectedProduct.push(data);
                this.priceList.push(data.price);
            }
            this.calculateTotal();
        },
        cardData(data, index) {
            if (this.selectedCard[index] != undefined) {
                this.selectedCard[index] = data;
                this.cardPriceList[index] = data.price
                data.quantity = 1
            } else {
                data.quantity = 1
                this.selectedCard.push(data);
                this.cardPriceList.push(data.price);
            }
            this.calculateCard();
        },
        selectColor(index) {
            this.selectedProduct[index].chossedColor = this.colorValue[index];
        },
        selectSize(index) {
            this.selectedProduct[index].chossedSize = this.sizeValue[index];
        },
        selectMaterial(index) {
            this.selectedProduct[index].chossedMaterial = this.materialValue[index];
        },
        increaseQuantity(index) {
            this.selectedProduct[index].quantity = this.selectedProduct[index].quantity + 1;
            this.calculateTotal();
        },
        increaseCardQuantity(index) {
            this.selectedCard[index].quantity = this.selectedCard[index].quantity + 1;
            this.calculateCard();
        },
        decreaseQuantity(index) {
            (this.selectedProduct[index].quantity == 1) ? (this.selectedProduct[index].quantity = 1) : (this.selectedProduct[index].quantity = this.selectedProduct[index].quantity - 1);
            this.calculateTotal()
        },
        decreaseCardQuantity(index) {
            (this.selectedCard[index].quantity == 1) ? (this.selectedCard[index].quantity = 1) : (this.selectedCard[index].quantity = this.selectedCard[index].quantity - 1);
            this.calculateCard()
        },
        removeItem(index) {
            this.selectedProduct.splice(index, 1);
            this.addedProductList.splice(index, 1);
            this.productValue.splice(index, 1);
            this.colorValue.splice(index, 1);
            this.sizeValue.splice(index, 1);
            this.materialValue.splice(index, 1);
            this.calculateTotal()
        },
        removeCard(index) {
            this.selectedCard.splice(index, 1);
            this.addedCardList.splice(index, 1);
            this.cardValue.splice(index, 1);
            this.calculateCard()
        },
        addDiscount: function () {
            let discountCheck = ''
            if (this.discount.slice(-1) == '%') {
                discountCheck = this.discount.slice(0, -1);
            }
            else {
                discountCheck = this.discount;
                this.grandTotal = this.grandSubTotal;
            }
            if (isNaN(discountCheck)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid discount amount or percentage"
                });
                this.discount = '';
                return;
            }
            if (this.discount && !isNaN(discountCheck)) {
                if (this.discount.includes("%")) {
                    let converted_discount = parseFloat(this.discount) / 100.0;
                    this.grandTotal = this.grandSubTotal - this.grandSubTotal * converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseFloat(this.discount);
                        this.grandTotal = this.grandSubTotal - converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter discount amount or percentage"
                        });
                    }
                }
            }
            else {
                this.grandTotal = this.grandSubTotal;
            }

        },
        calculateTotal() {
            this.subTotal = 0;
            for (let el in this.selectedProduct) {
                if (this.selectedProduct[el].price && !isNaN(this.selectedProduct[el].price)) {
                    this.subTotal = this.subTotal + (this.selectedProduct[el].price ? this.selectedProduct[el].price * this.selectedProduct[el].quantity : this.selectedProduct[el].discountedPrice * this.selectedProduct[el].quantity);
                    this.total = this.subTotal;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter valid amount"
                    });
                    this.selectedProduct[el].price = this.priceList[el];
                    this.calculateTotal();
                }

            }
            this.grandSubTotal = this.subTotal + this.cardSubTotal;
            this.grandTotal = this.grandSubTotal;
            if (this.discount) {
                this.addDiscount();
            }
            // this.calculateWeight();
        },
        calculateCard() {
            this.cardSubTotal = 0;
            for (let el in this.selectedCard) {
                if (this.selectedCard[el].price && !isNaN(this.selectedCard[el].price)) {
                    this.cardSubTotal = this.cardSubTotal + (this.selectedCard[el].price ? this.selectedCard[el].price * this.selectedCard[el].quantity : this.selectedCard[el].discountedPrice * this.selectedCard[el].quantity);
                    this.cardTotal = this.cardSubTotal;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter valid amount"
                    });
                    this.selectedCard[el].price = this.cardPriceList[el];
                    this.calculateCard();
                }

            }
            this.grandSubTotal = this.subTotal + this.cardSubTotal;
            this.grandTotal = this.grandSubTotal;
            if (this.discount) {
                this.addDiscount();
            }
            // this.calculateWeight();
        },
        getServiceList: async function () {
            if (this.validation())
                await this.checkShipping();
        },
        getUpsServiceList: async function () {
            if (this.validation())
                await this.upsCheckShipping();
        },
        setShippingCost: function (price) {
            this.shippingCost = price;
        },
        checkShipping: async function () {
            this.serviceTypeList = [];
            this.shippingAddressData.country = this.shippingCountry.name;
            this.shippingAddressData.countryCode = this.shippingCountry.code;
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: 50 //Math.ceil(this.totalWeight)
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        upsCheckShipping: async function () {
            this.serviceTypeList = [];
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        validation: function () {
            if (!this.userName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer name.",
                });
                return false;
            } else if (!this.userEmail) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer email.",
                });
                return false;
            } else if (!this.userMobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer Mobile.",
                });
                return false;
            }  else if (!this.shippingAddressData.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping name.",
                });
                return false;
            } else if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping email.",
                });
                return false;
            } else if (!this.shippingAddressData.phone) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping contact no.",
                });
                return false;
            } else if (this.shippingAddressData.phone.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping address1.",
                });
                return false;
            } else if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping city.",
                });
                return false;
            } else if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping post code.",
                });
                return false;
            }
            else if (!this.stateCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping state code.",
                });
                return false;
            }
            else if(this.selectedProduct.length == 0 && this.selectedCard.length == 0){
                this.$swal.fire({
                    icon: "info",
                    text: "Please select atleast one product or gift card.",
                });
                return false;
            } else {
                return true;
            }
        },
        setPaymentAmount: function() {
            this.paymentInfo.amount = ((this.grandTotal-0) + (this.shippingCost-0) + (this.salesTaxAmount - 0));
        },
        formatCCnum: function () {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        handlePayment: async function (payment) {
            if (payment == 'Pay Later') {
                this.createOrder('Pay Later');
            } else if (payment == 'Pay Now') {
                if (this.paymentValidation()) {
                    this.createOrder('Pay Now');
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment option."
                });
                return;
            }
        },
        async createOrder(paymentType) {
            if(this.validation()) {
                this.$swal.showLoading();
                this.confirmedProduct = this.selectedProduct.map((el) => {
                    return { productId: el.id, quantity: el.quantity, price: el.price, variants: [el.chossedColor, el.chossedSize, el.chossedMaterial] };
                })
                let confirmedCard = this.selectedCard.map((el) => {
                    return { giftCardId: el.id, quantity: el.quantity, price: el.price};
                });
                if(paymentType == 'Pay Later') {
                    this.paymentInfo = {
                        name: "",
                        cardNumber: "",
                        securityCode: "",
                        amount: ""
                    }
                    this.paymentMethodName = "Pay Later";
                    this.paymentStatus = "PENDING";
                }
                else {
                    this.paymentMethodName = "Credit/Debit Card"
                    if (this.paymentInfo.amount && this.paymentInfo.amount < ((this.grandTotal-0) + (this.shippingCost-0) +  (this.salesTaxAmount - 0))) {
                        this.paymentStatus = "PARTIAL PAID"
                    }
                    else if(this.paymentInfo.amount && this.paymentInfo.amount == ((this.grandTotal-0) + (this.shippingCost-0) +  (this.salesTaxAmount - 0))) {
                        this.paymentStatus = "PAID" 
                    }
                }
                let data = {
                    fullName: this.userName,
                    email: this.userEmail,
                    phone: this.userMobile,
                    shippingAddress: JSON.stringify({
                        name: this.shippingAddressData.name,
                        phone: this.shippingAddressData.phone,
                        email: this.shippingAddressData.email,
                        city: this.shippingAddressData.city,
                        postCode: this.shippingAddressData.postCode,
                        stateCode: this.stateCode,
                        country: this.shippingCountry.name,
                        countryCode: this.shippingCountry.code,
                        addressOne: this.shippingAddressData.addressOne,
                        addressTwo: this.shippingAddressData.addressTwo,
                    }),
                    products: this.confirmedProduct,
                    giftCard: confirmedCard,
                    itemTotalDiscount: this.discount ? this.discount : "0",
                    remarks: this.remarks,
                    ccNum: this.paymentInfo.cardNumber ? this.paymentInfo.cardNumber.split(' ').join('') : '',
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode,
                    amount: this.paymentInfo.amount,
                    paymentStatus: this.paymentStatus,
                    paymentMethodName: this.paymentMethodName,
                    shippingMethodID: this.shippingMethodID,
                    shippingMethodName: this.shippingMethodName,
                    shippingMethodCost: this.shippingMethodCost,
                    salesTaxTitle: this.stRateTitle,
                    salesTaxRate: this.stRate,
                    salesTaxAmount: this.salesTaxAmount,
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.createOrderURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        document.getElementById("buttonClose").click();
                        this.$router.push('/order-list');
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        }).then(result => {
                            console.log(result);
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.errors.responsetext,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading()
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    },
    watch: {
        isExistingUser: function () {
            if (this.isExistingUser == false) {
                this.userDetails = ""
                this.userName = "";
                this.userEmail = "";
                this.userMobile = "";
                this.shippingName = "";
                this.shippingContactNo = "";
                this.shippingAddressData = {};
            }
        },
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        },
        grandSubTotal: function () {
            console.log('Data = ', this.grandSubTotal);
            console.log('stRate = ', this.stRate);

            this.salesTaxAmount = parseFloat((this.grandSubTotal * this.stRate)/100).toFixed(2);

        },
    }
}