import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    
    data() {
        return {
            pageCMSData: [],
            setURL: this.$serverURL + this.$api.productDetailsCms.requestURL,
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            hoverTitle: '',
            hoverText: '',
        }
    },
    async mounted() {
        document.title = "Golden Gal - Product Details CMS";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async update() {
            try {
                if(!this.pageCMSData.hoverTitle || !this.pageCMSData.hoverText ){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter hover text!"
                    });
                }
                else {
                    let data = {
                        hoverTitle: this.pageCMSData.hoverTitle,
                        hoverText: this.pageCMSData.hoverText,
                    };
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: data,
                        headers:  authHeader(),
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);
                    
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}