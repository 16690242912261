import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            name: '',
            link: '',
            image: '',
            previewImage: '',
            linkType:''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Gallery";
    },
    methods: {
        onImageChange: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function() {
            if(!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
                return false;
            }
            if(!this.linkType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select link type!"
                });
                return false;
            }
            if(!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
                return false;
            }
            if(!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                try {
                    let formData =  new FormData();
                    formData.append('name', this.name);
                    formData.append('linkType', this.linkType);
                    formData.append('link', this.link);
                    formData.append('image', this.image);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.gallery.requestURL,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.name = '';
                    this.link = '';
                    this.linkType = '';
                    this.image = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$router.push("/gallery");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}