import { authHeader } from "../../../auth";

export default {
    name: "ContactAddress",
    data() {
        return {
            singleContactAddress: {},
            setURL: '',
            title: '',
            address: '',
            email: '',
            phone: ''
        }
    },
    async created() {
        document.title = "Golden Gal - Contact Address";
        this.setURL = this.$serverURL + this.$api.contact.contactAddressURL;
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter contact title!"
                })
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter contact email!"
                })
                return false;
            }
            return true;
        },
        updateValidation: function () {
            if (!this.singleContactAddress.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter contact title!"
                })
                return false;
            }
            if (!this.singleContactAddress.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter contact email!"
                })
                return false;
            }
            return true;
        },
        addContactAddress: async function () {
            if (this.addValidation() == true) {
                let data = {
                    title: this.title,
                    address: this.address,
                    email: this.email,
                    phone: this.phone
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.contact.contactAddressURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                this.title = '';
                this.address = '';
                this.email = '';
                this.phone = '';
                await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editContactAddress: async function (data) {
            this.singleContactAddress = data;
        },
        updateContactAddress: async function () {
            if (this.updateValidation() == true) {
                let data = {
                    title: this.singleContactAddress.title,
                    address: this.singleContactAddress.address,
                    email: this.singleContactAddress.email,
                    phone: this.singleContactAddress.phone
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.contact.contactAddressURL + '/' + this.singleContactAddress.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteContactAddress: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.contact.contactAddressURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}