import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LinkShortcut from "../../../layouts/template/LinkShortcut";
export default {
    components:{
        LinkShortcut
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: '',
            pageCMSData: [],
            image: "",
            previewImage: ""
        }
    },
    async mounted() {
        document.title = "Golden Gal - Shop Guide Page";
        this.setURL = this.$serverURL + this.$api.cms.shopGuideCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        
        uploadImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData =  new FormData();
                formData.append('image', this.image ? this.image : this.pageCMSData.image ? this.pageCMSData.image: '');
                formData.append('description', this.pageCMSData.description ? this.pageCMSData.description: '');
                formData.append('buttonText', this.pageCMSData.buttonText ? this.pageCMSData.buttonText: '');
                formData.append('buttonLink', this.pageCMSData.buttonLink ? this.pageCMSData.buttonLink: '');
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.$refs.image.value = "";
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}