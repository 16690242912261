import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            title: '',
            subTitle: '',
            blockOneImage: '',
            previewBlockOneImage: '',
            blockOneTitle: '',
            blockOneLink: '',
            blockTwoImage: '',
            previewBlockTwoImage: '',
            blockTwoTitle: '',
            blockTwoLink: '',
            buttonText: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Collection";
    },
    methods: {
        onBlockOneImageChange: function (event) {
            this.blockOneImage = event.target.files[0];
            let input = this.$refs.blockOneImageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewBlockOneImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onBlockTwoImageChange: function (event) {
            this.blockTwoImage = event.target.files[0];
            let input = this.$refs.blockTwoImageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewBlockTwoImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter sub title!"
                });
                return false;
            }
            if(!this.blockOneImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload block one image!"
                });
                return false;
            }
            if(!this.blockOneTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block one title!"
                });
                return false;
            }
            if(!this.blockOneLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block one link!"
                });
                return false;
            }
            if(!this.blockTwoImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload block two image!"
                });
                return false;
            }

            if(!this.blockTwoTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block two title!"
                });
                return false;
            }
            if(!this.blockTwoLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter block two link!"
                });
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                try {
                    let formData =  new FormData();
                    formData.append('title', this.title);
                    formData.append('subTitle', this.subTitle);
                    formData.append('blockOneImage', this.blockOneImage);
                    formData.append('blockOneTitle', this.blockOneTitle);
                    formData.append('blockOneLink', this.blockOneLink);
                    formData.append('blockTwoImage', this.blockTwoImage);
                    formData.append('blockTwoTitle', this.blockTwoTitle);
                    formData.append('blockTwoLink', this.blockTwoLink);
                    formData.append('buttonText', this.buttonText);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.cms.collection.collectionPage,
                        data: formData,
                        headers: authHeader()
                    };
                    await this.postDataToBackend(config);
                    this.title = '';
                    this.subTitle = '';
                    this.blockOneTitle = '';
                    this.blockOneLink = '';
                    this.blockTwoTitle = '';
                    this.blockTwoLink = '';
                    this.buttonText = '';
                    this.$refs.blockOneImageFile.value = null;
                    this.$refs.blockTwoImageFile.value = null;
                    this.$router.push("/collection-page");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}