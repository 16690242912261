<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Promotional Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Promotional Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Promotional Page CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="pageCMSData.title" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Subtitle:</label>
                                        <input type="text" v-model="pageCMSData.subTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">First Product:</label>
                                        <select class="form-select" aria-label="Default select example"
                                            v-model="pageCMSData.firstProductId">
                                            <option style="display: none" value="" selected>
                                                Select Product
                                            </option>
                                            <option v-for="(data, index) in productList" :key="index" :value="data.urlSlug">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">First Product Label:</label>
                                        <input type="text" v-model="pageCMSData.firstProductLabel" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">First Product Discount:</label>
                                        <input type="text" v-model="pageCMSData.firstProductDiscount" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">First Product Image: <strong>[Preferred Image Size: 875X840, Max
                                                Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageOne($event)"
                                            ref="imageOne" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageOne" :src="previewImageOne" height="100"
                                                width="150" />
                                            <img v-else-if="pageCMSData.firstProductImage" :src="pageCMSData.firstProductImage"
                                                height="100" width="150" />
                                            <img v-else src="../../../../assets/images/default.png" height="100"
                                                width="150" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Second Product:</label>
                                        <select class="form-select" aria-label="Default select example"
                                            v-model="pageCMSData.secondProductId">
                                            <option style="display: none" value="" selected>
                                                Select Product
                                            </option>
                                            <option v-for="(data, index) in productList" :key="index" :value="data.urlSlug">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Second Product Label:</label>
                                        <input type="text" v-model="pageCMSData.secondProductLabel" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Second Product Discount:</label>
                                        <input type="text" v-model="pageCMSData.secondProductDiscount" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Second Product Image: <strong>[Preferred Image Size: 413X407, Max
                                                Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageTwo($event)"
                                            ref="imageTwo" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageTwo" :src="previewImageTwo" height="100"
                                                width="150" />
                                            <img v-else-if="pageCMSData.secondProductImage" :src="pageCMSData.secondProductImage"
                                                height="100" width="150" />
                                            <img v-else src="../../../../assets/images/default.png" height="100"
                                                width="150" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Third Product:</label>
                                        <select class="form-select" aria-label="Default select example"
                                            v-model="pageCMSData.thirdProductId">
                                            <option style="display: none" value="" selected>
                                                Select Product
                                            </option>
                                            <option v-for="(data, index) in productList" :key="index" :value="data.urlSlug">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Third Product Label:</label>
                                        <input type="text" v-model="pageCMSData.thirdProductLabel" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Third Product Discount:</label>
                                        <input type="text" v-model="pageCMSData.thirdProductDiscount" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Third Product Image: <strong>[Preferred Image Size: 413X407,
                                                Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageThree($event)"
                                            ref="imageThree" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageThree" :src="previewImageThree" height="100"
                                                width="150" />
                                            <img v-else-if="pageCMSData.thirdProductImage" :src="pageCMSData.thirdProductImage"
                                                height="100" width="150" />
                                            <img v-else src="../../../../assets/images/default.png" height="100"
                                                width="150" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fourth Product:</label>
                                        <select class="form-select" aria-label="Default select example"
                                            v-model="pageCMSData.fourthProductId">
                                            <option style="display: none" value="" selected>
                                                Select Product
                                            </option>
                                            <option v-for="(data, index) in productList" :key="index" :value="data.urlSlug">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fourth Product Label:</label>
                                        <input type="text" v-model="pageCMSData.fourthProductLabel" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fourth Product Discount:</label>
                                        <input type="text" v-model="pageCMSData.fourthProductDiscount" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fourth Product Image: <strong>[Preferred Image Size: 413X407,
                                                Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageFour($event)"
                                            ref="imageFour" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageFour" :src="previewImageFour" height="100"
                                                width="150" />
                                            <img v-else-if="pageCMSData.fourthProductImage" :src="pageCMSData.fourthProductImage"
                                                height="100" width="150" />
                                            <img v-else src="../../../../assets/images/default.png" height="100"
                                                width="150" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fifth Product:</label>
                                        <select class="form-select" aria-label="Default select example"
                                            v-model="pageCMSData.fifthProductId">
                                            <option style="display: none" value="" selected>
                                                Select Product
                                            </option>
                                            <option v-for="(data, index) in productList" :key="index" :value="data.urlSlug">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fifth Product Label:</label>
                                        <input type="text" v-model="pageCMSData.fifthProductLabel" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fifth Product Discount:</label>
                                        <input type="text" v-model="pageCMSData.fifthProductDiscount" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Fifth Product Image: <strong>[Preferred Image Size: 413X407,
                                                Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImageFive($event)"
                                            ref="imageFive" id="formFile">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewImageFive" :src="previewImageFive" height="100"
                                                width="150" />
                                            <img v-else-if="pageCMSData.fifthProductImage" :src="pageCMSData.fifthProductImage"
                                                height="100" width="150" />
                                            <img v-else src="../../../../assets/images/default.png" height="100"
                                                width="150" />
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button"
                                            class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/promotional-page-cms.js"></script>