import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LinkShortcut from "../../../layouts/template/LinkShortcut";
export default {
    components:{
        LinkShortcut
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: '',
            pageCMSData: [],
            image: "",
            previewImageOne: "",
            imageTwo: "",
            previewImageTwo: ""
        }
    },
    async mounted() {
        document.title = "Golden Gal -  Home";
        this.setURL = this.$serverURL + this.$api.cms.home.homePageSectionThreeCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageOne = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadFeaturedImageTwo: function (event) {
            this.imageTwo = event.target.files[0];
            let input = this.$refs.backgroundImageTwo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImageTwo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let formData =  new FormData();
                formData.append('title', this.pageCMSData.title ? this.pageCMSData.title: '');
                formData.append('imageOne', this.image ? this.image : this.pageCMSData.imageOne ? this.pageCMSData.imageOne: '');
                formData.append('imageTwo', this.imageTwo ? this.imageTwo : this.pageCMSData.imageTwo ? this.pageCMSData.imageTwo: '');
                formData.append('description', this.pageCMSData.description ? this.pageCMSData.description: '');
                formData.append('buttonText', this.pageCMSData.buttonText ? this.pageCMSData.buttonText: '');
                formData.append('buttonLink', this.pageCMSData.buttonLink ? this.pageCMSData.buttonLink: '');
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homePageSectionThreeCMS,
                    data: formData,
                    headers: authHeader(),
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}