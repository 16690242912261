<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="container">
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="invoice-container" id="doc">
                  <div class="invoice-header">
                    <div class="row gutters">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="mb-3 text-center mt-4">
                          <img src="/core/assets/images/golden-gal.png" alt />
                          <p v-if="pageCMSData">{{ pageCMSData.contactAddress }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" id="contnet" v-if="
                        billingAddress &&
                        Object.values(billingAddress).length > 0
                      ">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Billing Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>
                              {{
                                billingAddress?.name
                                ? billingAddress?.name
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>
                              {{
                                billingAddress?.mobile
                                ? billingAddress?.mobile
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>
                              {{
                                billingAddress?.email
                                ? billingAddress?.email
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <div v-if="billingAddress">
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                  billingAddress?.addressOne
                                  ? billingAddress?.addressOne
                                    : ""
                                }}
                                {{
  billingAddress?.city
  ? "," + billingAddress?.city
                                : ""
                                }}
                                {{
  billingAddress?.postCode
  ? "," + billingAddress?.postCode
                                : ""
                                }}
                                ,United States of America
                              </div>
                              <div v-else>
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                N/A
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Shipping Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>
                              {{
                                shippingAddress?.name
                                ? shippingAddress?.name
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>
                              {{
                                shippingAddress?.mobile
                                ? shippingAddress?.mobile
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>
                              {{
                                shippingAddress?.email
                                ? shippingAddress?.email
                                  : "N/A"
                              }},
                            </li>
                            <li class="text-muted">
                              <div v-if="shippingAddress">
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                  shippingAddress?.addressOne
                                  ? shippingAddress?.addressOne
                                    : ""
                                }}
                                {{
  shippingAddress?.city
  ? ", " + shippingAddress?.city
                                : ""
                                }}
                                {{
  shippingAddress?.postCode
  ? ", " + shippingAddress?.postCode
                                : ""
                                }}
                                {{
  shippingAddress?.country
  ? ", " + shippingAddress?.country
                                : ""
                                }}
                              </div>
                              <div v-else>
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span>Address:</span>
                                N/A
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Invoice</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">ID:</span>
                              #{{
                                orderDetails.orderId
                              }}
                            </li>
                            <li class="text-muted" v-if="orderDetails.trackingNumber">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Tracking Number:</span>
                              {{
                                orderDetails.trackingNumber
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Creation Date:</span>
                              {{
                                this.$filters.formatOrderDate(orderDetails.createdAt)
                              }}
                            </li>
                            <li class="text-muted" v-if="orderDetails.paymentDate">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Payment Date:</span>
                              {{
                                $filters.formatOrderDate(orderDetails.paymentDate)
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Payment Status:</span>
                              <span :class="[
                                orderDetails.paymentStatus == 'PENDING'
                                  ? 'badge bg-warning'
                                  : orderDetails.paymentStatus == 'PAID'
                                    ? 'badge bg-success' : orderDetails.paymentStatus == 'PARTIAL PAID' ? 'badge bg-info'
                                      : 'badge bg-danger',
                                'text-black fw-bold',
                              ]">{{ orderDetails.paymentStatus }}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Delivery Status:</span>
                              <span :class="[
                                orderDetails.deliveryStatus == 'PENDING'
                                  ? 'badge bg-primary'
                                  : orderDetails.deliveryStatus == 'PROCESSING'
                                    ? 'badge bg-info'
                                    : orderDetails.deliveryStatus == 'SHIPPED'
                                      ? 'badge bg-warning'
                                      : orderDetails.deliveryStatus == 'DELIVERED'
                                        ? 'badge bg-success'
                                        : 'badge bg-danger',
                              ]">{{ orderDetails.deliveryStatus }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                  <div class="invoice-body">
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Amount</th>
                                <th scope="col" data-html2canvas-ignore="true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in itemList" :key="index">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>
                                  {{ data.name }}
                                  <br />
                                  {{ data.variants }}
                                  <br>
                                  <p v-if="data.returnQuantity > 0 && (data.returnStatus == 'Approved' || data.returnStatus == 'Unapproved')"
                                    style="color:green;">
                                    <span style="color: red">*</span>
                                    {{`${data.returnQuantity} Item has been` + (data.returnStatus == 'Approved' ? `
                                    returned
                                    (${data.returnStatus})` : ` requested for return (${data.returnStatus})`)}}
                                  </p>

                                </td>
                                <td>{{ data.type }}</td>
                                <td>${{ data.price }}</td>
                                <td>{{ data.quantity }}</td>
                                <td>${{ data.amount }}</td>
                                <td data-html2canvas-ignore="true">
                                  <div class="btn-group" v-if="data.type == 'Product'">
                                    <button type="button" :disabled="orderDetails.paymentStatus == 'PENDING'"
                                      class="btn btn-outline-primary">Actions</button>
                                    <button type="button"
                                      class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                      data-bs-toggle="dropdown" aria-expanded="false"
                                      :disabled="orderDetails.paymentStatus == 'PENDING'">
                                      <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul class="dropdown-menu">
                                      <!-- <li>
                                        <button
                                          class="dropdown-item"
                                          @click="refund(data)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#refundModal"
                                          :disabled="data.isRefunded || data.returnQuantity==data.quantity || orderDetails.isGlobalRefunded == true"
                                        >{{ data.isRefunded || (data.returnQuantity==data.quantity && data.returnStatus=='Approved') || orderDetails.isGlobalRefunded == true ? "Refunded" : "Refund" }}</button>
                                      </li>-->
                                      <li>
                                        <button class="dropdown-item" data-bs-toggle="modal"
                                          data-bs-target="#returnModal" @click="handleReturn(data)"
                                          :disabled="data.quantity <= data.returnQuantity || orderDetails.isGlobalRefunded == true">{{(data.returnQuantity
                                          == data.quantity && data.returnStatus == 'Approved') ? 'Returned & Refunded' :
                                          'Return & Refund'}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4">Remarks: {{ orderDetails.remarks ? orderDetails.remarks : "" }}</td>
                                <td colspan="1">
                                  <p>
                                    Subtotal
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p v-if="(orderDetails.salesTaxAmount > 0)">
                                    (+) {{ orderDetails.salesTaxTitle }}({{ orderDetails.salesTaxRate }}%)
                                    <br />
                                  </p>
                                  <p
                                    v-if="(orderDetails.shippingMethodCost > 0 || orderDetails.shippingMethodName == 'Free Shipping')">
                                    (+) Shipping Cost ({{ orderDetails.shippingMethodName }})
                                    <br />
                                  </p>

                                  <p v-if="(orderDetails.refundAmount > 0)">
                                    Refund
                                    <br />
                                  </p>
                                  <!-- <p v-if="orderDetails.couponDiscount != '0'">
                                    Coupon discount<br />
                                  </p>-->
                                  <p v-if="
                                    orderDetails.subTotal !=
                                    orderDetails.total
                                  ">(-) Discount</p>
                                  <h5 class="text-success">
                                    <strong>Grand Total</strong>
                                  </h5>
                                  <h5 class="text-danger" v-if="orderDetails.total > orderDetails.paymentAmount">
                                    <strong>Due</strong>
                                  </h5>
                                </td>
                                <td>
                                  <p>
                                    ${{ orderDetails.subTotal }}
                                    <br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <p v-if="(orderDetails.salesTaxAmount > 0)">
                                    ${{ orderDetails.salesTaxAmount }}
                                    <br />
                                  </p>
                                  <p
                                    v-if="(orderDetails.shippingMethodCost > 0 || orderDetails.shippingMethodName == 'Free Shipping')">
                                    ${{ orderDetails.shippingMethodCost }}
                                    <br />
                                  </p>
                                  <p v-if="(orderDetails.refundAmount > 0)">
                                    (-)${{ orderDetails.refundAmount }}
                                    <br />
                                  </p>

                                  <p v-if="orderDetails.subTotal != orderDetails.total">
                                    (-) ${{ orderDetails.itemTotalDiscount}}
                                  </p>
                                  <h5 class="text-success">
                                    <strong>${{ calculateGrandTotal }}</strong>
                                  </h5>
                                  <!-- ((orderDetails.total - 0) + (orderDetails.shippingMethodCost - 0) + (orderDetails.salesTaxAmount - 0)) > orderDetails.paymentAmount -->
                                  <h5 class="text-danger" v-if="orderDetails.total > orderDetails.paymentAmount">
                                    <strong>
                                      ${{ calculateDue }}
                                    </strong>
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="custom-actions-btns mb-5">
              <button type="button" class="btn btn-primary text-white me-2" data-bs-toggle="modal"
                data-bs-target="#addPayment" v-if="orderDetails.paymentStatus != 'PAID'">Pay</button>
              <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
              </a>-->
              <a href="javascript:void(0)" class="btn btn-secondary" @click="generatePDF">
                <i class="icon-printer"></i> Print
              </a>
            </div>
          </div>
        </div>

        <!-- return request list -->

        <div class="row gutters" v-if="returnProductList.length > 0">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="btn-group" style="margin:15px" v-if="bulkUpdate">
                  <button type="button" class="btn btn-outline-info btn-sm mb-md-0">Bulk Action</button>
                  <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" style>
                    <li>
                      <a class="dropdown-item" href="#" @click="bulkStatusUpdate('Approved')">Approve</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="bulkStatusUpdate('Rejected')">Reject</a>
                    </li>
                  </ul>
                </div>
                <div class="invoice-container">
                  <div class="invoice-body">
                    <!-- Row start -->

                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th v-if="bulkUpdate">
                                  <input class="form-check-input" v-model="selectAll" @change="handleSelectAll"
                                    type="checkbox" value id="flexCheckDefault" />
                                </th>
                                <th>SL</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Item Price</th>
                                <th>Total Price</th>
                                <th>Return Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in returnProductList" :key="index">
                                <td v-if="bulkUpdate">
                                  <input v-if="data.status == 'Unapproved'" class="form-check-input"
                                    v-model="selected[index]" @change="
                                      () => {
                                        handleSelect(
                                          index,
                                          selected[index],
                                          data
                                        );
                                      }
                                    " type="checkbox" id="flexCheckDefault" />
                                </td>
                                <td>{{ index+ 1}}</td>
                                <td>{{ data.productName }}</td>
                                <td>{{ data.quantity }}</td>
                                <td>{{ data.actualPrice }}</td>
                                <td>{{ data.totalPrice }}</td>
                                <td>{{ data.status }}</td>
                                <td>
                                  <div class="d-flex order-actions">
                                    <div class="d-flex order-actions" v-if="data.status == 'Unapproved'">
                                      <div class="btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button"
                                          class="btn btn-outline-primary dropdown-toggle"
                                          data-bs-toggle="dropdown">Actions</button>
                                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                          <li>
                                            <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal"
                                              data-bs-target="#noteModal" @click="viewNote(data)">Reason</a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="javascript:void(0)"
                                              @click="updateReturnStatus(data, 'Approved')">Approve</a>
                                          </li>
                                          <li>
                                            <a @click="updateReturnStatus(data, 'Rejected')" class="dropdown-item"
                                              href="javascript:void(0)">Reject</a>
                                          </li>
                                        </ul>
                                      </div>

                                      <!-- <button
                                        class="me-2 btn btn-success"
                                        data-bs-toggle="modal"
                                        data-bs-target="#noteModal"
                                        @click="viewNote(data)"
                                      >
                                        <i class="fa-solid fa-file-circle-check text-white"></i>
                                      </button>
                                      <button
                                        href="javascript:void(0)"
                                        class="me-2 btn btn-success"
                                        @click="updateStatus(data, 'Approved')"
                                      >
                                        <i class="bx bx-check text-white"></i>
                                      </button>
                                      <button
                                        href="javascript:void(0)"
                                        class="me-2 btn btn-danger"
                                        @click="updateStatus(data, 'Rejected')"
                                      >
                                        <i class="bx bxs-trash text-white"></i>
                                      </button>-->
                                    </div>
                                    <button v-else type="button"
                                      :class="['me-2', 'btn', data.status == 'Approved' ? 'btn-outline-success' : 'btn-outline-danger']"
                                      data-bs-toggle="modal" data-bs-target="#noteModal"
                                      :disabled="data.status == 'Approved' || data.status == 'Rejected'"
                                      @click="viewNote(data)">{{
                                        data.status == 'Approved' ? 'Approved' : data.status ==
                                          'Rejected' ? 'Rejected' : ''
                                      }}</button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="modal fade" id="addPayment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Card Information</h5>
            <button type="button" id="buttonClose" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="payment-container preload" style="margin: 0 auto">
                  <div class="creditcard">
                    <div class="front">
                      <div id="ccsingle"></div>
                      <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471" xml:space="preserve">
                        <g id="Front">
                          <g id="CardBackground">
                            <g id="Page-1_1_">
                              <g id="amex_1_">
                                <path id="Rectangle-1_1_" class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z" />
                              </g>
                            </g>
                            <path class="darkcolor greydark"
                              d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                          </g>
                          <text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" class="st2 st3 st4">
                            {{
                              paymentInfo.cardNumber
                                ? paymentInfo.cardNumber
                                : "0123 4567 8910 1112"
                            }}
                          </text>
                          <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" class="st2 st5 st6">
                            {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                          <text transform="matrix(1 0 0 1 54.1074 389.8793)" class="st7 st5 st8">cardholder name</text>
                          <text transform="matrix(1 0 0 1 479.7754 388.8793)" class="st7 st5 st8">expiration</text>
                          <text transform="matrix(1 0 0 1 65.1054 241.5)" class="st7 st5 st8">card number</text>
                          <g>
                            <text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" class="st2 st5 st9">{{
                              expireDate? expireDate: "01/23"
                            }}</text>
                            <text transform="matrix(1 0 0 1 479.3848 417.0097)" class="st2 st10 st11">VALID</text>
                            <text transform="matrix(1 0 0 1 479.3848 435.6762)" class="st2 st10 st11">THRU</text>
                            <polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
                          </g>
                          <g id="cchip">
                            <g>
                              <path class="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                            </g>
                            <g>
                              <g>
                                <rect x="82" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <path class="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                              </g>
                              <g>
                                <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
                              </g>
                              <g>
                                <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="Back" />
                      </svg>
                    </div>
                    <div class="back">
                      <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471" xml:space="preserve">
                        <g id="Back">
                          <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
                        </g>
                        <g id="Back">
                          <g id="Page-1_2_">
                            <g id="amex_2_">
                              <path id="Rectangle-1_2_" class="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z" />
                            </g>
                          </g>
                          <rect y="61.6" class="st2" width="750" height="78" />
                          <g>
                            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z" />
                            <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
                            <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
                            <path class="st5"
                              d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                          </g>
                          <text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" class="st6 st7">
                            {{
                              paymentInfo.securityCode
                                ? paymentInfo.securityCode
                                : "985"
                            }}
                          </text>
                          <g class="st8">
                            <text transform="matrix(1 0 0 1 518.083 280.0879)" class="st9 st6 st10">security code</text>
                          </g>
                          <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
                          <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
                          <text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" class="st12 st13">
                            {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="field-container mb-2">
                  <label class="payment-label" for="name">Name</label>
                  <input class="payment-input" id="name" maxlength="20" type="text" v-model="paymentInfo.name" />
                </div>
                <div class="field-container mb-2">
                  <label class="payment-label" for="cardnumber">Card Number</label>
                  <!-- <span id="generatecard">generate random</span> -->
                  <input id="cardnumber" type="text" pattern="^[0-9]$" v-on:keyup="formatCCnum" name="cardnumber"
                    class="payment-input" v-model="paymentInfo.cardNumber" />
                  <svg id="ccicon" class="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" />
                </div>
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <div class="field-container">
                      <label class="payment-label" for="expirationdate">Expiration (mm/yy)</label>
                      <input id="expirationdate" class="payment-input" type="text" maxlength="5" pattern="[0-9]*"
                        inputmode="numeric" v-model="expireDate" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="field-container">
                      <label class="payment-label" for="securitycode">Security Code</label>
                      <input id="securitycode" class="payment-input" type="text" maxlength="4" pattern="[0-9]*"
                        v-model="paymentInfo.securityCode" />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="field-container">
                      <label class="payment-label" for="securitycode">Amount</label>
                      <input class="payment-input" type="text" v-model="paymentInfo.amount" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary text-white text-uppercase me-2"
              @click="completePayment">Confirm Payment</button>
          </div>
        </div>
      </div>
    </div>

    <!-- admin return modal -->
    <div class="modal fade mt-5" id="returnModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Return Product</h5>
            <button type="button" class="btn-close" id="return-close-btn" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Reason</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 15% !important;">
                      <div class="itemside" style="display:flex; align-items: center;">
                        <div class="left m-1">
                          <img :src="singleReturnProduct.featuredImage" width="40" height="40" class="img-xs"
                            alt="Item" />
                        </div>
                        <div class="info m-1">{{ singleReturnProduct.name }}</div>
                      </div>
                    </td>
                    <td>
                      <textarea class="form-control m-1" id="exampleFormControlTextarea1"
                        v-model="singleReturnProduct.note" rows="1"></textarea>
                    </td>
                    <td>
                      <div class="numbers-row m-1" style="display:flex;">
                        <input disabled type="text" :value="singleReturnProduct.insertQty" id="quantity_1" class="qty2"
                          name="quantity_1" />
                        <button :disabled="singleReturnProduct.remainingQty == 0" style="border: none"
                          class="inc button_inc" @click="increaseQuantity()">+</button>
                        <button :disabled="singleReturnProduct.remainingQty == 0" style="border: none"
                          class="dec button_inc" @click="decreaseQuantity()">-</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="color:black">Close</button>
            <button type="button" class="btn btn-primary" @click="confirmReturn">Confirm Return</button>
          </div>
        </div>
      </div>
    </div>

    <!-- note modal -->
    <div class="modal fade" id="noteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Return Reason</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">{{ note? note: 'Not Available!' }}</div>
        </div>
      </div>
    </div>
    <!-- note modal -->
  </div>
</template>

<script src="../js/order-details.js"></script>

<style scoped>
@import "../css/order-details.css";

.numbers-row {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: visible;
  border: 1px solid #dddddd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  text-align: left !important;
}

input.qty2 {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  left: 50%;
  background: none;
  padding: 5px;
  border: none;
  margin-left: -20px;
}

input.qty2:focus {
  outline: none;
  box-shadow: none;
}

.order-actions a {
  font-size: 18px;
  width: 170px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeecec;
  text-align: center;
  border-radius: 0%;
  color: #2b2a2a;
  margin: 5px;
}

.button_inc {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  z-index: 2;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 300 !important;
  color: #999;
}

.button_inc:hover {
  color: #004dda;
}

.dec {
  left: 0;
  top: 0;
}

.inc {
  right: 0;
  top: 0;
}
</style>
