<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Email Template</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Email Template</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Add New Email Template</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Template Type:</label>
                    <select v-model="type" class="form-select">
                      <option style="display:none" value>Select Type</option>
                      <option value="Account Create Admin">Account Create Admin</option>
                      <option value="Order Shipped">Order Shipped</option>
                      <option value="Password Reset ">Password Reset</option>
                      <option value="Create Customer ">Create Customer</option>
                      <option value="Create Customer By Admin ">Create Customer By Admin</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Template Content:</label>
                    <ckeditor :editor="editor" v-model="template " :config="editorConfig"></ckeditor>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="save" type="button" class="btn btn-primary">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-email-template.js"></script>