import { authHeader } from "../../../../auth";

export default {
    name: "Size",
    data() {
        return {
            singleSize: {},
            setURL: '',
            name: ''
        }
    },
    async created() {
        document.title = "Golden Gal - Product Size";
        this.setURL = this.$serverURL + this.$api.product.sizeURL;
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter size name!"
                })
                return false;
            }
            return true;
        },
        updateValidation: function () {
            if (!this.singleSize.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter size name!"
                })
                return false;
            }
            return true;
        },
        addSize: async function () {
            if (this.addValidation() == true) {
                let data = {
                    name: this.name
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.sizeURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                this.name = '';
                await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editSize: async function (data) {
            this.singleSize = data;
        },
        updateSize: async function () {
            if (this.updateValidation() == true) {
                let data = {
                    name: this.singleSize.name
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.product.sizeURL + '/' + this.singleSize.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteSize: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.product.sizeURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}