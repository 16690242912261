<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Create Customer Account - Email Template</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Create Customer Account - Email Template</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Customer Account - Email Template</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8 col-md-6" style="overflow:hidden;">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3" style="text-align:center;" id="emailBody">
                                   


        
                      <div style="background-color: #f9f9f9; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none; max-width: 700px;">
                          <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                          <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; " :style="{ 'background-color':template.logoBG }" width="100%">
                              <tbody>
                                  <tr>
                                      <td>
                                          <table align="center"   border="0" cellpadding="0" cellspacing="0" class="row row-1"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td v-on:click="changeLogo">
                                                          <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;"
                                                              width="700">
                                                              <tbody>
                                                                  <tr>
                                                                      <td   v-on:click="changeLogo" class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <div class="spacer_block"
                                                                              style="height:10px;line-height:10px;font-size:1px;"> </div>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <table align="center" v-on:click="changeLogo" border="0" cellpadding="0" cellspacing="0" class="row row-2"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td>
                                                          <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;"
                                                              width="700">
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <table border="0" v-on:click="changeLogo" cellpadding="0" cellspacing="0"
                                                                              class="image_block block-1" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="width:100%;padding-right:0px;padding-left:0px;">
                                                                                      <div align="center" class="alignment" style="line-height:10px">
                                                                                          <img  alt="Alternate text" :src="template.logo"
                                                                                              style="display: block; height: auto; border: 0; width: 200px; max-width: 100%;" title="Alternate text" width="200" />
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td>
                                                          <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;"
                                                              width="700">
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <div class="spacer_block"
                                                                              style="height:15px;line-height:15px;font-size:1px;"> </div>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td>
                                                          <table align="center" border="0" cellpadding="0" cellspacing="0" v-on:click="changeSectionTwo" 
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; border-top-left-radius: 15px; border-top-right-radius: 15px;"
                                                              width="700" :style="{'background-color':template.sectionTwoBG, 'color': template.sectionTwoFontColor}">
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <table border="0" cellpadding="0" cellspacing="0"
                                                                              class="image_block block-2" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="width:100%;padding-right:0px;padding-left:0px;padding-top:30px;">
                                                                                      <div align="center" class="alignment"
                                                                                          style="line-height:10px">
                                                                                          <img alt="I'm an image"
                                                                                              :src="template.sectionTwoImage"
                                                                                              style="display: block; height: auto; border: 0; width: 315px; max-width: 100%;"
                                                                                              title="I'm an image" width="315" />
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                          <table border="0" cellpadding="0" cellspacing="0"
                                                                              class="text_block block-3" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="padding-bottom:10px;padding-left:40px;padding-right:40px;padding-top:10px;">
                                                                                      <div style="font-family: sans-serif">
                                                                                          <div class="" style="font-size: 12px; mso-line-height-alt: 18px;  line-height: 1.5; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;"
                                                                                           :style="{ 'color': template.sectionTwoFontColor}">
                                                                                           <p style="margin: 0; font-size: 16px; text-align: center; mso-line-height-alt: 24px;">
                                                                                                <strong>
                                                                                                    <span style="font-size:38px;">
                                                                                                        {{ template.sectionTwoHeadingContent }}
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </p>
                                                                                              <!-- <p style="margin: 0; font-size: 16px; text-align: center; mso-line-height-alt: 24px;">
                                                                                                  <strong><span style="font-size:38px;">Hi John Doe, </span></strong></p>
                                                                                              <p style="margin: 0; font-size: 16px; text-align: center; mso-line-height-alt: 24px;">
                                                                                                  <strong><span style="font-size:38px;">welcome to GoldenGal
                                                                                                      </span></strong><strong><span style="font-size:38px;">Jewelry!</span></strong>
                                                                                              </p> -->
                                                                                          </div>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                          <table border="0" cellpadding="0" cellspacing="0"
                                                                              class="text_block block-4" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="padding-bottom:65px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                                                                      <div style="font-family: sans-serif">
                                                                                          <div class="" style="font-size: 12px; mso-line-height-alt: 14.399999999999999px; line-height: 1.2; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;"
                                                                                          :style="{ 'color': template.sectionTwoFontColor}">
                                                                                                <p style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;">
                                                                                                    <span style="font-size:22px;">
                                                                                                        {{ template.sectionTwoShortDetail }}
                                                                                                    </span>
                                                                                                </p>
                                                                                          </div>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>   
                                          <table   v-on:click="changeSectionThree" align="center" border="0" cellpadding="0" cellspacing="0" class="row row-8"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td>
                                                          <table  v-on:click="changeSectionThree"  align="center" border="0" cellpadding="0" cellspacing="0"
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 700px;" 
                                                              :style="{'background-color':template.sectionThreeBG, 'color':template.sectionThreeFontColor, 'border-bottom-left-radius':'25px', 'border-bottom-right-radius':'25px' }"
                                                              width="700">
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <table  v-on:click="changeSectionThree"  border="0" cellpadding="0" cellspacing="0"
                                                                              class="text_block block-2" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="padding-bottom:10px;padding-left:40px;padding-right:40px;padding-top:40px;">
                                                                                      <div style="font-family: sans-serif">
                                                                                          <div class=""
                                                                                              style="font-size: 12px; mso-line-height-alt: 24px; color: #555555; line-height: 2; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">
                                                                                                <p style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 28px;">
                                                                                                  {{ template.sectionThreeFotterContent }}
                                                                                                </p>
                                                                                          </div>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <table   v-on:click="changeSectionFour"  align="center" border="0" cellpadding="0" cellspacing="0" class="row row-11"
                                              role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                              <tbody>
                                                  <tr>
                                                      <td>
                                                          <table   v-on:click="changeSectionFour"  align="center" border="0" cellpadding="0" cellspacing="0"
                                                              class="row-content stack" role="presentation"
                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px;"
                                                              width="700">
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="column column-1"
                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                                          width="100%">
                                                                          <table   v-on:click="changeSectionFour"  border="0" cellpadding="0" cellspacing="0"
                                                                              class="icons_block block-1" role="presentation"
                                                                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                              width="100%">
                                                                              <tr>
                                                                                  <td class="pad"
                                                                                      style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">
                                                                                      <table   v-on:click="changeSectionFour"  cellpadding="0" cellspacing="0"
                                                                                          role="presentation"
                                                                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                                          width="100%">
                                                                                          <tr>
                                                                                              <td class="alignment"
                                                                                                  style="vertical-align: middle; text-align: center;">
                                                                                                  <!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                                                                                  <!--[if !vml]><!-->
                                                                                                  <table cellpadding="0" cellspacing="0"
                                                                                                      class="icons-inner" role="presentation"
                                                                                                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                                                                                      <!--<![endif]-->
                                                                                                      <tr>
                                                                                                          <td
                                                                                                              style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;">
                                                                                                              </td>
                                                                                                                  <td style="font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; font-size: 15px; color: #9d9d9d; vertical-align: middle; letter-spacing: undefined; text-align: center;">
                                                                                                                      <a href="https://goldengaljewelry.com" style="color: #9d9d9d; text-decoration: none;" target="_blank">{{ template.sectionFourContent }}</a>
                                                                                                                  </td>
                                                                                                      </tr>
                                                                                                  </table>
                                                                                              </td>
                                                                                          </tr>
                                                                                      </table>
                                                                                  </td>
                                                                              </tr>
                                                                          </table>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </td>
                                  </tr>
                              </tbody>
                          </table><!-- End -->
                      </div>

                    
                  </div>
                  <div class="mb-3" style="text-align: left;">
                        <button v-on:click="publishUpdateTemplate" type="button" class="btn btn-primary">Publish Updated Template</button> 
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                    <div class="border border-3 p-4 rounded">
                        <code>[FullName]</code>
                        <code>[Email]</code>
                        <code>[Password]</code>
                        <code>[Phone]</code>
                        <hr />
                        <h6>How to use:</h6>
                        <p>Hi Mr <code style="color:black;">[FullName]</code>, Nice to meet you.</p>
                    </div>
                    {{ logoChangeArea }}
                    <div class="border border-3 p-4 rounded" v-if="logoChangeArea">
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label for="inputProductTitle" class="form-label" style="text-align:left;">Logo Image: <br /><strong>[Image Size: 200 X 66, Max: 200 KB]</strong></label>
                                    <input class="form-control mb-1" type="file" ref="backgroundImage" accept="image/*" @change="uploadFeaturedImage" />

                                    <img style="position: relative;" v-if="template.logo" :src="template.logo" height="70" />
                                    <img style="position: relative;" v-else  src="https://golden-gal.nyc3.cdn.digitaloceanspaces.com/email-template/default_cms.png" height="70" />
                                </div>
                          </div>
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <div class="input-group">
                                        <LvColorpicker label="Logo & Fotter Section Background:" v-model="template.logoBG" :clearable="true"
                                            :bottomBar="true" :colors="colors" />
                                    </div>
                                </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4" style="text-align: left;">
                              <button v-on:click="updateCms" type="button" class="btn btn-primary">Update</button> 
                              
                            </div>
                            <div class="col-md-4 text-right" >
                              <button v-on:click="logoChangeArea=false" type="button" class="btn btn-warning text-white">X Close</button> 
                            </div>
                          </div>
                    </div>
                    <div class="border border-3 p-4 rounded" v-if="sectionTwoChangeArea">
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label for="sectionTwoImage" class="form-label" style="text-align:left;">Section Image: <br /><strong>[Image Size: 200 X 200, Max: 200 KB]</strong></label>
                                    <input class="form-control mb-1" type="file" ref="sectionTwoImage" accept="image/*" @change="uploadSectionTwoImage" />

                                    <img style="position: relative;" v-if="previewSectionTwoImage" :src="previewSectionTwoImage" height="70" />
                                    <img style="position: relative;" v-else  src="https://golden-gal.nyc3.cdn.digitaloceanspaces.com/email-template/default_cms.png" height="70" />
                                </div>
                          </div>
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <div class="input-group">
                                        <LvColorpicker label="Section Background:" v-model="template.sectionTwoBG" :clearable="true"
                                            :bottomBar="true" :colors="colors" />
                                    </div>
                                </div>
                          </div>
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <div class="input-group">
                                        <LvColorpicker label="Section Font Color:" v-model="template.sectionTwoFontColor" :clearable="true"
                                            :bottomBar="true" :colors="colors" />
                                    </div>
                                </div>
                          </div>
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label class="form-label">Section Heading Content</label>
                                    <textarea rows="5" class="form-control" v-model="template.sectionTwoHeadingContent"></textarea>
                                </div>
                          </div>
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label class="form-label">Section Short Detail</label>
                                    <textarea rows="5" class="form-control" v-model="template.sectionTwoShortDetail"></textarea>
                                </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4" style="text-align: left;">
                              <button v-on:click="updateCms" type="button" class="btn btn-primary">Update</button> 
                              
                            </div>
                            <div class="col-md-4 text-right" >
                              <button v-on:click="sectionTwoChangeArea=false" type="button" class="btn btn-warning text-white">X Close</button> 
                            </div>
                          </div>
                    </div>
                    <div class="border border-3 p-4 rounded" v-if="sectionThreeChangeArea">
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label class="form-label">Section Fotter Content</label>
                                    <textarea rows="5" class="form-control" v-model="template.sectionThreeFotterContent"></textarea>
                                </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4" style="text-align: left;">
                              <button v-on:click="updateCms" type="button" class="btn btn-primary">Update</button> 
                              
                            </div>
                            <div class="col-md-4 text-right" >
                              <button v-on:click="sectionThreeChangeArea=false" type="button" class="btn btn-warning text-white">X Close</button> 
                            </div>
                          </div>
                    </div>
                    <div class="border border-3 p-4 rounded" v-if="sectionFourChangeArea">
                          
                          <div class="mb-3 row">
                                <div class="col-md-12">
                                    <label class="form-label">Section Content</label>
                                    <textarea rows="5" class="form-control" v-model="template.sectionFourContent"></textarea>
                                </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4" style="text-align: left;">
                              <button v-on:click="updateCms" type="button" class="btn btn-primary">Update</button> 
                              
                            </div>
                            <div class="col-md-4 text-right" >
                              <button v-on:click="sectionFourChangeArea=false" type="button" class="btn btn-warning text-white">X Close</button> 
                            </div>
                          </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/CreateCustomerEmail.js"></script>
<style>
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
    }

    a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
    }

    #MessageViewBody a {
        color: inherit;
        text-decoration: none;
    }

    p {
        line-height: inherit
    }

    .desktop_hide,
    .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
    }

    @media (max-width:720px) {
        .desktop_hide table.icons-inner {
            display: inline-block !important;
        }

        .icons-inner {
            text-align: center;
        }

        .icons-inner td {
            margin: 0 auto;
        }

        .image_block img.big,
        .row-content {
            width: 100% !important;
        }

        .mobile_hide {
            display: none;
        }

        .stack .column {
            width: 100%;
            display: block;
        }

        .mobile_hide {
            min-height: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            font-size: 0px;
        }

        .desktop_hide,
        .desktop_hide table {
            display: table !important;
            max-height: none !important;
        }
    }
</style>