import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';
export default {
    components:{
        LvColorpicker
    },
    data() {
        return {
            type: '',
            template: {
                logo:'',
                logoBG:'',
                sectionTwoImage:'',
                sectionTwoBG:''
            },
            colors: [
                "#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50"
                                                     , "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                },
            },
            showModal: false,
            image: "",
            sectionTwoImageRaw: "",
            sectionThreeSideImageRaw: "",
            previewImage: "",
            previewSectionTwoImage: "",
            previewSectionThreeSideImage: "",
            emailType: "Create Customer By Admin",
            logoChangeArea: false,
            sectionTwoChangeArea: false,
            sectionThreeChangeArea: false,
            sectionFourChangeArea: false,
        }
    },
    async mounted() {
        document.title = "Golden Gal - Edit Email Template";
        await this.fetchEditData();
    },
    methods: {
        publishUpdateTemplate:async function(){
            let emailHead=`<!DOCTYPE html>

                    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                    <head>
                    <title></title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                    <style>
                            * {
                                box-sizing: border-box;
                            }
                    
                            body {
                                margin: 0;
                                padding: 0;
                            }
                    
                            a[x-apple-data-detectors] {
                                color: inherit !important;
                                text-decoration: inherit !important;
                            }
                    
                            #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                            }
                    
                            p {
                                line-height: inherit
                            }
                    
                            .desktop_hide,
                            .desktop_hide table {
                                mso-hide: all;
                                display: none;
                                max-height: 0px;
                                overflow: hidden;
                            }
                    
                            @media (max-width:720px) {
                                .desktop_hide table.icons-inner {
                                    display: inline-block !important;
                                }
                    
                                .icons-inner {
                                    text-align: center;
                                }
                    
                                .icons-inner td {
                                    margin: 0 auto;
                                }
                    
                                .image_block img.big,
                                .row-content {
                                    width: 100% !important;
                                }
                    
                                .mobile_hide {
                                    display: none;
                                }
                    
                                .stack .column {
                                    width: 100%;
                                    display: block;
                                }
                    
                                .mobile_hide {
                                    min-height: 0;
                                    max-height: 0;
                                    max-width: 0;
                                    overflow: hidden;
                                    font-size: 0px;
                                }
                    
                                .desktop_hide,
                                .desktop_hide table {
                                    display: table !important;
                                    max-height: none !important;
                                }
                            }
                        </style>
                    </head>
                    <body style="margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">`;
            let emailFotter=`</body></html>`;
            let emailBody=document.querySelector("#emailBody").innerHTML; 
            console.log('email Body ', emailBody);

            try {
                let formData =  new FormData();
                formData.append('rawFormatedCode', emailHead+emailBody+emailFotter);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.createCustomerAccount,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                console.log(config);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        changeLogo: function(){
            console.log('changeLogo');
            this.logoChangeArea = true;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
        },
        changeSectionTwo: function(){
            console.log('changeSectionTwo');
            this.logoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionTwoChangeArea = true;
        },
        changeSectionThree: function(){
            console.log('changeSectionThree');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionFourChangeArea = false;
            this.sectionThreeChangeArea = true;
        },
        changeSectionFour: function(){
            console.log('changeSectionFour');
            this.logoChangeArea = false;
            this.sectionTwoChangeArea = false;
            this.sectionThreeChangeArea = false;
            this.sectionFourChangeArea = true;
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionTwoImage: function (event) {
            this.sectionTwoImageRaw = event.target.files[0];
            let input = this.$refs.sectionTwoImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionTwoImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadSectionThreeSideImage: function (event) {
            this.sectionThreeSideImageRaw = event.target.files[0];
            let input = this.$refs.sectionThreeSideImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewSectionThreeSideImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        fetchEditData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.createCustomerAccount,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log(response.data.data.template);
                    let template = JSON.parse(response.data.data.template);
                    this.template = template;
                    this.previewImage = this.template.logo;
                    this.previewSectionTwoImage = this.template.sectionTwoImage;
                    //this.previewImage = this.template.logo; //sectionTwoImage
                    console.log('this.template =', this.template);
                    // this.type = response.data.data.type;
                    // this.template = response.data.data.template;
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            try {
                if (!this.type) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please Select Type!"
                    });
                    return;
                }
                else if (!this.template) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter content!"
                    });
                    return;
                }
                let data = {
                    type: this.type,
                    template: this.template,
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.$router.push("/email-template");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async updateCms() {
            try {
                let formData =  new FormData();
                formData.append('logo', this.image ? this.image : this.template.logo);
                formData.append('logoBG', this.template.logoBG ? this.template.logoBG : '');

                formData.append('sectionTwoImage', this.sectionTwoImageRaw ? this.sectionTwoImageRaw : this.template.sectionTwoImage);
                formData.append('sectionTwoBG', this.template.sectionTwoBG ? this.template.sectionTwoBG : '');
                formData.append('sectionTwoFontColor', this.template.sectionTwoFontColor ? this.template.sectionTwoFontColor : '');
                formData.append('sectionTwoHeadingContent', this.template.sectionTwoHeadingContent ? this.template.sectionTwoHeadingContent : '');
                formData.append('sectionTwoShortDetail', this.template.sectionTwoShortDetail ? this.template.sectionTwoShortDetail : '');
                
                formData.append('sectionThreeSideImage', this.sectionThreeSideImageRaw ? this.sectionThreeSideImageRaw : this.template.sectionThreeSideImage);
                formData.append('sectionThreeBG', this.template.sectionThreeBG ? this.template.sectionThreeBG : '');
                formData.append('sectionThreeFontColor', this.template.sectionThreeFontColor ? this.template.sectionThreeFontColor : '');
                formData.append('sectionThreeHeadingContent', this.template.sectionThreeHeadingContent ? this.template.sectionThreeHeadingContent : '');
                formData.append('sectionThreeShortTitle', this.template.sectionThreeShortTitle ? this.template.sectionThreeShortTitle : '');
                formData.append('sectionThreeShortDetail', this.template.sectionThreeShortDetail ? this.template.sectionThreeShortDetail : '');
                formData.append('sectionThreeUsername', this.template.sectionThreeUsername ? this.template.sectionThreeUsername : '');
                formData.append('sectionThreePassword', this.template.sectionThreePassword ? this.template.sectionThreePassword : '');
                formData.append('sectionThreeFotterContent', this.template.sectionThreeFotterContent ? this.template.sectionThreeFotterContent : '');
                formData.append('sectionFourContent', this.template.sectionFourContent ? this.template.sectionFourContent : '');
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.createCustomerAccount,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                await this.fetchEditData();
                console.log(config);
                //this.$refs.backgroundImage.value = null;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}