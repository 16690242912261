import { authHeader } from "../../../../auth";

export default {
    name: "ShippingMethod",
    data() {
        return {
            singleSM: {},
            setURL: '',
            name: '',
            description: '',
            price: 0.00,
            sliderType: "",
            productList: [],
            giftCardList: [],
            sliderModel: "",
            sliderList: [],
            duplicate: false,
        }
    },
    async created() {
        document.title = "Golden Gal - Section Four Slider";

        await this.getProducts();
        await this.getGiftCards();
        await this.getSlider();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        getProducts: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productURL,
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.productList = response.data.data.length > 0 ? response.data.data : [];
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getGiftCards: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.giftCard.giftCardURL,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.giftCardList = response.data.data.length > 0 ? response.data.data : [];
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getSlider: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.cms.home.sectionFourSliderURL,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.sliderList = response.data.data
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        addSlider: async function () {
            try {
                if (!this.sliderModel) {
                    this.$swal.fire({
                        icon: 'info',
                        text: 'Please select product or gift card.'
                    })
                    return;
                } else {
                    this.duplicate = false
                    for (let i = 0; i < this.sliderList.length; i++) {
                        if (this.sliderList[i].detail.id == this.sliderModel.productOrGiftCardId) {
                            this.duplicate = true;
                            break;
                        }
                    }
                    if (this.duplicate == true) {
                        this.$swal.fire({
                            icon: 'info',
                            text: 'Already Added to slider.'
                        })
                        return;
                    }
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.home.sectionFourSliderURL,
                    data: this.sliderModel,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {
                        document.getElementById('closeButton').click();
                        this.sliderModel = "";
                        this.sliderType = "";
                        this.getSlider();
                        this.$swal.fire({
                            icon: 'success',
                            text: response.data.message
                        })
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        deleteSlider: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.cms.home.sectionFourSliderURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getSlider();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}