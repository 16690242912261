import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            featuredImage: "",
            data: [],
            brandList: [],
            brandValue: "",
            discountList: [],
            colorValue: [],
            colorsList: [],
            sizeValue: [],
            sizesList: [],
            tagValue: [],
            tagList: [],
            highlightValue: [],
            highlightList: [],
            materialValue: [],
            materialList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            productVideo: {
                video: ""
            },
            preview: {
                video: ""
            },
            disableButton: false,
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.product.productURL,
            imageUrl: this.$imageURL,
            singleProduct: {},
            // discount: {
            //     id: '',
            //     name: ''
            // },
            subCategory: "",
            parentCategory: "",
            childCategory: "",
            subCategoryList: [],
            childCategoryList: [],
            deletedImage: [],
            shortDescription: "",
            longDescription: "",
            vendor: "",
            vendorList: [],
            addedDetails: [{ id: "" }],
            selectedDetails: [{ title: "", description: "" }],
            productList: [],
            relatedProducts: []
        }

    },
    async mounted() {
        this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
        this.childCategoryList.push({ id: '', name: 'No Child-Category' });
        await this.getProductDetails();
        await this.getAllVarient();
        await this.getAllParentCategories();
        await this.getVendorList();
        await this.getDataList(this.siteUrl);
        this.productList = this.dataList.map(el => {
            return { value: { id: el.id, name: el.name }, label: el.name }
        })
    },
    methods: {
        checkName(data) {
            if (data.startsWith("https")) {
                return true;
            } else {
                return false;
            }
        },
        addDetails() {
            this.addedDetails.push({ id: "" });
            this.selectedDetails.push({ title: "", description: "" });
        },
        getVendorList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.vendor.requestURL
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.vendorList = response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        getProductDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    //headers: authHeader()
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data.length > 0) {
                            this.singleProduct = response.data.data[0];
                            console.log(this.singleProduct);
                            if (this.singleProduct.shortDescription) {
                                this.shortDescription = this.singleProduct.shortDescription;
                            }
                            if (this.singleProduct.longDescription) {
                                this.longDescription = this.singleProduct.longDescription;
                            }
                            if (this.singleProduct.parentCategoryId) {
                                await this.getSubCategoryList(this.singleProduct.parentCategoryId);
                                //this.parentCategory = { name: this.singleProduct.parentCategoryName, id: this.singleProduct.parentCategoryId }
                            }
                            if (this.singleProduct.subCategoryId) {
                                await this.getChildCategoryList(this.singleProduct.subCategoryId);
                                //this.subCategory = { name: this.singleProduct.subCategoryName, id: this.singleProduct.subCategoryId };
                            }
                            this.productImageList = this.singleProduct.productImages.map(el => {
                                return el.image;
                            });
                            this.previewProductImages = this.singleProduct.productImages.map(el => {
                                return el.image;
                            });
                            if (this.singleProduct.video) {
                                this.preview.video = this.singleProduct.video
                            }
                            this.colorValue = this.singleProduct.productColorVariants.map(el => {
                                return el.colorName;
                            });
                            this.sizeValue = this.singleProduct.productSizeVariants.map(el => {
                                return el.sizeName;
                            });
                            this.tagValue = this.singleProduct.productTagVariants.map(el => {
                                return el.tagName;
                            });
                            this.highlightValue = this.singleProduct.productHighlightVariants.map(el => {
                                return el.highlightName;
                            });
                            this.materialValue = this.singleProduct.productMaterialVariants.map(el => {
                                return el.materialName;
                            });
                            this.selectedDetails = this.singleProduct.extraDetails.length > 0 ? this.singleProduct.extraDetails : [{ title: "", description: "" }];
                            if (this.selectedDetails.length > 0) {
                                this.addedDetails = [];
                                for (let i = 0; i < this.selectedDetails.length; i++) {
                                    this.addedDetails.push({ id: "" });
                                }
                            }
                            // this.discount = {
                            //     id: this.singleProduct.discountId,
                            //     name: this.singleProduct.discountTitle
                            // }
                            if (this.singleProduct.relatedProducts.length > 0) {
                                this.relatedProducts = this.singleProduct.relatedProducts.map(el => {
                                    return { value: { id: el.id, name: el.name }, label: el.name }
                                })
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                    // console.log(error);
                });

            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage() {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        uploadProductImages() {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        uploadProductVideo(videoKey, previewVideoKey, ref, event) {
            this.productVideo.video = '';
            this.preview.video = '';
            this.productVideo[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview.video = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },

        getAllVarient: async function () {
            let url = this.$serverURL + this.$api.product.getVariants;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    console.log(response)
                    this.colorsList = response.data.productColor.map((el) => {
                        return { value: el.name, label: el.name }
                    });
                    this.sizesList = response.data.productSize.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    this.tagList = response.data.productTag.map((el) => {
                        return { value: el.name, label: el.name }
                    });
                    this.highlightList = response.data.productHighlight.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    this.materialList = response.data.productMaterial.map(el => {
                        return { value: el.name, label: el.name }
                    });
                    // this.brandList = response.data.brandData;
                    // this.discountList = response.data.discountData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },

        getSubCategoryList: async function (id, bool) {
            if (bool) {
                this.singleProduct.subCategoryName = "";
                this.singleProduct.childCategoryName = "";
            }
            this.subCategory = "";
            this.subCategoryList = [];
            this.childCategory = "";
            this.childCategoryList = [];
            this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
            this.childCategoryList.push({ id: '', name: 'No Child-Category' });
            let url = this.$serverURL + this.$api.productCategory.getSubCategoryByParentCategory + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subCategoryList = response.data.data;
                    this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
                    if (this.subCategoryList.length > 0) {
                        this.showSubCategory = true;
                    } else {
                        this.showSubCategory = false;
                    }
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getChildCategoryList: async function (id, bool) {
            if (bool) {
                this.singleProduct.childCategoryName = "";
            }
            this.childCategoryList = [];
            this.childCategory = "";
            this.childCategoryList.push({ id: '', name: 'No Child-Category' });
            let url = this.$serverURL + this.$api.productCategory.getChildCategoryBySubCategory + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                    this.childCategoryList.push({ id: '', name: 'No Child-Category' });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },

        // addDiscount: async function () {

        //     if (!this.discountTitle) {

        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter discount title!"
        //         });
        //     }
        //     else {
        //         let data = {
        //             discountTitle: this.discountTitle,
        //         };
        //         let config = {
        //             method: "POST",
        //             url: this.$serverURL + this.$api.product.productDiscountAPI,
        //             data: data,
        //             headers: {
        //                 "Authorization": authHeader()
        //             }
        //         };
        //         await this.$axios(config).then(async (response) => {
        //             if (response.status == 201) {

        //                 this.discount = {
        //                     id: response.data.data.id,
        //                     name: response.data.data.discountTitle
        //                 };
        //                 this.discountTitle = '';
        //                 await this.getAllVarient();
        //                 await this.changePrice();
        //             }
        //             else {

        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Failed! please try again."
        //                 });
        //             }
        //         }).catch(error => {

        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Failed! please try again." + error
        //             });
        //         });

        //     }
        // },
        // changePrice: function () {
        //     if (this.discount.name != 'None') {
        //         if (this.discount.name.includes("%")) {
        //             let converted_discount = parseFloat(this.discount.name) / 100.0;
        //             this.singleProduct.discountedPrice = this.singleProduct.price - this.singleProduct.price * converted_discount;
        //         }
        //         else {
        //             try {
        //                 let converted_discount = parseInt(this.discount.name)
        //                 this.singleProduct.discountedPrice = this.singleProduct.price - converted_discount;
        //             }
        //             catch (error) {
        //                 console.log(error);
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Please enter am amount or percentage"
        //                 });
        //             }
        //         }
        //     } else {
        //         this.singleProduct.discountedPrice = 0;
        //     }
        // },

        validate: function () {
            if (!this.singleProduct.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            if (!this.parentCategory && !this.singleProduct.parentCategoryName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Select category."
                });
                return;
            }
            // else if (!this.subCategory && !this.singleProduct.subCategoryName) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter sub category!"
            //     });
            //     return false;
            // } else if (!this.childCategory && !this.singleProduct.childCategoryName) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter child category!"
            //     });
            //     return false;
            // } 
            else if (!this.singleProduct.featuredImage && !this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (!this.singleProduct.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            }
            else if (!this.singleProduct.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            }
            return true;
        },
        updateProduct: async function () {
            this.$swal.showLoading();
            if (this.validate()) {
                let formData = new FormData();
                formData.append("name", this.singleProduct.name);
                formData.append("urlSlug", this.singleProduct.urlSlug);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                if (this.parentCategory) {
                    formData.append("parentCategoryId", this.parentCategory.id);
                    formData.append("parentCategoryName", this.parentCategory.name);
                } else {
                    formData.append("parentCategoryId", this.singleProduct.parentCategoryId);
                    formData.append("parentCategoryName", this.singleProduct.parentCategoryName);
                }
                if (this.subCategory) {
                    if (this.subCategory.name != null) {
                        formData.append("subCategoryId", this.subCategory.id);
                        formData.append("subCategoryName", this.subCategory.name);
                    }
                } else {
                    if (this.singleProduct.subCategoryName != null) {
                        formData.append("subCategoryId", this.singleProduct.subCategoryId);
                        formData.append("subCategoryName", this.singleProduct.subCategoryName);
                    }

                }
                if (this.childCategory) {
                    if (this.childCategory.name.length != null) {
                        formData.append("childCategoryId", this.childCategory.id);
                        formData.append("childCategoryName", this.childCategory.name);
                    }

                } else {
                    if (this.singleProduct.childCategoryName != null) {
                        formData.append("childCategoryId", this.singleProduct.childCategoryId);
                        formData.append("childCategoryName", this.singleProduct.childCategoryName);
                    }
                }
                if (this.vendor) {
                    if (this.vendor.name.length != null) {
                        formData.append("vendorName", this.vendor.name);
                    }
                } else {
                    if (this.singleProduct.vendorName != null) {
                        formData.append("vendorName", this.singleProduct.vendorName);
                    }
                }
                if (this.featuredImage) {
                    formData.append("featuredImage", this.featuredImage);
                } else {
                    formData.append("featuredImage", this.singleProduct.featuredImage);
                }
                this.selectedDetails.map(el => {
                    let dt = {
                        title: el.title,
                        description: el.description
                    }
                    formData.append("extraDetail", JSON.stringify(dt));
                })
                formData.append("sku", this.singleProduct.sku);
                formData.append("price", this.singleProduct.price);
                formData.append("rating", this.singleProduct.rating);
                formData.append("stock", this.singleProduct.stock);
                // formData.append("quantityReorder", this.singleProduct.quantityReorder);
                // formData.append("discountedPrice", this.singleProduct.discountedPrice);
                // if (this.brandValue) {
                //     formData.append("brandName", this.brandValue);
                // } else {
                //     formData.append("brandName", this.singleProduct.brandName);
                // }

                // if (this.discount) {
                //     formData.append("discountId", this.discount.id != null ? this.discount.id : '');
                //     formData.append("discountTitle", (this.discount?.name && this.discount.name != 'None') ? this.discount.name : '');
                // } else {
                //     formData.append("discountId", this.singleProduct.discountId);
                //     formData.append("discountTitle", this.singleProduct.discountTitle);
                // }
                this.relatedProducts.map(el => {
                    let dt = {
                        relatedProductID: el.value.id,
                        relatedProductName: el.value.name
                    }
                    formData.append("relatedProducts", JSON.stringify(dt));
                })
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                });
                this.deletedImage.map((el) => {
                    formData.append("deletedImage", el);
                });
                formData.append('video', this.productVideo.video);
                this.colorValue.map((el) => {
                    formData.append("color[]", el);
                });
                this.sizeValue.map((el) => {
                    formData.append("size[]", el);
                });
                this.tagValue.map((el) => {
                    formData.append("tag[]", el);
                });
                this.highlightValue.map((el) => {
                    formData.append("highlight[]", el);
                });
                this.materialValue.map((el) => {
                    formData.append("material[]", el);
                });
                // this.colorValue.map((el) => {
                //     formData.append("color[]", el);
                // });
                // this.sizeValue.map((el) => {
                //     formData.append("size[]", el);
                // });
                formData.append("cost", this.singleProduct.cost);
                formData.append("weight", this.singleProduct.weight);
                formData.append("isFeatured", this.singleProduct.isFeatured ? 1 : 0);
                formData.append("isDeactive", this.singleProduct.isDeactive ? 1 : 0);
                formData.append("isMerch", this.singleProduct.isMerch ? 1 : 0);
                formData.append("isGiftGuide", this.singleProduct.isGiftGuide ? 1 : 0);
                formData.append("isReadyToShip", this.singleProduct.isReadyToShip ? 1 : 0);
                let config = {
                    method: "PATCH",
                    data: formData,
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });
            }
        },

        removeUploadedProductImage(index) {
            this.previewProductImages.splice(index, 1);
            if (typeof this.productImageList[index] === "string") {
                if (this.productImageList[index].startsWith("https")) {
                    this.deletedImage.push(this.productImageList[index]);
                }
            }
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        }
    },
    watch: {

    }
}