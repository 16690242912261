<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Order</p>
                                    <h4 class="my-1">{{ totalOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <img src="/core/assets/svg/1.svg" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Pending</p>
                                    <h4 class="my-1">{{ totalPendingOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <img src="/core/assets/svg/track-blue.svg" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Delivered</p>
                                    <h4 class="my-1">{{ totalDeliveredOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <img src="/core/assets/svg/track-green.svg" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Cancelled</p>
                                    <h4 class="my-1">{{ totalCancelledOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <img src="/core/assets/svg/order-cancel.svg" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0 text-uppercase">Order List</h6>
                </div>
                <div class="col-6 float-right">
                    <a role="button" class="btn btn-warning btn-sm m-1 mb-md-0" style="float: right" @click="exportOrders()">
                        <i class="fa-solid fa-file-export"></i> Export
                    </a>
                    <a role="button" data-bs-toggle="modal" data-bs-target="#filterModal" id="filterTable" class="btn btn-success btn-sm m-1 mb-md-0" style="float: right">
                        <i class="bx bx-filter text-white float-left"></i> Filter
                    </a>
                </div>
            </div>
            <hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Order Id</th>
                                    <th>Customer Name</th>
                                    <th>Total Price</th>
                                    <th>Total Quantity</th>
                                    <th>Order Date</th>
                                    <th>Payment Method</th>
                                    <th>Payment Status</th>
                                    <th>Shipping Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in orderList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)">#{{ data.orderId }}</a>
                                    </td>
                                    <td>{{ data.customerName }}</td>
                                    <td>
                                        {{ (data.total-0)+(data.shippingMethodCost-0)+(data.salesTaxAmount-0) }}
                                    </td>
                                    <td>{{ data.totalQuantity }}</td>
                                    <td>{{ $filters.formatOrderDate(data.createdAt) }}</td>
                                    <td>{{ data.paymentMethodName }}</td>
                                    <td>
                                        <select class="form-select" aria-label="Default select example" v-model="data.paymentStatus" :onchange="() => { updateOrder(data) }">
                                            <option value="PENDING">PENDING</option>
                                            <option value="PAID">PAID</option>
                                            <option value="PARTIAL PAID">PARTIAL PAID</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-select" aria-label="Default select example" v-model="data.deliveryStatus" :onchange="() => { updateOrder(data) }">
                                            <option style="display: none" selected value="">
                                                {{ data.deliveryStatus }}
                                            </option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="PROCESSING">PROCESSING</option>
                                            <option value="SHIPPED">SHIPPED</option>
                                            <option value="DELIVERED">DELIVERED</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)" class="edit-button">
                                                <i class="bx bxs-show text-white"></i>
                                            </a>
                                            <a href="javascript:void(0);" v-on:click="deleteOrder(data.orderId)" class="ms-3 delete-button">
                                                <i class="bx bxs-trash text-white"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Order Id</th>
                                    <th>Customer Name</th>
                                    <th>Total Price</th>
                                    <th>Total Quantity</th>
                                    <th>Order Date</th>
                                    <th>Payment Method</th>
                                    <th>Payment Status</th>
                                    <th>Shipping Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Filter Modal -->
            <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="filterModalLabel">Filtering</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form class="filter_form">
                                <div class="row mb-3">
                                    <div class="col-6 col-sm-6">
                                        <label class="form-label">Payment Status:</label>
                                        <select v-model="filter.paymentStatus" class="form-select">
                                            <option value="">All</option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="PAID">PAID</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <label class="form-label">Delivery Status:</label>
                                        <select v-model="filter.deliveryStatus" class="form-select">
                                            <option value="">All</option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="PROCESSING">PROCESSING</option>
                                            <option value="SHIPPED">SHIPPED</option>
                                            <option value="DELIVERED">DELIVERED</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Start Date:</label>
                                        <input v-model="filter.startDate" type="date" class="form-control"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">End Date:</label>
                                        <input v-model="filter.endDate" type="date" class="form-control"/>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Customer Name:</label>
                                        <Multiselect v-model="filter.userName" id="inputVendor" :options="userList" :searchable="true"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Order Id:</label>
                                        <Multiselect v-model="filter.orderId" id="inputVendor" :options="filteredOrderIdList" :searchable="true"/>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="text-center">
                                        <button type="submit" @click="clearFilter()" class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal">
                                            Clear
                                        </button>
                                        <button type="button" class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal" @click="validateFilter">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filter Modal -->
        </div>
    </div>
</template>

<script src='../js/order-list.js'></script>

<style scoped>
.edit-button {
    background-color: #49abab;
}

.confirm-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>