<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Add Section Six Content</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Section Six Content</h5>
          <hr />
          <div class="form-body mt-4">
            <form @submit.prevent="addContent()">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label" @click="addContent">Title:</label>
                      <input
                        type="text"
                        v-model="title"
                        class="form-control"
                        placeholder="Enter title"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Image: <b>[Preferred Image Size: 65X65, Max Upload Limit: 1MB]</b></label>
                      <input
                        class="form-control"
                        @change="onImageChange"
                        type="file"
                        ref="imageFile"
                      />
                    </div>
                    <div class="mb-3">
                      <div class="col-md-3">
                        <img v-if="previewImage" :src="previewImage" height="100" width="150" />
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Button Text:</label>
                      <input type="text" v-model="buttonText" class="form-control" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Button Link:</label>
                      <input type="text" v-model="buttonLink" class="form-control" />
                    </div>
                    <div class="mb-3">
                      <LvColorpicker
                        label="Button Color:"
                        v-model="buttonColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-home-section-six.js"></script>