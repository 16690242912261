import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import SiteTitleCMS from "../components/CMS/HeaderFooterCMS/template/siteTitleCms";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import Customer from "../components/user/template/Customer";
import Admin from "../components/user/template/Admin";
import ProductReview from "../components/Product/ProductReview/template/ProductReview";
import Product from "../components/Product/ProductList/template/Product";
import ProductReorder from "../components/Product/ProductReorder/template/ProductReorder";
import AddProduct from "../components/Product/AddProduct/template/AddProduct";
import UpdateProduct from "../components/Product/UpdateProduct/template/UpdateProduct";
import Brand from "../components/Product/Brand/template/Brand";
import ImportBrand from "../components/Product/Brand/template/ImportBrand";
import Size from "../components/Product/Size/template/Size";
import Color from "../components/Product/Color/template/Color";
import Discount from "../components/Product/Discount/template/Discount";
import ParentCategory from "../components/Product/ProductCategory/template/ParentCategory";
import SubCategory from "../components/Product/ProductCategory/template/SubCategory";
import ContactAddress from "../components/Contact/template/ContactAddress";
import ContactMessage from "../components/Contact/template/ContactMessage";
import AboutServices from "../components/AboutUs/AboutServices/template/AboutServices";
import AboutContent from "../components/AboutUs/AboutContent/template/AboutContent";
import Profile from "../components/profile/template/Profile";
import OrderList from "../components/Order/OrderList/template/OrderList";
import CreateOrder from "../components/Order/CreateOrder/template/CreateOrder";
import OrderDetails from "../components/Order/OrderDetails/template/OrderDetails";
import PrivacyPolicy from "../components/policies/privacy_policy/template/PrivacyPolicy";
import ReturnPageCMS from "../components/policies/return_page/template/ReturnPageCMS";
import TermsAndCondition from "../components/policies/terms_and_condition/template/TermsAndCondition";
import ProductBulkUpload from "../components/Product/ProductBulkUpload/template/ProductBulkUpload";
import ProductStockIn from "../components/Product/ProductStockIn/template/ProductStockIn";
import StockList from "../components/Product/StockList/template/StockList";
import StockDetail from "../components/Product/StockDetail/template/StockDetail";
import Ups from "../components/ShippingSettings/Ups/template/Ups";
import Fedex from "../components/ShippingSettings/Fedex/template/Fedex";
import PaymentSetting from "../components/PaymentSettings/template/PaymentSetting";
import ChildCategory from "../components/Product/ProductCategory/template/ChildCategory";
import StyleThreeSection from "../components/CMS/style/template/StyleThreeSection";
import StyleFourSection from "../components/CMS/style/template/StyleFourSection";
import StyleSectionOne from "../components/CMS/style/template/StyleSectionOne";
import StyleSectionFive from "../components/CMS/style/template/StyleSectionFive";
import HomePageCMS from "../components/CMS/home/template/HomePageCMS";
import GalleryPageCMS from "../components/CMS/gallery/template/GalleryPageCMS";
import HomePageSectionThree from "../components/CMS/home/template/HomePageSectionThree";
import HomePageSectionFour from "../components/CMS/home/template/HomePageSectionFour";
import HomePageSectionSix from "../components/CMS/home/template/HomePageSectionSix";
import HomePageSectionSeven from "../components/CMS/home/template/HomePageSectionSeven";
import ContactPageCMS from "../components/CMS/Contact/template/ContactPageCMS";
import NewsletterCMS from "../components/CMS/PopUpModalCMS/NewsletterModalCMS/template/NewsletterCMS";
import FooterCMS from "../components/CMS/HeaderFooterCMS/template/FooterCms";
import AboutPageCMS from "../components/CMS/AboutUs/AboutPageCMS/template/AboutPageCMS";
import ProductCMS from "../components/CMS/Product/ProductCMS/template/ProductCMS";
import CustomerReview from "../components/home/template/CustomerReview";
import AddCustomerReview from "../components/home/template/AddCustomerReview";
import EditCustomerReview from "../components/home/template/EditCustomerReview";
import HomeSlider from "../components/home/template/HomeSlider";
import AddHomeSlider from "../components/home/template/AddHomeSlider";
import EditHomeSlider from "../components/home/template/EditHomeSlider";
import SectionTwo from "../components/home/template/SectionTwo";
import AddSectionTwo from "../components/home/template/AddSectionTwo";
import EditSectionTwo from "../components/home/template/EditSectionTwo";
import Gallery from "../components/gallery/template/Gallery";
import AddGallery from "../components/gallery/template/AddGallery";
import EditGallery from "../components/gallery/template/EditGallery";
import SocialLinks from "../components/CMS/Common/SocialLinks/template/SocialLinks";
import Material from "../components/Product/material/template/Material";
import Highlight from "../components/Product/highlight/template/Highlight";
import Tag from "../components/Product/tag/template/Tag";
import Subscriber from "../components/Subscriber/template/Subscriber";
import GiftCard from "../components/GiftCard/template/GiftCard";
import AddGiftCard from "../components/GiftCard/template/AddGiftCard";
import EditGiftCard from "../components/GiftCard/template/EditGiftCard";
import Glossary from "../components/learn/template/Glossary";
import LearnPageCMS from "../components/CMS/learn/template/LearnPageCMS";
import LearnTab from "../components/learn-tab/template/LearnTab";
import AddGlossary from "../components/learn/template/AddGlossary";
import EditGlossary from "../components/learn/template/EditGlossary";
import GiftCardCMS from "../components/CMS/GiftCard/template/GiftCardCMS";
import GiftGuideCMS from "../components/CMS/GiftGuide/template/GiftGuideCMS";
import PromotionalPageCMS from "../components/CMS/Promotional/template/PromotionalPageCMS";
import RingSize from "../components/ring-size/template/RingSize";
import BraceletsSize from "../components/bracelets-size/template/BraceletsSize";
import NecklaceSize from "../components/necklace-size/template/NecklaceSize";
import PaymentConfiguration from "../components/payment-configuration/template/PaymentConfiguration";
import PaymentList from "../components/PaymentList/template/PaymentList";
import Coupon from "../components/Coupon/template/Coupon";
import Vendor from "../components/vendor/template/Vendor";
import HomeComponentCMS from "../components/ComponentCMS/Home/template/HomeComponentCMS";
import ShopComponentCMS from "../components/ComponentCMS/shop/template/ShopComponentCMS";
import GalleryComponentCMS from "../components/ComponentCMS/Gallery/template/GalleryComponentCMS";
import StyleComponentCMS from "../components/ComponentCMS/style/template/StyleComponentCMS";
import AboutComponentCMS from "../components/ComponentCMS/about/template/AboutComponentCMS";
import CollectionComponentCMS from "../components/ComponentCMS/collection/template/CollectionComponentCMS";
import LearnComponentCMS from "../components/ComponentCMS/learn/template/LearnComponentCMS";
import ContactComponentCMS from "../components/ComponentCMS/contact/template/ContactComponentCMS";
import PromotionalComponentCMS from "../components/ComponentCMS/promotional/template/PromotionalComponentCMS";
import GiftCardComponentCMS from "../components/ComponentCMS/gift-card/template/GiftCardComponentCMS";
import PrivacyPolicyComponentCMS from "../components/ComponentCMS/privacy-policy/template/PrivacyPolicyComponentCMS";
import ProductDetailsComponentCMS from "../components/ComponentCMS/product-details/template/ProductDetailsComponentCMS";
import GiftCardDetailsComponentCMS from "../components/ComponentCMS/gift-card-details/template/GiftCardDetailsComponentCMS";
import ReturnPolicyComponentCMS from "../components/ComponentCMS/return-policy/template/ReturnPolicyComponentCMS";
import TermsPolicyComponentCMS from "../components/ComponentCMS/terms-policy/template/TermsPolicyComponentCMS";
import AddCollectionPage from "../components/collection-page/template/AddCollectionPage";
import EditCollectionPage from "../components/collection-page/template/EditCollectionPage";
import CollectionPage from "../components/collection-page/template/CollectionPage";
import ProductProfitReport from "../components/ProductProfitReport/template/ProductProfitReport";
import GiftCardProfitReport from "../components/GiftCardProfitReport/template/GiftCardProfitReport";
import OrderReport from "../components/OrderReport/template/OrderReport";
import ShopGuideCMS from "../components/CMS/shop-guide/template/ShopGuideCMS";
import QuickLinks from "../components/CMS/Common/quick-links/template/QuickLinks";
import ShippingMethods from "../components/shipping-methods/template/ShippingMethods";
import SalesTax from "../components/sales-tax/template/SalesTax";
import HoverColorCms from "../components/ComponentCMS/hover-color-cms/template/HoverColorCms";
import SectionFourSlider from "../components/CMS/section-four-slider/template/SectionFourSlider";
import AddHomeSectionSix from "../components/CMS//home/template/AddHomeSectionSix";
import UpdateHomeSectionSix from "../components/CMS//home/template/UpdateHomeSectionSix";
import AddLearnTab from "../components/learn-tab/template/AddLearnTab";
import EditLearnTab from "../components/learn-tab/template/EditLearnTab";
import ProductHint from "../components/product-hint/template/ProductHint";

import EmailTemplate from "../components/CMS/EmailTemplate/template/EmailTemplate";
import AddEmailTemplate from "../components/CMS/EmailTemplate/template/AddEmailTemplate";
import EditEmailTemplate from "../components/CMS/EmailTemplate/template/EditEmailTemplate";
import CreateAccountByAdminEmail from "../components/CMS/EmailTemplate/template/CustomerEmailByAdminEmailTemplate";
import CreateCustomerAccountEmail from "../components/CMS/EmailTemplate/template/CustomerAccountEmailTemplate";
import OrderPlaceEmailTemplate from "../components/CMS/EmailTemplate/template/CustomerOrderPlacedTemplate";
import CartCMS from "../components/CMS/cart/template/CartCMS";
import Unapproved from "../components/ReturnProducts/template/Unapproved"
import Approved from "../components/ReturnProducts/template/Approved"
import Rejected from "../components/ReturnProducts/template/Rejected"
import ProductMeta from "../components/CMS/HeaderFooterCMS/template/productMeta";
import ProductDetailsCms from "../components/CMS/Product/ProductDetailsCMS/template/ProductDetailsCMS.vue";
import CartComponentCMS from "../components/ComponentCMS/cart/template/CartComponentCMS.vue";
import ProductVarientModalCms from "../components/ComponentCMS/product-varient-modal/template/productVarientModal.vue"
import ItemsOnSale from "../components/Product/ItemsOnSale/template/ItemsOnSale";
import ShopByOccasion from "../components/Product/ShopByOccasion/template/ShopByOccasion";

//Elementor
import CreateCustomerAccountEmailElementor from "../components/CMS/EmailTemplate/template/elementor/CustomerSignup/CustomerAccountEmailTemplateElementor.vue";
import CreateAdminAccountEmailElementor from "../components/CMS/EmailTemplate/template/elementor/AdminSignup/AdminAccountEmailTemplateElementor.vue";
import ProcessingOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ProcessingOrder/ProcessingOrderEmailTemplate.vue";
import ShippedOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ShippedOrder/ShippedOrderEmailTemplate.vue";
import DeliveredOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/DeliveredOrder/DeliveredOrderEmailTemplate.vue";
import PlaceOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/PlaceOrder/PlaceOrderEmailTemplate.vue";
import ForgotPasswordEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ForgotPassword/ForgotPasswordEmailTemplate.vue";
import ProductHintEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ProductHint/ProductHintEmailTemplateElementor.vue";
import AdminNotifyEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/AdminNotify/AdminNotifyEmailTemplateElementor.vue";


const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
    { path: "/page-title-cms", name: "pageTitleCMS", component: SiteTitleCMS, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },
    { path: "/admins", name: "Admin", component: Admin, },
    { path: "/customers", name: "Customer", component: Customer, beforeEnter: guard },

    // Product
    { path: "/product-size", name: "Size", component: Size, beforeEnter: guard },
    { path: "/product-color", name: "Color", component: Color, beforeEnter: guard },
    { path: "/product-material", name: "Material", component: Material, beforeEnter: guard },
    { path: "/product-highlight", name: "Highlight", component: Highlight, beforeEnter: guard },
    { path: "/product-tag", name: "Tag", component: Tag, beforeEnter: guard },
    { path: "/product-list", name: "Product", component: Product, beforeEnter: guard },
    { path: "/product-reorder", name: "ProductReorder", component: ProductReorder, beforeEnter: guard },
    { path: "/product-review", name: "ProductReview", component: ProductReview, beforeEnter: guard },
    { path: "/product-brand", name: "Brand", component: Brand, beforeEnter: guard },
    { path: "/brand-import", name: "ImportBrand", component: ImportBrand, beforeEnter: guard },
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-discount", name: "Discount", component: Discount, beforeEnter: guard },
    { path: "/category", name: "ParentCategory", component: ParentCategory, beforeEnter: guard },
    { path: "/sub-category", name: "SubCategory", component: SubCategory, beforeEnter: guard },
    { path: "/child-category", name: "ChildCategory", component: ChildCategory, beforeEnter: guard },
    { path: "/product-bulk-upload", name: "ProductBulkUpload", component: ProductBulkUpload, beforeEnter: guard },
    { path: "/stock-in-product", name: "ProductStockIn", component: ProductStockIn, beforeEnter: guard },
    { path: "/stock-list", name: "StockList", component: StockList, beforeEnter: guard },
    { path: "/stock-detail/:id", name: "StockDetail", component: StockDetail, beforeEnter: guard },
    { path: "/items-on-sale", name: "ItemsOnSale", component: ItemsOnSale, beforeEnter: guard },
    { path: "/shop-by-occasion", name: "ShopByOccasion", component: ShopByOccasion, beforeEnter: guard },

    // Contact
    { path: "/contact-address", name: "ContactAddress", component: ContactAddress, beforeEnter: guard },
    { path: "/contact-message", name: "ContactMessage", component: ContactMessage, beforeEnter: guard },

    //Product Hint
    { path: "/product-hint", name: "ProductHint", component: ProductHint, beforeEnter: guard },
    
    //About Us
    { path: "/about-services", name: "AboutServices", component: AboutServices, beforeEnter: guard },
    { path: "/about-content", name: "AboutContent", component: AboutContent, beforeEnter: guard },

    // Newsletter Subscriber
    { path: "/subscriber", name: "Subscriber", component: Subscriber, beforeEnter: guard },

    // Gallery
    { path: "/gallery", name: "Gallery", component: Gallery, beforeEnter: guard },
    { path: "/add-gallery", name: "AddGallery", component: AddGallery, beforeEnter: guard },
    { path: "/edit-gallery/:id", name: "EditGallery", component: EditGallery, beforeEnter: guard },

    // Collection Page
    { path: "/collection-page", name: "CollectionPage", component: CollectionPage, beforeEnter: guard },
    { path: "/add-collection-page", name: "AddCollectionPage", component: AddCollectionPage, beforeEnter: guard },
    { path: "/edit-collection-page/:id", name: "EditCollectionPage", component: EditCollectionPage, beforeEnter: guard },

    // Vendor
    { path: "/vendor", name: "Vendor", component: Vendor, beforeEnter: guard },

    // Shipping - method
    { path: "/shipping-methods", name: "ShippingMethods", component: ShippingMethods, beforeEnter: guard },

    // Sales - Tax
    { path: "/sales-tax", name: "SalesTax", component: SalesTax, beforeEnter: guard },

     // Reports
     { path: "/product-profit-report", name: 'ProductProfitReport', component: ProductProfitReport, beforeEnter: guard },
     { path: "/gift-card-report", name: 'GiftCardProfitReport', component: GiftCardProfitReport, beforeEnter: guard },

    // Gift card
    { path: "/gift-card", name: "GiftCard", component: GiftCard, beforeEnter: guard },
    { path: "/add-gift-card", name: "AddGiftCard", component: AddGiftCard, beforeEnter: guard },
    { path: "/edit-gift-card/:id", name: "EditGiftCard", component: EditGiftCard, beforeEnter: guard },

    // Learn
    { path: "/learn-page-cms", name: "LearnPageCMS", component: LearnPageCMS, beforeEnter: guard },
    { path: "/learn-tab", name: "LearnTab", component: LearnTab, beforeEnter: guard },
    { path: "/add-learn-page-tab", name: "AddLearnTab", component: AddLearnTab, beforeEnter: guard },
    { path: "/edit-learn-page-tab/:id", name: "EditLearnTab", component: EditLearnTab, beforeEnter: guard },
    { path: "/glossary", name: "Glossary", component: Glossary, beforeEnter: guard },
    { path: "/add-glossary", name: "AddGlossary", component: AddGlossary, beforeEnter: guard },
    { path: "/edit-glossary/:id", name: "EditGlossary", component: EditGlossary, beforeEnter: guard },
    { path: "/ring-size", name: "RingSize", component: RingSize, beforeEnter: guard },
    { path: "/bracelets-size", name: "BraceletsSize", component: BraceletsSize, beforeEnter: guard },
    { path: "/necklace-size", name: "NecklaceSize", component: NecklaceSize, beforeEnter: guard },

    // CMS
    { path: "/style-section-one-cms", name: "StyleSectionOne", component: StyleSectionOne, beforeEnter: guard },
    { path: "/style-section-three-cms", name: "StyleThreeSection", component: StyleThreeSection, beforeEnter: guard },
    { path: "/style-section-four-cms", name: "StyleFourSection", component: StyleFourSection, beforeEnter: guard },
    { path: "/style-section-five-cms", name: "StyleSectionFive", component: StyleSectionFive, beforeEnter: guard },
    { path: "/home-page-cms", name: "HomePageCMS", component: HomePageCMS, beforeEnter: guard },
    { path: "/home-page-section-three-cms", name: "HomePageSectionThree", component: HomePageSectionThree, beforeEnter: guard },
    { path: "/home-page-section-four-cms", name: "HomePageSectionFour", component: HomePageSectionFour, beforeEnter: guard },
    { path: "/home-page-section-six-cms", name: "HomePageSectionSix", component: HomePageSectionSix, beforeEnter: guard },
    { path: "/home-page-section-seven-cms", name: "HomePageSectionSeven", component: HomePageSectionSeven, beforeEnter: guard },
    { path: "/cart-component-cms", name: "CartComponentCMS", component: CartComponentCMS, beforeEnter: guard },
    { path: "/contact-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },
    { path: "/contact-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },
    { path: "/newsletter-cms", name: "NewsletterCMS", component: NewsletterCMS, beforeEnter: guard },
    { path: "/footer-cms", name: "FooterCMS", component: FooterCMS, beforeEnter: guard },
    { path: "/about-page-cms", name: "AboutPageCMS", component: AboutPageCMS, beforeEnter: guard },
    { path: "/gallery-page-cms", name: "GalleryPageCMS", component: GalleryPageCMS, beforeEnter: guard },
    { path: "/product-cms", name: "ProductCMS", component: ProductCMS, beforeEnter: guard },
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    { path: "/quick-links", name: "QuickLinks", component: QuickLinks, beforeEnter: guard },
    { path: "/gift-card-cms", name: "GiftCardCMS", component: GiftCardCMS, beforeEnter: guard },
    { path: "/cart-cms", name: "CartCMS", component: CartCMS, beforeEnter: guard },
    { path: "/gift-guide-cms", name: "GiftGuideCMS", component: GiftGuideCMS, beforeEnter: guard },
    { path: "/shop-guide-cms", name: "ShopGuideCMS", component: ShopGuideCMS, beforeEnter: guard },
    { path: "/promotional-page-cms", name: "PromotionalPageCMS", component: PromotionalPageCMS, beforeEnter: guard },
    { path: "/payment-configuration", name: "PaymentConfiguration", component: PaymentConfiguration, beforeEnter: guard },
    { path: "/home-component-cms", name: "HomeComponentCMS", component: HomeComponentCMS, beforeEnter: guard },
    { path: "/section-four-slider-cms", name: "SectionFourSlider", component: SectionFourSlider, beforeEnter: guard },
    { path: "/product-meta", name: "ProductMeta", component: ProductMeta, beforeEnter: guard },
    { path: "/product-details-cms", name: "ProductDetailsCms", component: ProductDetailsCms, beforeEnter: guard },
    { path: "/product-varient-modal", name: "ProductVarientModalCms", component: ProductVarientModalCms, beforeEnter: guard },

    //Email Template cms

    { path: "/email-template", name: "EmailTemplate", component: EmailTemplate, beforeEnter: guard },
    { path: "/add-email-template", name: "AddEmailTemplate", component: AddEmailTemplate, beforeEnter: guard },
    { path: "/update-email-template/:id", name: "EditEmailTemplate", component: EditEmailTemplate, beforeEnter: guard },
    { path: "/create-account-by-admin-email-template", name: "CreateAccountByAdminEmail", component: CreateAccountByAdminEmail, beforeEnter: guard },
    { path: "/create-account-email-template", name: "CreateAccountEmail", component: CreateCustomerAccountEmail, beforeEnter: guard },
    { path: "/order-placed-email-template", name: "OrderPlacedEmail", component: OrderPlaceEmailTemplate, beforeEnter: guard },
    
    // Elementor
    { path: "/create-customer-signup-email-template-elementor", name: "CreateCustomerAccountEmailElementor", component: CreateCustomerAccountEmailElementor, beforeEnter: guard },
    { path: "/create-admin-signup-email-template-elementor", name: "CreateAdminAccountEmailElementor", component: CreateAdminAccountEmailElementor, beforeEnter: guard },
    { path: "/processing-order-email-template", name: "ProcessingOrderEmailTemplate", component: ProcessingOrderEmailTemplate, beforeEnter: guard },
    { path: "/shipped-order-email-template", name: "ShippedOrderEmailTemplate", component: ShippedOrderEmailTemplate, beforeEnter: guard },
    { path: "/delivered-order-email-template", name: "DeliveredOrderEmailTemplate", component: DeliveredOrderEmailTemplate, beforeEnter: guard },
    { path: "/place-order-email-template", name: "PlaceOrderEmailTemplate", component: PlaceOrderEmailTemplate, beforeEnter: guard },
    { path: "/forgot-password-email-template", name: "ForgotPasswordEmailTemplate", component: ForgotPasswordEmailTemplate, beforeEnter: guard },
    { path: "/product-hint-email-template", name: "ProductHintEmailTemplate", component: ProductHintEmailTemplate, beforeEnter: guard },
    { path: "/new-customer-admin-email-template", name: "AdminNotifyEmailTemplate", component: AdminNotifyEmailTemplate, beforeEnter: guard },
    

    // Home
    { path: "/customer-review", name: "CustomerReview", component: CustomerReview, beforeEnter: guard },
    { path: "/add-customer-review", name: "AddCustomerReview", component: AddCustomerReview, beforeEnter: guard },
    { path: "/edit-customer-review/:id", name: "EditCustomerReview", component: EditCustomerReview, beforeEnter: guard },
    { path: "/home-slider", name: "HomeSlider", component: HomeSlider, beforeEnter: guard },
    { path: "/add-home-slider", name: "AddHomeSlider", component: AddHomeSlider, beforeEnter: guard },
    { path: "/edit-home-slider/:id", name: "EditHomeSlider", component: EditHomeSlider, beforeEnter: guard },
    { path: "/section-two", name: "SectionTwo", component: SectionTwo, beforeEnter: guard },
    { path: "/add-section-two", name: "AddSectionTwo", component: AddSectionTwo, beforeEnter: guard },
    { path: "/edit-section-two/:id", name: "EditSectionTwo", component: EditSectionTwo, beforeEnter: guard },
    
    { path: "/add-home-section-six", name: "AddHomeSectionSix", component: AddHomeSectionSix, beforeEnter: guard },
    { path: "/update-home-section-six/:id", name: "UpdateHomeSectionSix", component: UpdateHomeSectionSix, beforeEnter: guard },

    // Order
    { path: "/create-order", name: "CreateOrder", component: CreateOrder, beforeEnter: guard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },
    { path: "/order-list", name: "OrderList", component: OrderList, beforeEnter: guard },
    { path: "/payment-list", name: "PaymentList", component: PaymentList, beforeEnter: guard },
    { path: "/coupon", name: "Coupon", component: Coupon, beforeEnter: guard },
    { path: "/order-report", name: "OrderReport", component: OrderReport, beforeEnter: guard },

    //policies
    { path: "/privacy-policy", name: "Privacy Policy", component: PrivacyPolicy, beforeEnter: guard },
    { path: "/return-policy", name: "ReturnPageCMS", component: ReturnPageCMS, beforeEnter: guard },
    { path: "/terms&condition", name: "Terms and Condition", component: TermsAndCondition, beforeEnter: guard },

    /* Error and Unauthorized routes */
    { path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
    { path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
    { path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },

    //shipping settins
    { path: "/ups-settings", name: 'Ups', component: Ups, beforeEnter: guard },
    { path: "/fedex-settings", name: 'Fedex', component: Fedex, beforeEnter: guard },

    //payment setting
    { path: "/payment-settings", name: 'PaymentSetting', component: PaymentSetting, beforeEnter: guard },
    { path: "/payment-list", name: 'PaymentList', component: PaymentList, beforeEnter: guard },

    // Component CMS
    { path: "/shop-component-cms", name: 'ShopComponentCMS', component: ShopComponentCMS, beforeEnter: guard },
    { path: "/style-component-cms", name: 'StyleComponentCMS', component: StyleComponentCMS, beforeEnter: guard },
    { path: "/about-component-cms", name: 'AboutComponentCMS', component: AboutComponentCMS, beforeEnter: guard },
    { path: "/collection-component-cms", name: 'CollectionComponentCMS', component: CollectionComponentCMS, beforeEnter: guard },
    { path: "/gallery-component-cms", name: 'GalleryComponentCMS', component: GalleryComponentCMS, beforeEnter: guard },
    { path: "/learn-component-cms", name: 'LearnComponentCMS', component: LearnComponentCMS, beforeEnter: guard },
    { path: "/contact-component-cms", name: 'ContactComponentCMS', component: ContactComponentCMS, beforeEnter: guard },
    { path: "/promotional-component-cms", name: 'PromotionalComponentCMS', component: PromotionalComponentCMS, beforeEnter: guard },
    { path: "/gift-card-component-cms", name: 'GiftCardComponentCMS', component: GiftCardComponentCMS, beforeEnter: guard },
    { path: "/gift-card-details-component-cms", name: 'GiftCardDetailsComponentCMS', component: GiftCardDetailsComponentCMS, beforeEnter: guard },
    { path: "/product-details-component-cms", name: 'ProductDetailsComponentCMS', component: ProductDetailsComponentCMS, beforeEnter: guard },
    { path: "/privacy-policy-component-cms", name: 'PrivacyPolicyComponentCMS', component: PrivacyPolicyComponentCMS, beforeEnter: guard },
    { path: "/return-policy-component-cms", name: 'ReturnPolicyComponentCMS', component: ReturnPolicyComponentCMS, beforeEnter: guard },
    { path: "/terms-and-condition-component-cms", name: 'TermsPolicyComponentCMS', component: TermsPolicyComponentCMS, beforeEnter: guard },
    { path: "/hover-component-cms", name: 'HoverComponentCMS', component: HoverColorCms, beforeEnter: guard },

    //return products
    { path: "/return-product-approved", name: "Approved", component: Approved, beforeEnter: guard },
     { path: "/return-product-unapproved", name: "Unapproved", component: Unapproved, beforeEnter: guard },
     { path: "/return-product-rejected", name: "Rejected", component: Rejected, beforeEnter: guard }
     
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
