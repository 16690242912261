import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            reviewerName: '',
            message: '',
            reviewerImage: '',
            previewImage: ''
        }
    },
    async mounted() {
        document.title = "Golden Gal - Add Customer Review";
    },
    methods: {
        onImageChange: function (event) {
            this.reviewerImage = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate: function () {
            if (!this.reviewerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter reviewer name!"
                });
                return false;
            }
            if (!this.reviewerImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload reviewer image!"
                });
                return false;
            }
            if (!this.message) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter message!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append('reviewerName', this.reviewerName);
                    formData.append('reviewerImage', this.reviewerImage);
                    formData.append('message', this.message);
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.home.customerReviewURL,
                        data: formData,
                        headers: authHeader()
                    };
                    this.$swal.showLoading();
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.reviewerName = '';
                    this.reviewerImage = '';
                    this.message = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$router.push("/customer-review");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                }
            }
        }
    }
}