import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                homePageTitle: "",
                shopPagetitle: "",
                giftGuidePageTitle: "",
                stylePageTitle: "",
                merchPageTitle: "",
                learnPageTitle: "",
                galleryPageTitle: "",
                aboutPageTitle: "",
                collectionPageTitle: "",
                returnPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                giftCardPageTitle: "",
                promotionPageTitle: "",
                contactPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                productDetailsPageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                shopPageMetaKeyword: "",
                shopPageMetaDescription: "",
                giftGuidePageMetaKeyword: "",
                giftGuidePageMetaDescription: "",
                stylePageMetaKeyword: "",
                stylePageMetaDescription: "",
                merchPageMetaKeyword: "",
                merchPageMetaDescription: "",
                learnPageMetaKeyword: "",
                learnPageMetaDescription: "",
                galleryPageMetaKeyword: "",
                galleryPageMetaDescription: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                collectionPageMetaKeyword: "",
                collectionPageMetaDescription: "",
                returnPageMetaKeyword: "",
                returnPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                giftCardPageMetaKeyword: "",
                giftCardPageMetaDescription: "",
                promotionPageMetaKeyword: "",
                promotionPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                wishlistPageMetaKeyword: "",
                wishlistPageMetaDescription: "",
                cartPageMetaKeyword: "",
                cartPageMetaDescription: "",
                checkoutPageMetaKeyword: "",
                checkoutPageMetaDescription: "",
                loginPageMetaKeyword: "",
                loginPageMetaDescription: "",
                registrationPageMetaKeyword: "",
                registrationPageMetaDescription: "",
                productDetailsPageMetaKeyword: "",
                productDetailsPageMetaDescription: ""
            }
        }
    },
    async mounted() {
        document.title = "Golden Gal - Page Title";
        this.setURL = this.$serverURL + this.$api.cms.pageTitleCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        } else {
            this.pageCMSData = {
                homePageTitle: "",
                shopPagetitle: "",
                giftGuidePageTitle: "",
                stylePageTitle: "",
                merchPageTitle: "",
                learnPageTitle: "",
                galleryPageTitle: "",
                aboutPageTitle: "",
                collectionPageTitle: "",
                returnPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                giftCardPageTitle: "",
                promotionPageTitle: "",
                contactPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                loginPageTitle: "",
                registrationPageTitle: "",
                productDetailsPageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                shopPageMetaKeyword: "",
                shopPageMetaDescription: "",
                giftGuidePageMetaKeyword: "",
                giftGuidePageMetaDescription: "",
                stylePageMetaKeyword: "",
                stylePageMetaDescription: "",
                merchPageMetaKeyword: "",
                merchPageMetaDescription: "",
                learnPageMetaKeyword: "",
                learnPageMetaDescription: "",
                galleryPageMetaKeyword: "",
                galleryPageMetaDescription: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                collectionPageMetaKeyword: "",
                collectionPageMetaDescription: "",
                returnPageMetaKeyword: "",
                returnPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                giftCardPageMetaKeyword: "",
                giftCardPageMetaDescription: "",
                promotionPageMetaKeyword: "",
                promotionPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                wishlistPageMetaKeyword: "",
                wishlistPageMetaDescription: "",
                cartPageMetaKeyword: "",
                cartPageMetaDescription: "",
                checkoutPageMetaKeyword: "",
                checkoutPageMetaDescription: "",
                loginPageMetaKeyword: "",
                loginPageMetaDescription: "",
                registrationPageMetaKeyword: "",
                registrationPageMetaDescription: "",
                productDetailsPageMetaKeyword: "",
                productDetailsPageMetaDescription: ""
            };
        }
    },
    methods: {
        async update() {
            try {
                let data = {
                    homePageTitle: this.pageCMSData.homePageTitle,
                    shopPagetitle: this.pageCMSData.shopPagetitle,
                    giftGuidePageTitle: this.pageCMSData.giftGuidePageTitle,
                    stylePageTitle: this.pageCMSData.stylePageTitle,
                    merchPageTitle: this.pageCMSData.merchPageTitle,
                    learnPageTitle: this.pageCMSData.learnPageTitle,
                    galleryPageTitle: this.pageCMSData.galleryPageTitle,
                    aboutPageTitle: this.pageCMSData.aboutPageTitle,
                    collectionPageTitle: this.pageCMSData.collectionPageTitle,
                    returnPageTitle: this.pageCMSData.returnPageTitle,
                    privacyPageTitle: this.pageCMSData.privacyPageTitle,
                    termsPageTitle: this.pageCMSData.termsPageTitle,
                    giftCardPageTitle: this.pageCMSData.giftCardPageTitle,
                    promotionPageTitle: this.pageCMSData.promotionPageTitle,
                    contactPageTitle: this.pageCMSData.contactPageTitle,
                    cartPageTitle: this.pageCMSData.cartPageTitle,
                    wishlistPageTitle: this.pageCMSData.wishlistPageTitle,
                    checkoutPageTitle: this.pageCMSData.checkoutPageTitle,
                    loginPageTitle: this.pageCMSData.loginPageTitle,
                    registrationPageTitle: this.pageCMSData.registrationPageTitle,
                    productDetailsPageTitle: this.pageCMSData.productDetailsPageTitle,
                    homePageMetaKeyword: this.pageCMSData.homePageMetaKeyword,
                    homePageMetaDescription: this.pageCMSData.homePageMetaDescription,
                    shopPageMetaKeyword: this.pageCMSData.shopPageMetaKeyword,
                    shopPageMetaDescription: this.pageCMSData.shopPageMetaDescription,
                    giftGuidePageMetaKeyword: this.pageCMSData.giftGuidePageMetaKeyword,
                    giftGuidePageMetaDescription: this.pageCMSData.giftGuidePageMetaDescription,
                    stylePageMetaKeyword: this.pageCMSData.stylePageMetaKeyword,
                    stylePageMetaDescription: this.pageCMSData.stylePageMetaDescription,
                    merchPageMetaKeyword: this.pageCMSData.merchPageMetaKeyword,
                    merchPageMetaDescription: this.pageCMSData.merchPageMetaDescription,
                    learnPageMetaKeyword: this.pageCMSData.learnPageMetaKeyword,
                    learnPageMetaDescription: this.pageCMSData.learnPageMetaDescription,
                    galleryPageMetaKeyword: this.pageCMSData.galleryPageMetaKeyword,
                    galleryPageMetaDescription: this.pageCMSData.galleryPageMetaDescription,
                    aboutPageMetaKeyword: this.pageCMSData.aboutPageMetaKeyword,
                    aboutPageMetaDescription: this.pageCMSData.aboutPageMetaDescription,
                    collectionPageMetaKeyword: this.pageCMSData.collectionPageMetaKeyword,
                    collectionPageMetaDescription: this.pageCMSData.collectionPageMetaDescription,
                    returnPageMetaKeyword: this.pageCMSData.returnPageMetaKeyword,
                    returnPageMetaDescription: this.pageCMSData.returnPageMetaDescription,
                    privacyPageMetaKeyword: this.pageCMSData.privacyPageMetaKeyword,
                    privacyPageMetaDescription: this.pageCMSData.privacyPageMetaDescription,
                    termsPageMetaKeyword: this.pageCMSData.termsPageMetaKeyword,
                    termsPageMetaDescription: this.pageCMSData.termsPageMetaDescription,
                    giftCardPageMetaKeyword: this.pageCMSData.giftCardPageMetaKeyword,
                    giftCardPageMetaDescription: this.pageCMSData.giftCardPageMetaDescription,
                    promotionPageMetaKeyword: this.pageCMSData.promotionPageMetaKeyword,
                    promotionPageMetaDescription: this.pageCMSData.promotionPageMetaDescription,
                    contactPageMetaKeyword: this.pageCMSData.contactPageMetaKeyword,
                    contactPageMetaDescription: this.pageCMSData.contactPageMetaDescription,
                    cartPageMetaKeyword: this.pageCMSData.cartPageMetaKeyword,
                    cartPageMetaDescription: this.pageCMSData.cartPageMetaDescription,
                    wishlistPageMetaKeyword: this.pageCMSData.wishlistPageMetaKeyword,
                    wishlistPageMetaDescription: this.pageCMSData.wishlistPageMetaDescription,
                    checkoutPageMetaKeyword: this.pageCMSData.checkoutPageMetaKeyword,
                    checkoutPageMetaDescription: this.pageCMSData.checkoutPageMetaDescription,
                    loginPageMetaKeyword: this.pageCMSData.loginPageMetaKeyword,
                    loginPageMetaDescription: this.pageCMSData.loginPageMetaDescription,
                    registrationPageMetaKeyword: this.pageCMSData.registrationPageMetaKeyword,
                    registrationPageMetaDescription: this.pageCMSData.registrationPageMetaDescription,
                    productDetailsPageMetaKeyword: this.pageCMSData.productDetailsPageMetaKeyword,
                    productDetailsPageMetaDescription: this.pageCMSData.productDetailsPageMetaDescription
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.pageTitleCMS,
                    data: data,
                    headers: authHeader()
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}