<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Gift Card</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Gift Card CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Gift Card CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <form @submit.prevent="update">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Page Title:</label>
                      <input
                        type="text"
                        v-model="pageCMSData.pageTitle"
                        class="form-control"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Header Title:</label>
                      <input
                        type="text"
                        v-model="pageCMSData.headerTitle"
                        class="form-control"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Header Sub Title:</label>
                      <input
                        type="text"
                        v-model="pageCMSData.headerSubTitle"
                        class="form-control"
                      />
                    </div>
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/gift-card-cms.js"></script>