<template>
    <div>
        <div class="modal fade" id="change_password" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content radius-15 shadow-primary border-0">
                    <div class="modal-header modal-header-primary">
                        <h4 class="m-0 text-white">Change Password</h4>
                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row g-3">
                            <div class="col-12">
                                <label class="form-label">Old Password:</label>
                                <input type="password" v-model="oldPassword" class="form-control" placeholder="Input your old password" required/>
                            </div>
                            <div class="col-12">
                                <label class="form-label">New Password:</label>
                                <input type="password" v-model="newPassword" class="form-control" placeholder="Input your new password" required/>
                            </div>
                            <div class="col-12">
                                <label class="form-label">Retype New Password:</label>
                                <input type="password" v-model="confirmPassword" class="form-control" placeholder="Retype your new password" required/>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="updatePassword()" class="btn btn_primary pull-left">
                            <i class="bx bx-save"></i> Save Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authHeader } from "../../../auth";

export default {
    name: "Profile",
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            isEdit: false
        };
    },
    methods: {
        updatePassword: async function () {
            if (!this.oldPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your old password!"
                });
            } 
            else if (!this.newPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your new password!"
                });
            } 
            else if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your confirm password!"
                });
            } 
            else if (this.newPassword != this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Password and confirm password doesn't match!"
                });
            } else {
                let data = {
                    currentPassword: this.oldPassword,
                    newPassword: this.newPassword
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.changePassword,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your password changed successfully!"
                        });
                        window.location.replace("/home");
                        document.getElementById("closeButton").click();
                    }
                })
                .catch((error) => {
                    let message = 'Failed to update!';
                    if (error.response.data.statusCode == 404) {
                        message = 'Old password does not match!';
                    }
                    this.$swal.fire({
                        icon: "error",
                        text: message
                    });
                });
                this.isEdit = false;
            }
        }
    }
};
</script>

<style scoped>
.radius-15 {
    border-radius: 17px 17px 15px 15px !important;
}

.shadow-primary {
    box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%) !important;
}

.modal-header-primary {
    color: #fff;
    background-color: #037de2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.btn_primary {
    color: #fff;
    background-color: #037de2;
    border-color: #037de2;
}

.btn_primary:hover {
    color: #fff;
    background-color: #8436a8;
    border-color: #8436a8;
}

.btn_primary:focus {
    color: #fff;
    background-color: #8436a8;
    border-color: #8436a8;
    box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%);
}
</style>
