<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Section Seven CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Home Page Section Seven CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8" v-if="dataLoaded">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Product One Name:</label>
                                        <input type="text" v-model="pageCMSData.productName" class="form-control"
                                            id="inputPageHeaderTitle" placeholder="Enter product name">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product One Price:</label>
                                        <input type="text" v-model="pageCMSData.productPrice" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product price">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product One Link:</label>
                                        <input type="text" v-model="pageCMSData.productLink" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product link">
                                    </div>
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Product One Image:
                                            <strong>[Preferred Image Size: 172X274, Max Upload Limit:
                                                1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImageOne"
                                            :src="previewImageOne" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else-if="pageCMSData.productImage"
                                            :src="
                                                pageCMSData.productImage
                                            " height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else
                                            src="../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="imageOne"
                                            accept="image/*" @change="uploadImageOne" />
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Product Two Name:</label>
                                        <input type="text" v-model="pageCMSData.productTwoName" class="form-control"
                                            id="inputPageHeaderTitle" placeholder="Enter product name">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product Two Price:</label>
                                        <input type="text" v-model="pageCMSData.productTwoPrice" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product price">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product Two Link:</label>
                                        <input type="text" v-model="pageCMSData.productTwoLink" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product link">
                                    </div>
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Product Two Image:
                                            <strong>[Preferred Image Size: 172X274, Max Upload Limit:
                                                1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImageTwo"
                                            :src="previewImageTwo" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else-if="pageCMSData.productTwoImage"
                                            :src="
                                                pageCMSData.productTwoImage
                                            " height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else
                                            src="../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="imageTwo"
                                            accept="image/*" @change="uploadImageTwo" />
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Product Three Name:</label>
                                        <input type="text" v-model="pageCMSData.productThreeName" class="form-control"
                                            id="inputPageHeaderTitle" placeholder="Enter product name">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product Three Price:</label>
                                        <input type="text" v-model="pageCMSData.productThreePrice" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product price">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product Three Link:</label>
                                        <input type="text" v-model="pageCMSData.productThreeLink" class="form-control"
                                            id="inputPageHeaderPageName" placeholder="Enter product link">
                                    </div>
                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Product Three Image:
                                            <strong>[Preferred Image Size: 172X274, Max Upload Limit:
                                                1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImageThree"
                                            :src="previewImageThree" height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else-if="pageCMSData.productThreeImage"
                                            :src="
                                                pageCMSData.productThreeImage
                                            " height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else
                                            src="../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="imageThree"
                                            accept="image/*" @change="uploadImageThree" />
                                    </div>

                                    <div class="mb-3">
                                        <label for="inputProductTitle" class="form-label">Background Image:
                                            <strong>[Preferred Image Size: 624X1727, Max Upload Limit:
                                                1MB]</strong></label>
                                        <img style="position: relative; left: 11px" v-if="previewImage"
                                            :src="previewImage" height="70" width="100" />
                                        <img style="position: relative; left: 11px"
                                            v-else-if="pageCMSData.backgroundImage" :src="
                                                pageCMSData.backgroundImage
                                            " height="70" width="100" />
                                        <img style="position: relative; left: 11px" v-else
                                            src="../../../../assets/images/default.png" height="70" width="100" />
                                        <input class="form-control mt-3" type="file" ref="backgroundImage"
                                            accept="image/*" @change="uploadFeaturedImage" />
                                    </div>

                                    <div class="mb-3">
                                        <div class="input-group">
                                            <LvColorpicker label="Circle Dot Color:" v-model="pageCMSData.productDotCicleColor" :clearable="true"
                                                :bottomBar="true" :colors="colors" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <button v-on:click="updateCms()" type="button" class="btn btn-primary left">Save Changes</button>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleFullScreenModal"> <i class="fadeIn animated bx bx-disc"></i> Implement Dot - Feature</button>
                                        </div>
                                    </div>

                                    <div class="modal fade" id="exampleFullScreenModal"  ref="exampleFullScreenModal" tabindex="-1" aria-hidden="true">
                                        <div class="modal-dialog modal-fullscreen">
                                            <div class="modal-content">
                                                <div class="modal-body" style="padding:0px !important;">
                                                    
                                                    <div style="position: relative;" id="imageDotFetaureContainer">

                                                        <template v-for="(row, index) in dotfeatureData" :key="index">
                                                            <div 
                                                            :style="{'position': 'absolute', 'top': row.top, 'left':row.left, 'z-index':'999999'}">
                                                                <button @click="loadExistingCircle(row)" type="button"  :style="{
                                                                    'width': '22px',
                                                                    'height': '22px',
                                                                    'background':'#ed1122',
                                                                    'border': '4px solid hsla(12,11%,91%,.5) !important',
                                                                    'border-radius': '50% !important'
                                                                }"></button>
                                                            </div>
                                                        </template>
                                                        
                                                        <img style="position: relative;" 
                                                        :src="pageCMSData.backgroundImage" class="h-100 w-100 draw"
                                                        @click="drawDot($event, $event.target)" />

                                                    </div>
                                                
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        <div class="modal fade" id="exampleVerticallycenteredModal" tabindex="-1" aria-hidden="true">
											<div class="modal-dialog modal-dialog-centered">
												<div class="modal-content">
													<div class="modal-body">
                                                    
                                                        <div class="border border-3 p-4 rounded">
                                                            <input type="hidden" ref="circleTop" v-model="pageCicleCMSNewData.top">
                                                            <input type="hidden" ref="circleLeft" v-model="pageCicleCMSNewData.left">
                                                            <div class="mb-3">
                                                                <label class="form-label">Cirlce Product Name:</label>
                                                                <input type="text" ref="circleProductName" v-model="pageCicleCMSNewData.productName" class="form-control" id="inputPageHeaderTitle" placeholder="Enter product name">
                                                            </div>
                                                            <div class="mb-3">
                                                                <label class="form-label">Circle Product Price:</label>
                                                                <input type="text" ref="circleProductPrice" v-model="pageCicleCMSNewData.productPrice" class="form-control" id="inputPageHeaderPageName" placeholder="Enter product price">
                                                            </div>
                                                            <div class="mb-3">
                                                                <label class="form-label">Circle Product Link:</label>
                                                                <input type="text" ref="circleProductLink" v-model="pageCicleCMSNewData.productLink" class="form-control" id="inputPageHeaderPageName" placeholder="Enter product link">
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="inputProductTitle" class="form-label">Cicle Product Image:
                                                                    <strong>[Preferred Image Size: 172X274, Max Upload Limit: 1MB]</strong></label>
                                                                    <img style="position: relative;" v-if="previewCicleImage"  :src="previewCicleImage" height="70" width="100" />
                                                                    <img style="position: relative;" v-else-if="pageCicleCMSNewData.productImage" :src="pageCicleCMSNewData.productImage" height="70" width="100" />
                                                                    <img style="position: relative;" v-else src="../../../../assets/images/default.png" height="70" width="100" />
                                                                    <input class="form-control mt-3" type="file" ref="cicleImage" accept="image/*" @change="uploadCicleImage" />
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
													<div class="modal-footer">
														<button type="button" class="btn btn-danger" @click="deleteCirleCms">Delete Cirlce</button>
														<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
														<button type="button" class="btn btn-primary" @click="updateNewCirleCms">Save Circle changes</button>
													</div>
												</div>
											</div>
										</div>


                                </div>
                            </div>
                            <div class="col-md-12">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/home-page-section-seven.js"></script>
<style scoped>
    .draw{
        background: white;
        height: 100%;
        width: 100%;
        /* width: 100vw;
        height: 100vw; */
        border-style: solid;
        position: relative;
    }

    .point_btn {
        width: 22px;
        height: 22px;
        background:#ed1122 !important;
        border: 4px solid hsla(12,11%,91%,.5) !important;
        border-radius: 50% !important;
    }
</style>