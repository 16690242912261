import { authHeader } from "../../../auth";

export default {
    name: "Vendor",
    data() {
        return {
            singleVendor: {},
            setURL: '',
            name: '',
            location: '',
            email: '',
            phone: ''
        }
    },
    async created() {
        document.title = "Golden Gal - Vendor";
        this.setURL = this.$serverURL + this.$api.vendor.requestURL;
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter name!"
                })
                return false;
            }
            if (!this.location) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter location!"
                })
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                })
                return false;
            }
            if (!this.phone) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter phone!"
                })
                return false;
            }
            return true;
        },
        updateValidation: function () {
            if (!this.singleVendor.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter name!"
                })
                return false;
            }
            if (!this.singleVendor.location) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter location!"
                })
                return false;
            }
            if (!this.singleVendor.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                })
                return false;
            }
            if (!this.singleVendor.phone) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter phone!"
                })
                return false;
            }
            return true;
        },
        addVendor: async function () {
            if (this.addValidation() == true) {
                let data = {
                    name: this.name,
                    location: this.location,
                    email: this.email,
                    phone: this.phone
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.vendor.requestURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postDataToBackend(config);
                this.name = '';
                this.location = '';
                this.email = '';
                this.phone = '';
                await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editVendor: async function (data) {
            this.singleVendor = data;
        },
        updateVendor: async function () {
            if (this.updateValidation() == true) {
                let data = {
                    name: this.singleVendor.name,
                    location: this.singleVendor.location,
                    email: this.singleVendor.email,
                    phone: this.singleVendor.phone
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.vendor.requestURL + '/' + this.singleVendor.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteVendor: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.vendor.requestURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}